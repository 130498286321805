import React from 'react'
import { useTranslation } from 'next-i18next';
import useGetAllPermissions from '../../../../hook/GetAllPermissions';
import InputCheckbox from '../../../shared/form/input/InputCheckbox';
import { FormProps } from '../../../../interface/Element';
import LabelTxt from '../../../shared/Html/LabelTxt';
import { PermissionData } from '../../../../interface/EntityPermission';
import GrayLine from '../../../shared/Html/GrayLine';

export default function CheckBoxPermission({ control, errors, name: Name, changeF, getValues }: FormProps) {
    const { t } = useTranslation();

    const permissions = useGetAllPermissions()

    return (
        <>
            <div className='flexCol gap-4'>
                {permissions.map((item) => {
                    let name = (Object.keys(item))
                    let values: PermissionData[] = (Object.values(item)[0] as any)

                    return <>
                        <LabelTxt className="bg-transparent !text-[--secondaryTxt] font-bold" txt={t("Menu." + name)} />
                        <div className="flexRow flex-wrap gap-4">
                            {values.map((data) =>
                                <div className='w-[180px]'>
                                    <InputCheckbox
                                        getValues={getValues}
                                        keyValue={data.id.toString()}
                                        label={data.title}
                                        name={data.id.toString()}
                                        changeF={changeF}
                                        defaultValue={data.id.toString()}
                                        control={control}
                                    />
                                </div>
                            )}

                        </div>
                        <GrayLine />
                    </>
                })}

            </div>

            {/* <InputCheckbox keyValue="fdfd" label='' name='' control={control} /> */}

        </>
    )
}

