import { Controller } from 'react-hook-form'
import React from 'react'
import { InputType } from './interface'

const InputCheckbox = ({
  label,
  placeholder,
  control,
  rules,
  name,
  defaultValue,
  getValues,
  setValue,
  errors = {},
  changeF
}: InputType) => {

  const HtmlElement = ({ value, onChangeF }: { value: string, onChangeF: any }) => {

    return <>
      <div className="InputLabelClassRow">
        <input
          type="checkbox"
          value={value}
          name={name}
          id={name}
          checked={getValues(name)}
          autoComplete='off'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangeF(event.currentTarget.checked); }}
          className="InputClass !w-[30px] !h-[30px]"
          placeholder={placeholder} />

        <label htmlFor="" className="LabelClass ">{label}</label>
      </div>
    </>
  }

  return (
    <>
      {control && <Controller
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => <HtmlElement onChangeF={onChange} value={value} />}
        name={name} />}
      {!control && <HtmlElement value={defaultValue ?? ""} onChangeF={changeF} />}
      <div>
        <p className='text-red-400 font-bold text-sm m-2'>{errors[name]?.message}</p>
      </div>
    </>
  )
}

export default InputCheckbox