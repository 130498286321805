import * as Yup from "yup"

import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react'
import { useSupportAddMutation } from "../../../../../data/services/Support";


import ModalFull from '../../../../shared/modal/ModalFull';
import { RootState } from '../../../../../data/store';
import { TextDescription } from "../../../../shared/Html/TextDescription";
import { useOrderDetailQuery } from "../../../../../data/services/Order";
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { baseUrl } from "../../../../../config/ApiConf";
import { getAttachUrlProduct } from "../../../../../utility/Function";
import AvatarImage from "../../../../shared/Html/avatar/AvatarImage";
import GrayLine from "../../../../shared/Html/GrayLine";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useReactToPrint } from "react-to-print";
import ButtonBrand from "../../../../shared/button/ButtonBrand";

export default function ViewOrder({ openModal, ChangeOpen }: { openModal: boolean, ChangeOpen: Function }) {

    const { t } = useTranslation();
    const Data = useSelector((state: RootState) => state.OrderForm.data);
    const { data } = useOrderDetailQuery({ id: Data?.id ?? 0 })
    const [OpenAdd, setOpenAdd] = useState(false);
    const [adminReply, results] = useSupportAddMutation()

    const Schema = Yup.object().shape({
        text: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema),

    });

    const OnFinish: SubmitHandler<any> = (data) => {
        if (data?.text !== undefined) {
            adminReply({ ...data, ...{ id: Data?.id } })
        }
    }
    console.log(data)

    useEffect(() => {
        setOpenAdd(openModal);
        reset();
    }, [openModal, reset])

    useEffect(() => {
        if (results.isSuccess) {

            reset();

        }
    }, [openModal, reset, results])

    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ contentRef });

    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => { setOpenAdd(open); ChangeOpen(open); }} Open={OpenAdd} title={t("Txt.order") + " " + data?.data?.user.first_name + ' ' + data?.data.user.last_name}>
                <div className="FormModal !h-auto" ref={contentRef}>

                    {/* <div className="flexCol max-h-80 overflow-y-auto gap-y-4 p-4">
                        <TextDescription title={t("Txt.totalOrderPrice")} value={parseInt(data?.data.price??'0').toLocaleString()}/>
                    </div> */}
                    <div className="p-2 flexRow gap-3 rounded-[--radius] justify-between border-b items-center">
                        <strong>{t("Txt.image")}</strong>
                        <strong>{t("Txt.name")}</strong>
                        <strong>{t("Txt.amount")}</strong>
                        <strong>{t("Txt.color")}</strong>
                        <strong>{t("Txt.size")}</strong>
                        <strong>{t("Txt.price")}</strong>

                    </div>
                    <GrayLine />
                    <div className="flexCol flex-wrap gap-4">
                        {data?.data.products.map((item, index) => <>
                            <>
                                <div className="p-2 flexRow gap-3 rounded-[--radius] justify-between  items-center">
                                    <AvatarImage img={`${baseUrl}${getAttachUrlProduct(item.gallery, "thumb")[0]?.pathFile}`} />
                                    <strong>{item.name}</strong>
                                    <strong>{item.pivot.count}</strong>
                                    <strong>{item.pivot.color}</strong>
                                    <strong>{item.pivot.size}</strong>
                                    <strong>{(+item.pivot.final_price).toLocaleString()} {t("Common.unitPay")}</strong>

                                </div>
                                <GrayLine />
                            </>
                        </>)}

                    </div>

                    <div className="p-2 flexRow gap-3 rounded-[--radius] justify-between  items-center">
                        <strong>{t("Txt.totalOrderPrice")}</strong>
                        <strong>{parseInt(data?.data.price ?? '0').toLocaleString()} {t("Common.unitPay")}</strong>
                    </div>


                    <div className="w-full py-4 flex flex-col gap-4">
                        <h2>{`${t("Common.name")} : ${data?.data?.user?.first_name} ${data?.data?.user?.last_name}`}</h2>
                        <h2>{`${t("Common.mobile")} : ${data?.data?.user?.mobile}`}</h2>
                        <h2>{`${t("Common.address")} : ${data?.data?.address?.address}`}</h2>
                        <h2>{`${t("Common.postalCode")} : ${data?.data?.address?.postal_code}`}</h2>
                        <h2>{`${t("Common.deliveryName")} : ${data?.data?.delivery_name}`}</h2>
                    </div>

                    <div className="hidden sm:block">
                        <ButtonBrand click={() => reactToPrintFn()} className="w-[150px] rounded-md h-10 bg-[--brand] text-white"
                        >{t("Common.print order")}<LocalPrintshopIcon /></ButtonBrand>
                    </div>

                </div>

            </ModalFull>

        </>
    )
}
