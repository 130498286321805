
import { ChangeToFormData, ChangeToQueryString } from "../../utility/Function";
import { SECTION_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'
import { SectionAddPropsApi,  SectionList,  SectionListFilterApi, SectionRemovePropsApi, SectionUpdatePropsApi } from "../../interface/EntitySection";

export const SectionApi = createApi({
    reducerPath: 'SectionApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["section"],

    endpoints: (builder) => ({
        section: builder.query<SectionList, SectionListFilterApi>({
            query: (body) => ({
                url: SECTION_PATH+ChangeToQueryString(body),
                method: 'GET',

            })
            ,
            providesTags: ['section'],
        }),


        SectionAdd: builder.mutation<any, SectionAddPropsApi>({
            query: (body) => ({
                url: SECTION_PATH,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['section'],
        }),

        SectionEdit: builder.mutation<void, SectionUpdatePropsApi>({
            query: ({ id, ...res }) => ({
                url: SECTION_PATH + "/" + id,
                method: 'PUT',

                body: ChangeToFormData(res)
            }),
            invalidatesTags: ['section'],
        }),

        SectionRemove: builder.mutation<void, SectionRemovePropsApi>({
            query: (body) => ({
                url: SECTION_PATH + "/" + body.id,
                method: 'DELETE',

            }),
            invalidatesTags: ['section'],
        }),



    }),



})

export const {
    useSectionQuery,
    useSectionAddMutation,
    useSectionEditMutation,
    useSectionRemoveMutation
} = SectionApi


