import { Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import useRtlDetermine from '../../../../hook/RtlDetermine';
import { InputNumberCodeType } from './interface';

export default function NumberCodeInput({
    label,
    placeholder,
    keyValue,
    name,
    control,
    rules,
    errors,
    changeF,
    defaultValue,
    length = 10,
    className="p-4 rounded-[--radius] border border-[--secondaryLight] text-[--infoTxt] w-full bg-transparent  outline-[--infoLight]"
}: InputNumberCodeType) {
    const dir = useRtlDetermine()
    let pattern = "";
    for (let i = 0; i < length; i++) {
        pattern += '#'
        if (i < length) {
            pattern += " "
        }
    }
    const ControlElement = () =>
        <Controller
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value } }) => (

                <PatternFormat
                    dir={'ltr'}
                    placeholder={placeholder}
                    className={className}
                    format={pattern}
                    allowEmptyFormatting mask="_"
                    onValueChange={(values, sourceInfo) => {
                        onChange(values.value);
                        if(changeF){
                            changeF(values.value)
                        }

                    }}
                    value={value}
                />

            )}

            name={name} />

    const WithoutControlElement = () => <PatternFormat
        dir={'ltr'}
        placeholder={placeholder}
        className={className}
        format={pattern}
        allowEmptyFormatting mask="_"
        onChange={(values) => {
            if(changeF!==undefined){
                let value=values.currentTarget.value.replaceAll("_","").replaceAll(" ","")
                if(value.length>=length)
                changeF(value)
                
            }

        }}
        defaultValue={defaultValue}
    />
    return (
        <>
            <div key={keyValue} dir={dir} className="InputLabelClass"  >
                <label htmlFor="" className="LabelClass">{label}</label>

                {control&&<ControlElement/>}
                {!control&&<WithoutControlElement/>}
                {/* <Controller
                    control={control}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (

                        <PatternFormat
                            dir={'ltr'}
                            placeholder={placeholder}
                            className='p-4 rounded-[--radius] border border-[--secondaryLight] text-[--infoTxt] w-full bg-transparent  outline-[--infoLight]  '
                            format={pattern}
                            allowEmptyFormatting mask="_"
                            onValueChange={(values, sourceInfo) => {
                                onChange(values.value)

                            }}
                            value={value}
                        />

                    )}

                    name={name} /> */}

            </div>

            {control&& <div>
                <p className='text-red-400 font-bold text-sm m-2'>{errors[name]?.message}</p>

            </div>}




        </>
    )
}
