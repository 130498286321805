import { useTranslation } from 'next-i18next';
import SelectSearch from '../../shared/form/select/SelectSearch';
import { CategoryFormProps, FormProps, SelectOption } from '../../../interface/Element';
import { useCategoryQuery } from '../../../data/services/Category';
import { useEffect, useState } from 'react';
 
export default function CategorySelect({ control, errors,name="category",type="product" }: CategoryFormProps) {
    const {t}=useTranslation()
    const {data,isLoading}=useCategoryQuery({category_type:type})
    const [options,setOptions]=useState<SelectOption[]>([]);

    useEffect(()=>{
        if(data?.data){
            const newOptions=data.data.map((item)=>{
                return {
                    label:item.parentData?.name? item.parentData?.name+'=>'+item.name:item.name,
                    value:item.id
                }
            })
            setOptions([...[{label:t("Txt.chooseOne"),value:""},...newOptions]]);
        }
    },[data])
    
    return (
            <SelectSearch
                optionItem={options}
                control={control}
                errors={errors}
                keyValue={name}
                label={t("Txt.category")}
                name={name} />

    )
}

 