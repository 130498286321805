import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, CategoryDataType, CategoryType } from "../../../interface/EntityCategory";

const initialState: {
    mode: ActionMode,
    data: CategoryDataType | null,
    parents: CategoryDataType[],
    deleteItem:CategoryDataType|null,
    type:CategoryType

    
} = {
    mode: "add",
    data: null,
    parents: [],
    deleteItem:null,
    type:"product"
}


export const CategoryForm = createSlice({
    name: 'CategoryForm',
    initialState,
    reducers: {
        setCategoryFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },

        emptyCategoryFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'add'
            return newState;
        },

        setCategoryFormData: (state, action: { payload: CategoryDataType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },

        emptyCategoryFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },

        setCategoryParentFormData: (state, action: { payload: CategoryDataType }) => {
            let newState = state;
            newState['parents'] = [...newState['parents'], ...[action.payload]]
            return newState;
        },

        setCategoryDeleteItem: (state, action: { payload: CategoryDataType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },

        setCategoryTypeFormData: (state, action: { payload: CategoryType }) => {
            let newState = state;
            newState['type'] = action.payload
            return newState;
        },
        emptyCategoryParentFormData: (state, action: { payload: CategoryDataType }) => {
            let newState = state;
            if (newState['parents'].length > 0)
                newState['parents'] = newState['parents'].filter((item) => item.id !== action.payload.id)
            return newState;
        },

        emptyCategoryParentAllFormData: (state) => {
            let newState = state;
            newState['parents'] = []
            return newState;
        },

        emptyCategoryDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setCategoryFormMode,
    emptyCategoryFormMode,
    setCategoryFormData,
    emptyCategoryFormData,
    setCategoryParentFormData,
    emptyCategoryParentFormData,
    emptyCategoryParentAllFormData,
    setCategoryDeleteItem,
    emptyCategoryDeleteItem,
    setCategoryTypeFormData
    
} = CategoryForm.actions

export default CategoryForm.reducer