import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReAuth } from '../ApiCal'
import { DISCOUNT_PATH } from '../../config/ApiConf'
import { IDiscount, IDiscountData, IDiscountListFilter, IListDiscountApi, IResponseDiscountDetails } from '../../interface/EntityDiscount'
import {changeToFormDataDiscount, changeToQueryParams } from '../../utility/Function'
import { ID } from '../../interface/EntityCommon'

export const DiscountApi = createApi({
    reducerPath: 'DiscountApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ["Discount"],
    endpoints: (builder) => ({
        discountList: builder.query<IListDiscountApi, IDiscountListFilter>({
            query: (body) => ({
                url: DISCOUNT_PATH+changeToQueryParams(body),
                method: 'GET',
            }),
            providesTags: ['Discount'],
        }),
        getDiscount: builder.query<IResponseDiscountDetails, ID>({
            query: (body) => ({
                url: DISCOUNT_PATH+`/${body.id}`,
                method: 'GET',
            }),
            providesTags: ['Discount'],
        }),
        addDiscount: builder.mutation<any, IDiscount>({
            query: (body) => ({
                url: DISCOUNT_PATH,
                method: 'POST',
                body:changeToFormDataDiscount(body)
            }),
            invalidatesTags: ['Discount'],
        }),
        editDiscount: builder.mutation<void, IDiscountData>({
            query: ({ id, ...res }) => ({
                url: DISCOUNT_PATH + "/" + id,
                method: 'POST',
                body: changeToFormDataDiscount({...res,...{"_method":"PUT"}})
           
            }),
            invalidatesTags: ['Discount'],
        }),
    })
})

export const { useDiscountListQuery, useAddDiscountMutation,useEditDiscountMutation ,useGetDiscountQuery} = DiscountApi