import React from 'react'
import { FormComponentProps } from '../../../../interface/Element'
import InputTxt from '../../../shared/form/input/InputTxt'
import { useTranslation } from 'next-i18next'


import CheckBoxPermission from '../../../permission/form/checkBox'
import FileInput from '../../../shared/form/upload/FileInput'
import { ImgUploadType } from '../../../../config/App'
import InputTextarea from '../../../shared/form/input/InputTextarea'
import CategorySelect from '../../../category/select/CategorySelect'

export default function FormItem({ control, getValues, setValue, errors }: FormComponentProps) {
    const { t } = useTranslation()
    return (
        <>
            <div className='flexCol flex-wrap w-full items-center'>
                <div className='w-[45%]'>
                    <InputTxt control={control} errors={errors} keyValue='title' label={t("Txt.title")} name='title' placeholder={t("Txt.title")} />
                </div>
             
                <div className='w-[45%]'>
                <InputTextarea control={control} errors={errors} keyValue='text' label={t("Txt.text")} name='text' placeholder={t("Txt.text")} />

                </div>

 
                <div className='w-[45%]'>
                <CategorySelect control={control} errors={errors}  name='category_id' type='faq'    />

                </div>
            </div>

        </ >


    )
}
