
import { useNavigate } from 'react-router-dom';
import { setBlogFormMode } from '../../../data/slice/blog/BlogForm';
import ButtonBrand from '../../shared/button/ButtonBrand';
import PlusIcon from '../../shared/Icon/Plus';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';

function Header() {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <>
            <h1 className="text-2xl">{t("Menu.blog")}</h1>
            <div className="w-60">
                <ButtonBrand click={() => {
                    //  dispatch(setBlogFormMode("add"))
                    navigate("/blogs/new") 
                     }} >
                    <div className="flexRow items-center gap-2">
                        <span><PlusIcon fill="var(--light)" /></span>
                        <b> {t("Txt.addBlog")} </b>
                    </div>
                </ButtonBrand>
            </div>



        </>
    )
}

export default Header