import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, FaqDataType } from "../../../interface/EntityFaq";
import { PermissionData } from "../../../interface/EntityPermission";
import { IDiscountData } from "../../../interface/EntityDiscount";

const initialState: {
    mode: ActionMode,
    data: IDiscountData | null,
    deleteItem: IDiscountData | null,
    permissions: PermissionData[],
} = {
    mode: "none",
    data: null,
    deleteItem: null,
    permissions: [],
   
}


export const DiscountForm = createSlice({
    name: 'DiscountForm',
    initialState,
    reducers: {
        setDiscountFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },
       
        emptyDiscountFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'none'
            return newState;
        },

        setDiscountFormData: (state, action: { payload: IDiscountData }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },

        setDiscountPermissionsFormData: (state, action: { payload: PermissionData[] }) => {
            let newState = state;
            newState['permissions'] = action.payload
            return newState;
        },

        emptyDiscountFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },
        emptyDiscountPermissionsFormData: (state) => {
            let newState = state
            newState['permissions'] = [];
            return newState;
        },


        setDiscountDeleteItem: (state, action: { payload: IDiscountData }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptyDiscountDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setDiscountFormMode,
    emptyDiscountFormMode,
    setDiscountFormData,
    emptyDiscountFormData,
    setDiscountDeleteItem,
    emptyDiscountDeleteItem
} = DiscountForm.actions

export default DiscountForm.reducer