
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../data/store'

import { setRoleFormMode } from '../../../data/slice/role/RoleForm'
import EditAdmin from './actions/edit'
import DeleteAdminComponent from './actions/delete'
import { setAdminFormMode } from '../../../data/slice/admin/AdminForm'
import FilterAdmin from './actions/filter'

export default function FormAdmin() {

  const dispatch = useDispatch()
  const mode = useSelector((state: RootState) => state.AdminForm.mode)
  console.log(mode)
  return (
    <>
      <div className='w-full h-full bg-[--light] p-2 rounded-[--radius]'>

        {mode === "edit" && <EditAdmin
          ChangeOpen={() => dispatch(setAdminFormMode("none"))}
          openModal={mode === "edit"}
        />}

        {mode === "delete" && <DeleteAdminComponent />}
        {/* {mode === "filter" && <FilterAdmin/>} */}

      </div>
    </>
  )
}
