import { ClassNames } from '@emotion/react'
import { ButtonProps } from '../../../interface/Element'
import { Loader } from '../Loader'

const ButtonBrand = ({ click, children, type = "button",loading=false,className }: ButtonProps) => {
  return (
    <button
      type={type}
      onClick={() => click()}
      className={`w-full p-3 rounded-[--radius] ${loading ?"bg-[--brand]":"bg-[--brandLight]"} text-white ${className}`}>
        {loading && <Loader />}
      {!loading&&children}
    </button>
  )
}

export default ButtonBrand