import { useEffect, useState } from 'react'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import FormItem from '../FormItem';
import { ToastError, ToastSuccess } from '../../../../../utility/ToastFunction';
import ButtonBrand from '../../../../shared/button/ButtonBrand';
import ModalFull from '../../../../shared/modal/ModalFull';
import PageMarginBottom from '../../../../shared/Html/PageMarginBottom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store';
import { setSliderFormMode } from '../../../../../data/slice/slider/SliderForm';
import { useSliderEditMutation } from '../../../../../data/services/Slider';
import { SliderUpdatePropsApi } from '../../../../../interface/EntitySlider';
import { baseUrl } from '../../../../../config/ApiConf';

export default function EditSlider() {

    const { t } = useTranslation();
    const Data = useSelector((state: RootState) => state.SliderForm);
    const dispatch = useDispatch()
    const [Edit, results] = useSliderEditMutation();
    const [OpenAdd, setOpenAdd] = useState(false);

    const Schema = Yup.object().shape({


    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema),

    });

    const OnFinish: SubmitHandler<SliderUpdatePropsApi> = (data) => {

        if (data?.text !== undefined) {
            let params: any = data
            if (params.image === undefined) {
                delete (params['image']);
            }
            if (params.image2 === undefined) {
                delete (params['image2']);
            }
            if (params.image_bg_large === undefined) {
                delete (params['image_bg_large']);
            }
            if (params.image_bg_small === undefined) {
                delete (params['image_bg_small']);
            }
            
            if (params.text === '') {
                params['text'] = null;
            }
            if (params.link === '') {
                params['link'] = null;
            }
            Edit(params)
        }
    }

    useEffect(() => {
        if (Data.mode === 'edit') {
            setOpenAdd(true);
            reset({
                id: Data.data?.id,
                text: Data.data?.text !== 'undefined' && Data.data?.text !== 'null' ? Data.data?.text : "",
                title: Data.data?.title !== 'undefined' && Data.data?.title !== 'null' ? Data.data?.title : "",
                link: Data.data?.link !== 'undefined' && Data.data?.link !== 'null' ? Data.data?.link : "",
            });
        } else {
            setOpenAdd(false);
        }
    }, [Data.mode, reset])



    useEffect(() => {
        if (results.isSuccess) {

            dispatch(setSliderFormMode("none"))
            ToastSuccess(t("Common.successAlarm"))
            window.location.reload();
        }

        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => {
                setOpenAdd(open);
                dispatch(setSliderFormMode(open ? "add" : "none"));


            }} Open={OpenAdd} title={t("Txt.editSlider")}>
                <div className="FormModal ">
                    <div className='flexRow gap-2 justify-center'>
                        <div className='w-[30%] h-auto my-2 mx-auto'>
                            <img src={baseUrl + Data.data?.pathFile} className='w-full h-full object-fill  rounded-[--radius]' />
                        </div>
                        <div className='w-[30%] h-auto my-2 mx-auto'>
                            <img src={baseUrl + Data.data?.pathFileImage2} className='w-full h-full object-fill  rounded-[--radius]' />
                        </div>

                    </div>
                    <div className='flexRow gap-2 justify-center'>
                        <div className='w-[30%] h-auto my-2 mx-auto'>
                            <img src={baseUrl + Data.data?.pathFileImageBgLarge} className='w-full h-full object-fill  rounded-[--radius]' />
                            <p className='text-center mt-2 mb-4'>تصویر حالت دسکتاپ</p>
                        </div>
                        <div className='w-[30%] h-auto my-2 mx-auto'>
                            <img src={baseUrl + Data.data?.pathFileImageBgSmall} className='w-full h-full object-fill  rounded-[--radius]' />
                            <p className='text-center mt-2 mb-4'>تصویر حالت موبایل</p>
                        </div>

                    </div>
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />

                        <div className='w-full flex justify-center mt-10 bottom-5 left-[3%]' >
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>

                    <PageMarginBottom />


                </div>

            </ModalFull>

        </>
    )
}
