import AttachFileIcon from '@mui/icons-material/AttachFile';
import DownloadLink from '../../../../shared/Html/DownloadLink';
import { MessageProps } from '../../../../../interface/EntitySupport';
import { baseUrl } from '../../../../../config/ApiConf';

const SupportText = ({ data }: { data: MessageProps }) => {
    return (
        <>

            <div className={` w-fit flex flex-col gap-y-4 rounded-[--radius] p-4 shadow-lg ${data.is_reply?"bg-[--brand]":""}`}>
                {data.attach.map((item) =>
                    <DownloadLink url={baseUrl + item.pathFile} fileName={item.name} >
                        <img className='rounded-[--radius] max-w-64' src={baseUrl + item.pathFile} alt="" />
                    </DownloadLink>)}

                <p className='text-[--grayTxt] text-sm font-bold'>
                    {data.text}
                </p>
                <div className='w-full text-end text-xs'><small className=' text-xs'>{data.createdAtP}</small></div>

            </div>
        </>
    )
}

export default SupportText