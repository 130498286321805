import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { baseUrl } from '../../../config/ApiConf'
import { NullValue } from '../../../utility/Function'
import { useBannerQuery } from '../../../data/services/banner'
import FormBanner from '../form/Index'

import ListDataTable from './../../shared/listData/index'
import { Loader } from '../../shared/Loader'

// icons
import SettingIcon from '../../shared/Icon/Setting'
import TrashIcon from '../../shared/Icon/Trash'

// redux
import { useDispatch } from 'react-redux'
import {
    setBannerDeleteItem,
    setBannerFormData,
    setBannerFormMode
  } from '../../../data/slice/banner/BannerForm'

function ListData () {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)

  const { data, isLoading, refetch } = useBannerQuery()
  const dispatch = useDispatch()

  const Headers = [
    { value: <b>#</b>, width: 'w-[150px]' },
    { value: <b>{t('Txt.image')}</b> },
    { value: <b>{t('Txt.link')}</b> },
    { value: <b>{t('Txt.actions')}</b>, width: 'w-[380px]' }
  ]

  const Rows = data?.data?.map(item => {
    return {
      row: [
        { value: <div className='ms-1'>{item.id}</div>, width: 'w-[150px]' },

        {
          value: (
            <div className=' w-full h-full'>
              <img
                className='rounded-md h-full w-full object-cover '
                src={baseUrl + item.pathFile}
                loading='lazy'
              />
            </div>
          )
        },
        {
          value: (
            <div className='p-4'>
              <a className='rounded-md w-full' href={item.link}>
                {NullValue(item.link)}
              </a>
            </div>
          )
        },
        {
          value: (
            <div className='flexRow items-center'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  dispatch(setBannerFormMode('edit'))
                  dispatch(setBannerFormData(item))
                }}
              >
                <SettingIcon fill='var(--secondary)' size='30px' />
              </div>

              <div
                className='cursor-pointer'
                onClick={() => {
                  dispatch(setBannerFormMode('delete'))
                  dispatch(setBannerDeleteItem(item))
                }}
              >
                <TrashIcon fill='var(--error)' size='30px' />
              </div>
            </div>
          ),
          width: 'w-[380px]'
        }
      ]
    }
  })

  return (
    <>
      <div className='  py-4'>
        {isLoading && (
          <div className='flexRow justify-center w-full'>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <ListDataTable
            header={Headers}
            setPage={setPage}
            rows={Rows ?? []}
            // currentPage={+(data?.data.current_page ?? 1) - 1}
            // rowsPerPage={+(data?.data.per_page ?? 0)}
            // total={+(data?.data.total ?? 0)}
            handleChangePage={(params1: any, params2: number) =>
              setPage(params2 + 1)
            }
            onRowsPerPageChange={(params1: any, params2: any) =>
              console.log(params1, params2)
            }
          />
        )}
      </div>
      <FormBanner />
    </>
  )
}

export default ListData
