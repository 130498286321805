import React from 'react'
import { SvgProps } from '../../../interface/Element'

function ProductIcon({ fill = "#A1A0A3", size = "20px", stroke = "#000000" }: SvgProps) {
    return (
        <>
     <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width={size} fill={fill}><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"/></svg>
        </>
    )
}

export default ProductIcon