import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { ModalProps } from '../../../interface/Element';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogComponent from '../drawer/Dialog';
import { DialogContent } from '@mui/material';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function ModalFull({ Open, children, ChangeOpen, showClose = true, title }: ModalProps) {

    const matches = useMediaQuery('(min-width:600px)');


    return (
        <React.Fragment>

            <Dialog
                dir='rtl'
                fullScreen={matches ? false : true}
                open={Open}
                onClose={() => ChangeOpen()}
                TransitionComponent={Transition}
                sx={{ width: "80%", height: "auto", marginInline: "auto" }}
                maxWidth="xl"


            >
                {/* <DialogContent sx={{}} > */}
                <AppBar sx={{ position: 'relative', background: "var(--brandLight)" }}>
                    <Toolbar>
                        {showClose && <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => ChangeOpen()}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>}
                        <Typography sx={{ mr: 2, flex: 1 }} variant="h6" component="div">
                            {title}
                        </Typography>

                    </Toolbar>
                </AppBar>
                <div className='px-2'>

                    {children}
                </div>
                {/* </DialogContent> */}
            </Dialog>
        </React.Fragment>
    );
}
