import { ChangeEvent, FC, HTMLProps } from "react"

interface IProps {
    placeholder: string,
    label: string,
    value?: any,
    getValue:Function,
    classContainer?: HTMLProps<HTMLElement>['className'],
    classInput?: HTMLProps<HTMLElement>['className']
}

const InputTextWithoutForm: FC<IProps> = ({ label, placeholder, value,getValue ,classContainer,classInput}) => {
    return (
        <div className={`InputLabelClass ${classContainer}`}>
            <label htmlFor="" className="LabelClass">{label}</label>
            <input
                type="text"
                value={value}
                name=""
                id=""
                autoComplete='off'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    getValue(event.target.value)
                }}
                className={`InputClass ${classInput}`} placeholder={placeholder} />

        </div>
    )
}
export default InputTextWithoutForm