
//export const  baseUrl='https://wegopars.ir/api/public/api/v1/'
// export const  baseUrl=process.env.REACT_APP_API_BASE??""
export const  baseUrl="https://api.tiademco.com/api/v1/"

  
export const LOGIN_PATH                                             ='admin/login';
export const LOGOUT_PATH                                             ='admin/logout';
export const Product_PATH_LIST                                      ='admin/product-list'
export const Product_PATH_COMMENT_LIST                                      ='admin/product-comment'
export const Product_PATH_DETAIL                                    ='admin/product-detail'
export const Product_ADMIN_PATH                                     ='admin/product'
export const PRODUCT_SECTION_PATH                                   = "admin/product-section"
export const Product_ADMIN_INVENTORY_PATH                           ='admin/productInventory'
export const Product_ADMIN_KEY_PATH                                 ='admin/productKey'
export const Permission_PATH                                        ='admin/permissions';
export const PackageType_PATH                                       ='packageType'
export const PackageType_ADMIN_PATH                                 ='admin/packageType'
export const Category_PATH                                          ='category'
export const Category_ADMIN_PATH                                    ='admin/category'
export const ProductGallery                                         ="admin/productGallery"
export const ADMIN_PATH                                             ="admin/admin"
export const ADMIN_STORE_PATH                                       ="admin/store"
export const USER_PATH                                              ="admin/user"
export const SUPPLIER_PATH                                          ="admin/supplier"
export const SUPPORT_PATH                                           ="admin/ticket"
export const ROLE_PATH                                              ="admin/role"
export const ROLE_PERMISSION                                        ="admin/permissionRoles"
export const ROLE_ALL_PATH                                          ="admin/role-all"
export const ORDER_PATH                                             ="admin/order"
export const ORDER_STATUS_PATH                                      ="order-status";
export const ABOUT_ADMIN_PATH                                       ="admin/about"
export const ABOUT_PATH                                             ="about"
export const TERM_ADMIN_PATH                                        ="admin/term"
export const TERM_PATH                                              ="term"
export const SiteInfo_ADMIN_PATH                                    ="admin/create/site-info"
export const SiteInfo_PATH                                          ="site-info"
export const SLIDER_PATH                                            ="admin/slider"
export const SLIDER_GET_PATH                                        ="slider"
export const BANNER_PATH                                            ="admin/banner"
export const BANNER_GET_PATH                                        ="banner"
export const BLOG_PATH                                              ="admin/blog"
export const FAQ_PATH                                               ="admin/faq"
export const BLOG_GET_PATH                                          ="blog"
export const FAQ_GET_PATH                                           ="faq"
export const Transaction_PATH                                       ="admin/transaction"
export const PAGE_PATH                                              ="admin/page"
export const SECTION_PATH                                           ="admin/section"
export const DISCOUNT_PATH                                          ="admin/discount"
export const PROFILE_PATH                                           ="admin/profile"
