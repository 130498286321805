import Header from "../../components/admin/header";
import ListData from "../../components/admin/list/Index";

export default function AdminsPage() {
  return (
    <>
      <div className="flexCol gap-4 pageLayout">

        <Header />

        <ListData />

      </div>
    </>
  );
}
