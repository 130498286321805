import { ButtonProps } from '../../../interface/Element'
import React from 'react'

const ButtonBrandOuter = ({
  click,
  children,
  type = "button",
  className = ""
}:
  ButtonProps) => {
  return (
    <button type={type} onClick={() => click()}
     className={`w-full p-3 rounded-[--radius] border border-[--brandLight] text-[--infoTxt] hover:bg-[--brandLight] hover:text-[--light] ${className}`}>
      {children}
    </button>
  )
}

export default ButtonBrandOuter