import FilterAltIcon from '@mui/icons-material/FilterAlt'

import ButtonBrand from '../../shared/button/ButtonBrand'
import { useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'
import { setUserFormMode } from '../../../data/slice/user/UserForm'

function Header () {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <section className='w-full flex justify-between'>
      <h1 className='text-2xl'>{t('Menu.users')}</h1>
      <ButtonBrand
        type='button'
        className='!w-[100px]'
        click={() => {
          dispatch(setUserFormMode('filter'))
        }}
      >
        <div className='flexRow items-center gap-2'>
          <span>
            <FilterAltIcon fill='var(--light)' />
          </span>
          <b> {t('Txt.filter')} </b>
        </div>
      </ButtonBrand>
    </section>
  )
}

export default Header
