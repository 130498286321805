import { createSlice } from "@reduxjs/toolkit"
import { ActionMode} from "../../../interface/EntityFaq";
import { IDiscountData } from "../../../interface/EntityDiscount";

const initialState: {
    mode: ActionMode,
    data: IDiscountData | null
} = {
    mode: "none",
    data: null
}


export const TransactionForm = createSlice({
    name: 'TransactionForm',
    initialState,
    reducers: {
        setTransactionFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },
        emptyDiscountFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'none'
            return newState;
        },

        setDiscountFormData: (state, action: { payload: IDiscountData }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },
        emptyDiscountFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        }
    }
})

export const {
    setTransactionFormMode,
    emptyDiscountFormMode,
    setDiscountFormData,
    emptyDiscountFormData,
} = TransactionForm.actions

export default TransactionForm.reducer