import React from 'react'
import { baseUrl } from '../../../config/ApiConf'

function VideoComponent({
    src,
    className = 'rounded-md  h-full w-full object-cover ',

}:
    {
        src: String,
        className: string,

    }) {
    return (
        <>
        <video controls className={className} src={baseUrl+src} ></video>'

          
        </>
    )
}

export default VideoComponent