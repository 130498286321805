import React, { useEffect, useState } from 'react'
import { usePermissionQuery } from '../data/services/Permission';
import { Menus } from '../config/Menu';

const useGetAllPermissions = () => {
    const [permissions, setPermissions] = useState<any[]>([]);

    const { data } = usePermissionQuery();

    useEffect(() => {
        if (data) {
            let permissionData = data?.data ?? [];
            let newPermissions:any[] = [];
            Menus.forEach((menu) => {

                if (menu.keyGroupPermission !== '') {
                    let permission = permissionData.filter((item) => item.key.includes(menu.keyGroupPermission));
                    newPermissions = [...newPermissions, ...[{
                        [menu.name]: permission
                    }]]
                }
                if (menu.haveChildren) {
                    menu.children?.forEach(children => {
                        if (children.keyGroupPermission !== '') {

                            let childPermissions = permissionData.filter((item) => item.key.includes(children.keyGroupPermission));

                            newPermissions = [...newPermissions, ...[{
                                [children.name]: childPermissions
                            }]]
                        }

                    });
                }

            })
            setPermissions(newPermissions);
        }

    }, [data])

    return permissions
}

export default useGetAllPermissions