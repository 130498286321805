import { Switch } from '@mui/material'
import React from 'react'

export default function SwitchComponent({ checked, handleChange }: { checked: boolean, handleChange: Function }) {
    return (
        <>
            <Switch
                checked={checked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event.target.checked);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </>
    )
}
