import React from 'react'
import { FormComponentProps } from '../../../../interface/Element'
import InputTxt from '../../../shared/form/input/InputTxt'
import { useTranslation } from 'next-i18next'


import FileInput from '../../../shared/form/upload/FileInput'
import { ImgUploadType } from '../../../../config/App'
import CkComponent from '../../../shared/ck'
import CategorySelect from '../../../category/select/CategorySelect'
import InputTextarea from '../../../shared/form/input/InputTextarea'

export default function FormItemBlog({ control, getValues, setValue, errors }: FormComponentProps) {
    const { t } = useTranslation()
    return (
        <div className='flex flex-col gap-4'>
            <div className="w-full flex flex-col sm:flex-row items-start justify-between gap-4">
                <InputTxt classContainer='w-full' control={control} errors={errors} keyValue='name' label={t("Txt.name")} name='name' placeholder={t("Txt.name")} />
                <InputTxt classContainer='w-full' control={control} errors={errors} keyValue='summery' label={t("Txt.summery")} name='summery' placeholder={t("Txt.summery")} />
            </div>
            <div className="w-full flex flex-col sm:flex-row items-start justify-between gap-4">
                <InputTxt classContainer='w-full' control={control} errors={errors} keyValue='alt' label={t("Common.alt")} name='alt' placeholder={t("Common.alt")} />
                
                <CategorySelect control={control} errors={errors} name='category_id' key={'category_id'} type='blog' />
            </div>
            <div className="w-full flex flex-col sm:flex-row items-start justify-between gap-4">
                <InputTxt classContainer='w-full' control={control} errors={errors} keyValue='title_seo' label={t("Common.titleSeo")} name='title_seo' placeholder={t("Common.titleSeo")} />
                <InputTextarea classContainer='w-full' errors={errors} label={t("Common.descriptionSeo")} name="description_seo" control={control}
                    keyValue="description_seo" />
            </div>
           
            <div className='flexRow flex-wrap gap-x-4 w-full items-center'>
               
                <div className='w-[100%] my-4'>
                    <CkComponent txt={getValues("description") ?? ""} changeFun={setValue ? (data: string) => setValue("description", data) : () => { }} />
                </div>

                <div className='w-[100%]'>
                    <FileInput
                        changeValue={(file: File) => console.log(file)}
                        getValues={getValues}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        name="image"
                        accept={ImgUploadType}
                        title={t("Txt.chooseImg")} />
                </div>
            </div>

        </div >


    )
}
