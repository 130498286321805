import { LOGIN_PATH, baseUrl } from '../../config/ApiConf'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { ResultApi } from '../../interface/Api'

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: fetchBaseQuery({ baseUrl}),

  tagTypes: [],
  endpoints: (builder) => ({

    login: builder.mutation<ResultApi, any>({
      query: (body) => ({
        url: LOGIN_PATH,
        method: 'POST',
        body
      })
      ,
    }),
  }),

})


export const { useLoginMutation} = loginApi