import React, { useState } from 'react'
import Form from '../components/category/form'
import Show from '../components/category/show'

export default function Category() {

  return (
    <> 
      <div className='flexCol gap-4 pageLayout'>
        <Form />
        <Show />
      </div>

    </>
  )
}
