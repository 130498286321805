import { ChangeToFormData, ChangeToQueryString } from "../../utility/Function";
import { SupportAddReplyPropsApi, SupportDetailApi, SupportListApi, SupportListFilterApi } from "../../interface/EntitySupport";

import { ID } from "../../interface/EntityCommon";
import { SUPPORT_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const SupportApi = createApi({
    reducerPath: 'SupportApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Support"],

    endpoints: (builder) => ({
        Support: builder.query<SupportListApi, SupportListFilterApi>({
            query: (body) => ({
                url: SUPPORT_PATH+ChangeToQueryString(body),
                method: 'GET',

            })
            ,
            providesTags: ['Support'],
        }),
        SupportDetail: builder.query<SupportDetailApi, ID>({
            query: (body) => ({
                url: SUPPORT_PATH+"/"+body.id,
                method: 'GET',

            })
            ,
            providesTags: ['Support'],
        }),

        SupportAdd: builder.mutation<void, SupportAddReplyPropsApi>({
            query: ({id,...body}) => ({
                url: SUPPORT_PATH+"/"+id,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Support'],
        }),

      
   



    }),



})

export const {
    useSupportQuery,
    useSupportDetailQuery,
    useSupportAddMutation,
 
} = SupportApi


