import React from 'react'
import { ProductDetailData } from '../../../../interface/EntityProduct'
import Gallery from './Gallery'
import Detail from './Detail'

function DetailP({ ...data }:ProductDetailData) {
    return (
        <>

            <Gallery data={data.gallery} />
            <Detail {...data} />

        </>
    )
}

export default DetailP