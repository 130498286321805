import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, RoleDataType } from "../../../interface/EntityRole";
import { PermissionData } from "../../../interface/EntityPermission";

const initialState: {
    mode: ActionMode,
    data: RoleDataType | null,
    deleteItem:RoleDataType|null,
    permissions:PermissionData[]
    
} = {
    mode: "add",
    data: null,
    deleteItem:null,
    permissions:[]

}


export const RoleForm = createSlice({
    name: 'RoleForm',
    initialState,
    reducers: {
        setRoleFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },

        emptyRoleFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'add'
            return newState;
        },

        setRoleFormData: (state, action: { payload: RoleDataType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },

        setRolePermissionsFormData: (state, action: { payload: PermissionData[] }) => {
            let newState = state;
            newState['permissions'] = action.payload
            return newState;
        },

        emptyRoleFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },
        emptyRolePermissionsFormData:(state)=>{
            let newState=state
            newState['permissions']=[];
            return newState;
        },


        setRoleDeleteItem: (state, action: { payload: RoleDataType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptyRoleDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setRoleFormMode,
    emptyRoleFormMode,
    setRoleFormData,
    emptyRoleFormData,
    setRoleDeleteItem,
    emptyRoleDeleteItem
    
} = RoleForm.actions

export default RoleForm.reducer