import React from 'react'
import { MenuItemProps } from '../../interface/Menu'
import { useTranslation } from 'next-i18next'
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setClickMenu } from '../../data/slice/sidebar/Sidebar';

const MenuItem = ({ ...param }: MenuItemProps) => {
    const { t } = useTranslation();
    const url = useLocation();
    const dispatch = useDispatch()
    return (
        <>
                <Link to={param.url} onClick={()=>{dispatch(setClickMenu(true))}}>
            <div key={param.name}
                className={` hover:bg-[--brandLight] transition-all duration-300 ps-[10px] rounded-[--radius] flex flex-row gap-x-[12px] h-[48px] items-center py-4 ${url.pathname==(param.url) ? "bg-[--brandLight]" : ""}`}>
                {param.icon}
                <span>{t(`Menu.${param.name}`)} </span>
            </div>
            </Link>
        </>
    )
}

export default MenuItem