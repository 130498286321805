import { useEffect, useState } from 'react'
import { baseUrl } from '../../../../config/ApiConf'
import { useProductRemoveFromGalleryMutation } from '../../../../data/services/Product'
import { GalleryData, ProductDetailData, ShowFile } from '../../../../interface/EntityProduct'
import ButtonBrandOuter from '../../../shared/button/ButtonBrandOuter'
import ButtonOuterBrandIcon from '../../../shared/button/ButtonOuterBrandIcon'
import RemoveDialog from '../../../shared/dialog/RemoveDialog'
import TrashIcon from '../../../shared/Icon/Trash'
import { useTranslation } from 'next-i18next'
import { CapitalizeFirstLetter } from '../../../../utility/Function'

export default function Gallery({ data, typeShow = ["both", "single", "wholesaler","general"] }: { data: GalleryData[], typeShow?: ShowFile[] }) {
    
    const {t}=useTranslation()
    const [ModalDeleteShow,setModalDeleteShow]=useState(false)
    const [removeId,setRemoveId]=useState(0);
    const [RemoveGalleryItem, results] = useProductRemoveFromGalleryMutation();
    const RemoveIcon = () => {
        if(removeId!==0)
        RemoveGalleryItem({ id:removeId })
    }



    useEffect(() => {
        if (results.isSuccess) {
            setModalDeleteShow(false);
        setRemoveId(0);
        }
       
    }, [ results])


    return (
        <>
            <div className='flexRow flex-wrap gap-10 p-4 justify-center'>
                {data.map((item) => {
                    if (!typeShow.includes(item.show))
                        return <></>

                    return <div className='w-[300px] h-auto flexCol gap-2 items-center'>
                        {item.type === 'image' &&
                            <img className='w-full h-full rounded-[--radius] object-cover object-center aspect-auto' src={baseUrl + item.pathFile} alt="" />}
                        {item.type === 'video' &&
                            <video width="320" height="240" className=' rounded-[--radius]' controls>
                                <source src={baseUrl + item.pathFile} />
                            </video>
                        }
                        <p>{t("Txt.show"+CapitalizeFirstLetter(item.show))}</p>
                        <ButtonOuterBrandIcon loading={results.isLoading} click={() => {setRemoveId(item.id);setModalDeleteShow(true)}}>
                            <TrashIcon size='20px' fill={'var(--error)'} />
                        </ButtonOuterBrandIcon>

                    </div>
                }
                )}

            </div>
                {ModalDeleteShow&&<RemoveDialog 
                open={ModalDeleteShow}
                DeleteFunction={()=>RemoveIcon()}
                CloseModal={()=>setModalDeleteShow(false)}
                cancelTitle={t("Common.cancel")}
                deleteAgreeTitle={""}
                deleteTitle={t("Common.delete")}
                loadingConfirmButton={results.isLoading}
                title={t("Txt.removeImgFromGallery")}
                />}
        </>
    )
}
