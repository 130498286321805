import { GalleryData, ShowFile } from '../interface/EntityProduct'
import moment from 'jalali-moment'

export const buildQueryString = (params: Object) => {
  let queryString = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

  queryString = queryString.replaceAll('#', '%23')
  return queryString
}

export const ChangeToFormData = (body: any) => {
  let keys = Object.keys(body)
  let bodyFormData: FormData = new FormData()
  keys.forEach(item => {
    bodyFormData.append(item, body[item])
  })
  return bodyFormData
}

export const changeToFormDataDiscount = (body: any) => {
  let keys = Object.keys(body)
  let bodyFormData: FormData = new FormData()
  keys.forEach(item => {
    if (item === 'users') {
      const users = body[item]
      for (let i = 0; i < body[item].length; i++) {
        bodyFormData.append(
          `users[${i}][user_id]`,
          users[i].id ? users[i].id : users[i]
        )
      }
    } else {
      bodyFormData.append(item, body[item])
    }
  })
  return bodyFormData
}

export const ChangeToFormDataGalleryProducts = (
  images: File[],
  show: ShowFile,
  alt: string
) => {
  let bodyFormData: FormData = new FormData()
  for (let i = 0; i < images.length; i++) {
    bodyFormData.append(`images[${i}][file]`, images[i])
    bodyFormData.append(`images[${i}][show]`, show)
    bodyFormData.append(`images[${i}][alt]`, alt)
  }

  return bodyFormData
}

export const ChangeToFormDataGalleryVideoProducts = (
  videos: File[],
  show: ShowFile
) => {
  let bodyFormData: FormData = new FormData()
  for (let i = 0; i < videos.length; i++) {
    bodyFormData.append(`videos[${i}][file]`, videos[i])
    bodyFormData.append(`videos[${i}][show]`, show)
  }

  return bodyFormData
}

export const ChangeToFormDataSiteInfo = (params: { [index: string]: any }) => {
  console.log('params is : ', params)
  let bodyFormData: FormData = new FormData()
  let keys = Object.keys(params)
  keys.forEach((item, index) => {
    if (params[item] !== undefined) {
      if (keys[index] !== 'calculate_price_type') {
        bodyFormData.append(`info[${index}][key]`, item)
        bodyFormData.append(`info[${index}][value]`, params[item])
        if (keys[index] == 'tax_percent') {
          bodyFormData.append(`info[${index}][is_order_price]`, '1')
          bodyFormData.append(`info[${index}][calculate_price_type]`, 'percent')
        }
        if (keys[index] == 'post_price') {
          bodyFormData.append(`info[${index}][is_order_price]`, '1')
          bodyFormData.append(`info[${index}][calculate_price_type]`, 'fix')
        }
      }
    }
  })

  return bodyFormData
}

export const ChangeToFormDataPermission = (params: {
  [index: string]: any
}) => {
  let bodyFormData: FormData = new FormData()
  let keys = Object.keys(params)
  keys.forEach((item: any, index) => {
    if (item === 'role_id') {
      bodyFormData.append(`role_id`, params[item])
    }
    if (item === 'permissions') {
      params['permissions'].forEach((data: any) => {
        bodyFormData.append(`permissions[][id]`, data)
      })
    }
  })

  return bodyFormData
}

export const ChangeToFormDataProductSection = (params: {
  [index: string]: any
}) => {
  let bodyFormData: FormData = new FormData()
  let keys = Object.keys(params)
  keys.forEach((item: any, index) => {
    if (item === 'product_id') {
      bodyFormData.append(`product_id`, params[item])
    }
    if (item === 'sections') {
      params['sections'].forEach((data: any) => {
        bodyFormData.append(`sections[][id]`, data)
      })
    }
  })

  return bodyFormData
}

export const ChangeToQueryString = (body: any) => {
  let keys = Object.keys(body)
  let string = '?'
  keys.forEach((item, index) =>
    index > 0
      ? (string += '&' + item + '=' + body[item])
      : (string += item + '=' + body[item])
  )
  return string
}

export const changeToQueryParams = (body: any) => {
  let queryString = '?'

  const keys = Object.keys(body)
  const values = Object.values(body)

  if (keys.length > 0) {
    keys.map((key, index) => {
      if (
        values[index] !== undefined &&
        values[index] !== '' &&
        values[index] !== ''
      ) {
        queryString = `${queryString}${index > 0 ? '&' : ''}${keys[index]}=${
          values[index]
        }`
      }
    })
  }

  return queryString
}

export const CapitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const IsOnline = (no: Function, yes: Function) => {
  var xhr = new XMLHttpRequest()
  xhr.onload = function () {
    if (yes instanceof Function) {
      yes()
    }
  }
  xhr.onerror = function () {
    if (no instanceof Function) {
      no()
    }
  }
  xhr.open('GET', 'anyPage.php', true)
  xhr.send()
}

export const getAttachUrlProduct = (File: GalleryData[], show: ShowFile) =>
  File.filter(item => item.show === show)

export const NullValue = (string: string | null | undefined) =>
  string !== 'null' && string !== 'undefined' ? string ?? '' : ''

export const refreshPage = () => {
  window.location.reload()
}
export const showPersianDate = (date: string) => {
  // return moment(date).locale("fa").format("DD/MMM/YYYY")
  return moment(date).locale('fa').format('DD MMM YYYY')
}
