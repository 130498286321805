import { useEffect, useState } from 'react'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import FormItem from '../index';
import { ToastError, ToastSuccess } from '../../../../../utility/ToastFunction';
import ButtonBrand from '../../../../shared/button/ButtonBrand';
import ModalFull from '../../../../shared/modal/ModalFull';
import { useRoleEditMutation } from '../../../../../data/services/Role';
import PageMarginBottom from '../../../../shared/Html/PageMarginBottom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store';
import { setRoleFormMode } from '../../../../../data/slice/role/RoleForm';
import { useAdminEditMutation } from '../../../../../data/services/Admin';
import { emptyAdminFormData, setAdminFormMode } from '../../../../../data/slice/admin/AdminForm';

export default function EditAdmin({ openModal, ChangeOpen }: { openModal: boolean, ChangeOpen: Function }) {

    const { t } = useTranslation();
    const Data = useSelector((state: RootState) => state.AdminForm.data);

    const dispatch= useDispatch()
    const [OpenAdd, setOpenAdd] = useState(false);
    const [adminEdit, results] = useAdminEditMutation()

    const Schema = Yup.object().shape({
        name: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema),
      
    });

    const OnFinish: SubmitHandler<any> = (data) => {

        if (data?.name !== undefined) {
            adminEdit({...data,...{id:Data?.id}})
           
        
        }
    }

    useEffect(() => {
        setOpenAdd(openModal);
        reset();
    }, [openModal, reset])

    useEffect(() => {
        if (results.isSuccess) {
            
            
            ToastSuccess(t("Common.successAlarm"))
            setOpenAdd(false);
            dispatch(setAdminFormMode("none"))
            dispatch(emptyAdminFormData());

        }
        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    useEffect(()=>{
        if(Data){
            reset({
                username:Data.username,
                name:Data.name,
                role_id:Data.role_id
            })
        }
    },[Data])
    
 

    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => { setOpenAdd(open); ChangeOpen(open); }} Open={OpenAdd} title={t("Txt.addRole")}>
                <div className="FormModal !h-auto">
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />

                        <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]' >
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>

                    <PageMarginBottom />


                </div>

            </ModalFull>

        </>
    )
}
