import { RoleAddPropsApi, RoleListApi, RoleListFilterApi, RoleRemovePropsApi, RoleUpdatePropsApi } from "../../interface/EntityRole";

import { ChangeToFormData, ChangeToFormDataPermission, ChangeToQueryString } from "../../utility/Function";
import { ROLE_PERMISSION } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'
import { ID } from "../../interface/EntityCommon";
import { RoleListPermissionApi } from "../../interface/EntityPermission";

export const RolePermissionApi = createApi({
    reducerPath: 'RolePermissionApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["RolePermission"],

    endpoints: (builder) => ({
        RolePermission: builder.query<RoleListPermissionApi, ID>({
            query: (body) => ({
                url: ROLE_PERMISSION+"/"+body.id,
                method: 'GET',
            })
            ,
            providesTags: ['RolePermission'],
        }),


        RolePermissionAdd: builder.mutation<void, any>({
            query: (body) => ({
                url: ROLE_PERMISSION,
                method: 'POST',

                body: ChangeToFormDataPermission(body)
            }),
            invalidatesTags: ['RolePermission'],
        }),

    }),



})

export const {
    useRolePermissionQuery,
    useRolePermissionAddMutation,
} = RolePermissionApi


