import { useTranslation } from "next-i18next"
import * as Yup from "yup"
import ButtonBrand from "../../shared/button/ButtonBrand";
import SearchIcon from "../../shared/Icon/Search";
import OrderSelect from "../status/select";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { OrderFilterProps } from "../../../interface/EntityOrder";
import { setOrderFormFilter } from "../../../data/slice/order/OrderForm";
import { useDispatch } from "react-redux";



function FilterOrder() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const Schema = Yup.object().shape({
        order_status_id: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<OrderFilterProps> = (data) => {
        if (data?.order_status_id !== '0') {
            dispatch(setOrderFormFilter(data));
        }
    }

    return (
        <>
            {/* <div className="flexCol flex-wrap gap-4  bg-[--light] w-full rounded-[--radius] p-4">
                <b>{t("Common.filter")}</b>

                <form className="flexCol gap-4" onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                    <div className="flexRow items-center gap-2 w-full">

                        <OrderSelect control={control} errors={errors} key="order_status_id" name="order_status_id" />
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="">
                            <ButtonBrand type="submit" key={"buttonFilter"} click={() => { }} >
                                <div className="flexRow gap-4 items-center">
                                    <b>{t("Common.search")}</b>
                                    <SearchIcon stroke="var(--light)" />
                                </div>
                            </ButtonBrand>
                        </div>
                    </div>
                </form>


            </div> */}
        </>
    )
}

export default FilterOrder