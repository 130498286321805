import { SvgProps } from '../../../interface/Element'

function DashboardIcon({ fill = "#A1A0A3", size = "20px", stroke = "#000000" }: SvgProps) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width={size} fill={fill}>
                <path d="M160-120v-720h640v400H240v80h200v80H240v80h200v80H160Zm456 0L504-232l56-56 56 56 142-142 56 56-198 198ZM240-520h200v-80H240v80Zm280 0h200v-80H520v80ZM240-680h200v-80H240v80Zm280 0h200v-80H520v80Z" /></svg>

        </>
    )
}

export default DashboardIcon