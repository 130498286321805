import { useState } from 'react';
import { Loader } from '../../shared/Loader';
import { useTranslation } from 'next-i18next';
import { useRoleQuery } from '../../../data/services/Role';
import FormRoles from '../form/Index';
import ListDataTable from './../../shared/listData/index';

// icons
import SettingIcon from '../../shared/Icon/Setting';
import TrashIcon from '../../shared/Icon/Trash';

// redux
import { useDispatch } from 'react-redux';
import { setRoleDeleteItem, setRoleFormData, setRoleFormMode } from '../../../data/slice/role/RoleForm';

function ListData() {
  const { t } = useTranslation()
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');


  const { data, isLoading } = useRoleQuery({ page, name })
  const dispatch = useDispatch();

  const Headers = [
    { value: <b>#</b>, width: "w-[150px]" },
    { value: <b >{t("Txt.name")}</b> },
    { value: <b>{t("Txt.description")}</b> },
    { value: <b>{t("Txt.actions")}</b>, width: "w-[380px]" },

  ];
  const Rows = data?.data?.data.map((item) => {
    return {
      row: [
        { value: <div className='ms-1'>{item.id}</div>, width: "w-[150px]" },

        { value: <div>{item.name}</div> },
        { value: <div>{item.content}</div> },
        {
          value: <div className='flexRow items-center'>
            <div className='cursor-pointer' onClick={() => {
              dispatch(setRoleFormMode("view"));
              dispatch(setRoleFormData(item));
            }}>
              <SettingIcon fill="var(--secondary)" size='30px' />
            </div>

            <div className='cursor-pointer' onClick={() => {
              dispatch(setRoleFormMode("delete"));
              dispatch(setRoleDeleteItem(item));
            }}>
              <TrashIcon fill="var(--error)" size='30px' />
            </div>

          </div>, width: "w-[380px]"
        }


      ]
    }
  })
  return (
    <>
      <div className='  py-4' >
        {isLoading && <div className='flexRow justify-center w-full'><Loader /></div>}
        {!isLoading && <ListDataTable
            header={Headers}
            setPage={setPage}
            rows={Rows ?? []}
            currentPage={+(data?.data.current_page ?? 1) - 1}
            rowsPerPage={+(data?.data.per_page ?? 0)}
            total={+(data?.data.total ?? 0)}
            handleChangePage={(params1: any, params2: number) =>
              setPage(params2 + 1)
            }
            onRowsPerPageChange={(params1: any, params2: any) =>
              console.log(params1, params2)
            }
          />}
      </div>

      <FormRoles />


    </>
  )
}

export default ListData