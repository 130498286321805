import { FAQ_GET_PATH,FAQ_PATH } from './../../config/ApiConf';
import { FaqList,FaqAddPropsApi,FaqRemovePropsApi, FaqUpdatePropsApi } from "../../interface/EntityFaq";

import { ChangeToFormData } from "../../utility/Function";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const FaqApi = createApi({
    reducerPath: 'FaqApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Faq"],

    endpoints: (builder) => ({
        Faq: builder.query<FaqList, void>({
            query: () => ({
                url: FAQ_GET_PATH,
                method: 'GET',
            }),
            providesTags: ['Faq'],
        }),
     
        FaqAdd: builder.mutation<any, FaqAddPropsApi>({
            query: (body) => ({
                url: FAQ_PATH,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Faq'],
        }),

        FaqEdit: builder.mutation<void, FaqUpdatePropsApi>({
            query: ({ id, ...res }) => ({
                url: FAQ_PATH + "/" + id,
                method: 'POST',
                body: ChangeToFormData({...res,...{"_method":"PUT"}})
           
            }),
            invalidatesTags: ['Faq'],
        }),

        FaqRemove: builder.mutation<void, FaqRemovePropsApi>({
            query: (body) => ({
                url: FAQ_PATH + "/" + body.id,
                method: 'DELETE',

            }),
            invalidatesTags: ['Faq'],
        }),

    }),



})

export const {
    useFaqQuery,
    useFaqAddMutation,
    useFaqEditMutation,
    useFaqRemoveMutation
} = FaqApi


