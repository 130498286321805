import { useTranslation } from "next-i18next"
import TitleValue from "../../../shared/Html/TitleValue"
import { IComment } from "../../../../interface/EntityComment"
import { FC, useEffect } from "react"
import GrayLine from "../../../shared/Html/GrayLine"
import { useProductCommentEditMutation } from "../../../../data/services/Product"
import SwitchComponent from "../../../shared/form/switch"
import { ToastSuccess } from "../../../../utility/ToastFunction"

interface IProps {
    comments?: any
}

const CommentList: FC<IProps> = ({ comments }) => {

    const { t } = useTranslation()
    const [edit, result] = useProductCommentEditMutation()

    useEffect(() => {
        // if (result?.data?.success) {
        //     ToastSuccess(t("Common.successAlarm"))
        // }
        // console.log("result : ", result)
    }, [result])

    return (
        <>
            <div className="FormModal py-4 w-full">
                <div className='flexCol gap-10 text-lg '>
                    {comments?.map((comment: IComment) => (
                        <div className="w-full flex flex-col">
                            <div className="w-full flex flex-row">
                                {/* <div key={comment.id} className="flexRow gap-1 md:w-[75%] md:mx-auto"> */}
                                <div key={comment.id} className="flex flex-row justify-center md:w-[75%] md:mx-auto items-center">
                                    <TitleValue title={t("Common.comment")} value={comment.comment} className="justify-center flex flex-row pr-2" />
                                </div>
                                <div key={comment.id} className="flex justify-evenly gap-1 items-center md:w-[25%] md:mx-auto">
                                    <p>{t("Common.Do you agree with this comment")}</p>
                                    <SwitchComponent checked={comment.confirm === 1} handleChange={(data: any) => { edit({ confirm: data ? 1 : 0, id: comment.id }) }} />
                                </div>

                            </div>
                            <GrayLine />
                        </div>

                    ))}
                </div>
            </div>

        </>

    )
}
export default CommentList