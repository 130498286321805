import { ColumnData, TablePropsData } from '../../../interface/ListData'

import PageMarginBottom from '../Html/PageMarginBottom'
import React from 'react'
import { Pagination, TablePagination } from '@mui/material'
import { useTranslation } from 'next-i18next'

export default function Table ({
  header,
  rows,
  currentPage,
  handleChangePage,
  onRowsPerPageChange,
  rowsPerPage,
  total,
  showPage = true
}: TablePropsData) {
  const width = window.innerWidth
  const { t } = useTranslation()
  const TdClass =
    'flex justify-start items-center border-r  border-r-[--brand] bg-[--white] flex-grow px-1 flex-wrap py-4 text-[--secondaryTxt] items-center '

  const ColumnComponent = (item: ColumnData, className = 'h-[60px]') => (
    <>
      <div
        className={`flex-grow-1 overflow-hidden ${TdClass} ${className} ${
          item.width ? `${item.width}` : `w-full`
        }`}
      >
        {item.value}
      </div>
    </>
  )

  const Header = () => (
    <>
      <div className='bg-[--brandLight] flexRow justify-between  text-center  rounded-t-[--radius] sticky top-0'>
        {header.map(item => ColumnComponent(item))}
      </div>
    </>
  )

  return (
    <>
      <div className='hidden md:flex md:flex-col  w-full   rounded-[--radius] border border-[--brand]  relative'>
        <Header />
        <div className='overflow-auto flexCol'>
          {rows.map((item, index) => (
            <>
              <div
                className={`flexRow justify-between text-center items-center border-b border-b-[--brand] even:bg-[--brandTableLight] `}
              >
                {item.row.map(data => ColumnComponent(data, 'h-[70px]'))}
              </div>
            </>
          ))}
        </div>
      </div>
      <div className='md:hidden w-full flex flex-col gap-8'>
        {rows.map((row) => (
          <div className='w-full rounded-lg shadow p-4'>
            <div className='w-full flex flex-col gap-4'>
              {header.map((item,index) => (
                <div className='flex items-center gap-4'>{item.value } : {row.row[index]?.value}</div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {showPage && (
        <TablePagination
          sx={{ direction: 'initial' }}
          labelRowsPerPage={t('Common.labelRowsPerPage')}
          labelDisplayedRows={({ from, to, count }) =>
            `${t('Common.displayingRecord')} ${from}-${to} ${t(
              'Common.ofTotal'
            )} ${count}`
          }
          component='div'
          count={total ?? 0}
          page={currentPage ?? 1}
          onPageChange={(event: React.MouseEvent | null, page: number) =>
            handleChangePage ? handleChangePage(event, page) : () => {}
          }
          rowsPerPage={rowsPerPage ?? 0}
          rowsPerPageOptions={[rowsPerPage ?? 0]}
          onRowsPerPageChange={(event: React.ChangeEvent) =>
            onRowsPerPageChange ? onRowsPerPageChange(event) : () => {}
          }
        />
      )}
      <PageMarginBottom />
    </>
  )
}
