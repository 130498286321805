import Common from './fa/Common.json'
import Menu from './fa/Menu.json'
import Validation from './fa/Validation.json'
import Txt from './fa/Txt.json'
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  fa: {
    translation: {
      Common,
      Txt,
      Menu,
      Validation
    }
  },
  en: {
   // translation: translationEN,
  },

};

//i18N Initialization

i18next
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  resources,
  lng: "fa", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // if you're using a language detector, do not define the lng option

  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18next;
