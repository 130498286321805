import React, { useEffect } from 'react'
import FormItem from '../FormItem'
import * as Yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProductAddInventoryPropsApi, ProductDetailData } from '../../../../../../interface/EntityProduct';
import { useProductAddInventoryMutation } from '../../../../../../data/services/Product';
import ButtonBrand from '../../../../../shared/button/ButtonBrand';

function AddInventory({ ...data }: ProductDetailData) {
    const { t } = useTranslation()
    const [addColorSize, results] = useProductAddInventoryMutation()

    const Schema = Yup.object().shape({
        amount: Yup.string().required(t("Validation.require")),
        size: Yup.string().required(t("Validation.require")),
        color: Yup.string().required(t("Validation.require")),
    });
    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<ProductAddInventoryPropsApi> = (param) => {
        if (param.amount !== undefined) {
            addColorSize({ ...param, ...{ product_id: data.id } })
        }
    }


    useEffect(() => {
        if(results.isSuccess)
        reset();
    }, [results, reset])


    return (
        <>
            <form onSubmit={handleSubmit((values: any) => OnFinish(values))} className='flexCol gap-10'   >

                <div className='flexCol gap-4 p-4'>
                    <FormItem control={control} errors={errors} />
                </div>
                <div className='flex justify-center'>
                    <div className='w-1/2'>
                        <ButtonBrand type='submit' loading={results.isLoading} click={() => { }}>
                            {t("Common.saveButton")}
                        </ButtonBrand>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddInventory