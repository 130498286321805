import LoginForm from '../../../components/auth/login/LoginForm'
import LoginLayout from '../../../layout/login/Index'
import React from 'react'

function LoginPage() {
    return (
        <LoginLayout>
            <LoginForm />
        </LoginLayout>
    )
}

export default LoginPage