import { Controller } from 'react-hook-form'
import React from 'react'
import { SelectType } from './interface'

const SelectInput = ({
  label,
  control,
  rules,
  name,
  defaultValue,
  errors = {},
  changeF,
  options
}: SelectType) => {

  const HtmlElement = ({ value, onChangeF }: { value: string, onChangeF: any }) => <>
    <div className="InputLabelClass">
      <label htmlFor="" className="LabelClass">{label}</label>
      <select
        value={value}
        name={name}
        id={name}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          let value=event.currentTarget.value
           onChangeF(value);
           if(changeF){
            changeF(value)
           }

         }}
        className="InputClass"  >
        {options.map((item) => <option value={item.value} selected={defaultValue === item.value}>{item.label}</option>)}
      </select>

    </div>
  </>

  return (
    <>
      {control && <Controller
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <HtmlElement onChangeF={onChange} value={value} />

        )}

        name={name} />}
      {!control && <HtmlElement value={defaultValue ?? ""} onChangeF={changeF} />}
      <div>
        <p className='text-red-400 font-bold text-sm m-2'>{errors[name]?.message}</p>

      </div>
    </>
  )
}

export default SelectInput