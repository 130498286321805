import React, { useEffect, useState } from 'react'

import Header from './header/Header'
import Sidebar from './sidebar'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../data/store'
import { setClickMenu } from '../data/slice/sidebar/Sidebar'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const [display, setDisplay] = useState(window.innerWidth > 1024 ? '' : 'none')
  const isClick=useSelector((state:RootState)=>state.Sidebar.isClick);
  const dispatch=useDispatch()
  const matches = useMediaQuery('(min-width:600px)')

  const handleShow = () => {
    if (!matches) {
      setDisplay('none')
    }
  }

  useEffect(()=>{
    if(isClick){
      if(window.innerWidth<=750){
        setDisplay("none");
        dispatch(setClickMenu(false))

      }
    }
  },[isClick])

  return (
    <>
      <div
        dir='rtl'
        className='w-full h-screen lg:flex lg:flex-row overflow-y-auto'
      >
        <div
          className={`fixed transition-all h-screen duration-500 bg-[--light] z-10 border-r border-r-[--border] lg:sticky start-0 top-0  ${
            display === 'none' ? '!w-0' : 'w-[320px]'
          }`}
        >
          <Sidebar handleSidebar={() => handleShow()} />
        </div>
        <div className='flex-grow-1 w-full'>
          <div className=' flex  flex-col'>
            <Header
              showHideSideBar={() =>
                setDisplay(prevDisplay =>
                  prevDisplay === 'none' ? '' : 'none'
                )
              }
            />
            <div
              className='max-h-dvh w-full lg:w-[95%] lg:mx-auto  mt-10 '
              onClick={() => {
                if (window.innerWidth < 750) {
                  setDisplay('none')
                }
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
