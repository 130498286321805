import Header from '../../components/role/header'
import ListData from '../../components/role/list/Index'
import React from 'react'

export default function Sections() {
  return (
    <>
      <div className="flexCol gap-4 pageLayout">

        <Header />
        <ListData />

      </div>
    </>
  )
}
