import { useTranslation } from "next-i18next"
import InputTxt from "../../shared/form/input/InputTxt"
import { FormComponentProps } from "../../../interface/Element"
import MobileInput from "../../shared/form/input/MobileInput"
import CustomSelect from "../../shared/form/input/CustomSelect"

export default function FormItemUser({ control, getValues, setValue, errors, watch }: FormComponentProps) {

    const { t } = useTranslation()

    return (
        <>
            <div className='flexCol flex-wrap w-full'>

                <InputTxt
                    control={control}
                    errors={errors}
                    keyValue='first_name'
                    label={t("Common.first_name")}
                    name='first_name'
                    placeholder={t("Common.first_name")} />

                <InputTxt
                    control={control}
                    errors={errors}
                    keyValue='last_name'
                    label={t("Common.last_name")}
                    name='last_name'
                    placeholder={t("Common.last_name")} />
                <InputTxt
                    control={control}
                    errors={errors}
                    keyValue='display_name'
                    label={t("Common.display_name")}
                    name='display_name'
                    placeholder={t("Common.display_name")} />
                <MobileInput readOnly={true}  isRequired={false} label={t("Common.mobile")} name='mobile' defaultValue='' errors={errors} keyValue='mobile'
                    control={control} classContainer="w-full"
                />
                <CustomSelect control={control} keyValue='type' errors={errors} label={t("Common.type")} name='type' optionItem={[{ label: t("Common.wholesale"), value: "wholesale" }, { label: t("Common.single"), value: "single" }]} />

                {/* <CustomSelect control={control} keyValue='confirm' errors={errors} label={t("Common.userState")} name='confirm' optionItem={[{ label: t("Common.confirm"), value: 1 }, { label: t("Common.reject"), value: 0 }]} /> */}

                {/* <CustomSelect control={control} keyValue='block' errors={errors} label={t("Common.block")} name='block' optionItem={[{ label: t("Common.blocked"), value: 1 }, { label: t("Common.free"), value: 0 }]} /> */}

            </div>
        </ >
    )
}
