import * as Yup from 'yup'

import { SubmitHandler, useForm } from 'react-hook-form';

import ButtonBrand from '../../../../../shared/button/ButtonBrand';
import ButtonBrandOuter from '../../../../../shared/button/ButtonBrandOuter';
import FormItem from '../FormItem'
import { KeyValue } from '../../../../../../interface/EntityProduct';
import  { useEffect } from 'react'
import { useProductUpdateKeyMutation } from '../../../../../../data/services/Product';
import { useTranslation } from 'next-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

function EditKeys({ data, cancel }: { data: KeyValue, cancel: Function }) {
    const { t } = useTranslation()
    const [ProductUpdateKey, results] = useProductUpdateKeyMutation()

    const Schema = Yup.object().shape({
        key: Yup.string().required(t("Validation.require")),
        value: Yup.string().required(t("Validation.require")),
        show: Yup.string().required(t("Validation.require")),
    });
    const { handleSubmit, control, formState: { errors }, reset } = useForm<any>({
        resolver: yupResolver(Schema),
       
    });

    const OnFinish: SubmitHandler<KeyValue> = (param) => {
        console.log(param)
        if (param.show !== undefined) {
            ProductUpdateKey({ ...param, ...{ product_id: data.product_id, id: data.id } })
        }
    }


    useEffect(() => {
        if (results.isSuccess) {
            reset();
            cancel("add")

        }

    }, [results, reset, cancel])

    useEffect(()=>{
        reset(data);
 
    },[data, reset])
    return (
        <>
            <form onSubmit={handleSubmit((values: any) => OnFinish(values))} className='flexCol gap-10'   >

                <div className='flexCol gap-4 p-4'>
                    <FormItem control={control} errors={errors} />
                </div>
                <div className='flex justify-center gap-2'>
                    <div className='w-1/2'>
                        <ButtonBrand type='submit' loading={results.isLoading} click={() => { }}>
                            {t("Common.edit")}
                        </ButtonBrand>
                    </div>
                    <div className='w-1/2'>
                        <ButtonBrandOuter type='button' click={() => { cancel("add") }}>
                            {t("Common.cancel")}
                        </ButtonBrandOuter>
                    </div>
                </div>
            </form>
        </>
    )
}

export default EditKeys