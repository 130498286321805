import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, FaqDataType } from "../../../interface/EntityFaq";
import { PermissionData } from "../../../interface/EntityPermission";

const initialState: {
    isClick:boolean
    
} = {
    isClick:false

}


export const Sidebar = createSlice({
    name: 'Sidebar',
    initialState,
    reducers: {
        setClickMenu: (state, action: { payload: boolean }) => {
            let newState = state;
            newState['isClick'] = action.payload
            return newState;
        },

    }
})

export const {
    setClickMenu,

    
} = Sidebar.actions

export default Sidebar.reducer