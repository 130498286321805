import React from 'react'
import { FormComponentProps } from '../../../../interface/Element'
import InputTxt from '../../../shared/form/input/InputTxt'
import { useTranslation } from 'next-i18next'
import NumberInput from '../../../shared/form/input/NumberInput'



export default function FormItem({ control, getValues, setValue, errors }: FormComponentProps) {
    const { t } = useTranslation()
    return (
        <>
            <div className='flexCol flex-wrap w-full'>
                <div className='w-full'>
                    <InputTxt
                        control={control}
                        errors={errors}
                        keyValue='name'
                        label={t("Common.name")}
                        name='name'
                        placeholder={t("Common.name")} />
                </div>
                <div className='w-full'>
                    <InputTxt
                        control={control}
                        errors={errors}
                        keyValue='url'
                        label={t("Common.address")}
                        name='url'
                        placeholder={t("Common.address")} />
                </div>
                <div className='w-full'>
                    <NumberInput
                        control={control}
                        errors={errors}
                        keyValue='priority'
                        label={t("Common.priority")}
                        name='priority'
                        placeholder={t("Common.priority")} />
                </div>
                

            </div>

        </ >


    )
}
