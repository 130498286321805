import { Controller } from 'react-hook-form';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { InputTypeNumber } from './interface';

import { useState } from 'react';
import useRtlDetermine from '../../hook/RtlDetermine';

export default function MobileInput({
    label,
    placeholder,
    keyValue,
    name,
    control,classContainer,
    rules,isRequired=false,
    errors, classNameInput,readOnly = false
}: InputTypeNumber) {
    const dir = useRtlDetermine()


    return (
        <div className={classContainer}>
            <div key={keyValue} dir={dir} className="InputLabelClass">
                <label htmlFor=""
                    className="LabelClass">{label} <span className="requiredStar">{isRequired ? "*" : ""}</span> </label>
                <Controller
                    control={control}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <PatternFormat
                        readOnly = {readOnly}
                            dir={'ltr'}
                            value={value ?? ''}
                            placeholder={placeholder}
                            // format={`#### ###-####`}
                            format={`####- ### ## ##`}
                            onValueChange={(values, sourceInfo) => {
                                onChange(values.value)
                            }}
                            className={`InputClass ${classNameInput}`}
                        />
                    )}
                    name={name} />
            </div>

            {errors && errors[name] && <div>
                <p className='text-red-400 font-bold text-sm m-2'>{errors && errors[name]?.message}</p>

            </div>}

        </div>
    )
}
