import Notification from '../../components/shared/Icon/Notification'
import avatar from '../../asset/img/avatar/Ellipse 270.png'
import ArrowLeftCircle from '../../components/shared/Icon/ArrowLeftCircle'
import { MouseEvent, useEffect, useState } from 'react'
import ArrowRightCircle from '../../components/shared/Icon/ArrowRightCircle'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'next-i18next'
import { Avatar, Divider } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import ListItemIcon from '@mui/material/ListItemIcon'
import PersonAdd from '@mui/icons-material/PersonAdd'

import MenuItem from '@mui/material/MenuItem'
import { useLogoutMutation } from '../../data/services/Admin'
import { ToastSuccess } from '../../utility/ToastFunction'
import { useNavigate } from 'react-router-dom'
import RemoveDialog from '../../components/shared/dialog/RemoveDialog'
import { useDispatch, useSelector } from 'react-redux'
import { setAdminFormMode } from '../../data/slice/admin/AdminForm'
import AddAdmin from '../../components/admin/form/actions/add'
import { RootState } from '../../data/store'
import { IPropfile } from '../../interface/EntityProdile'
import { useGetProfileQuery } from '../../data/services/Profile'

const Header = ({ showHideSideBar }: { showHideSideBar: Function }) => {
    const mode = useSelector((state: RootState) => state.AdminForm.mode)

    const { t } = useTranslation()
    const [profile, setProfile] = useState<IPropfile>()
    const { data, isLoading } = useGetProfileQuery()
    const [openSide, setOpenSide] = useState(false)
    const IconClass = 'fill-[--primary]'
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const [logout, result] = useLogoutMutation()
    const [showModal, setShowModal] = useState<boolean>(false)

    useEffect(() => {
        if (result.isSuccess) {
            ToastSuccess(t('Common.successTxt'))
            localStorage.removeItem('userA')
            navigate('/')
            setShowModal(false)
        }
        if (result.isError) {
            ///@ts-ignore
            ToastError(result.error?.data?.message)
        }
    }, [result])

    useEffect(() => {
        if (data?.success == true) {
            setProfile(data.data)
        }
    }, [data])

    return (
        <>
            <div className='h-[10%s] bg-[--light] px-2 flex flex-row  items-center sticky top-0 shadow-md z-50'>
                <div
                    className='cursor-pointer px-1 z-20  absolute right-0'
                    onClick={() => {
                        showHideSideBar()
                        setOpenSide(!openSide)
                    }}
                >
                    {openSide ? (
                        <ArrowLeftCircle size='40px' classN={IconClass} />
                    ) : (
                        <ArrowRightCircle size='40px' classN={IconClass} />
                    )}
                </div>
                <div className=' text-xl flex-grow'></div>
                <div className='flex flex-row items-center gap-x-4'>
                    <div>
                        <Notification fill='var(--brandLight)' size='40' />
                    </div>
                    <Tooltip title={t('Common.AccountSettings')}>
                        <IconButton
                            onClick={handleClick}
                            size='small'
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        dir='ltr'
                        anchorEl={anchorEl}
                        id='account-menu'
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        slotProps={{
                            paper: {
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0
                                    }
                                }
                            }
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem
                            onClick={() => {
                                navigate('/profile')
                                handleClose()
                            }}
                        >
                            <Avatar />
                            {t('Common.Profile')}
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                dispatch(setAdminFormMode('add'))
                                handleClose()
                            }}
                        >
                            <ListItemIcon>
                                <PersonAdd fontSize='small' />
                            </ListItemIcon>
                            {t('Common.Add another account')}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate('/setting/site_infos')
                                handleClose()
                            }}
                        >
                            <ListItemIcon>
                                <Settings fontSize='small' />
                            </ListItemIcon>
                            {t('Common.Settings')}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setShowModal(true)
                                handleClose()
                            }}
                        >
                            <ListItemIcon>
                                <Logout fontSize='small' />
                            </ListItemIcon>
                            {t('Common.Logout')}
                        </MenuItem>
                    </Menu>
                </div>
                <RemoveDialog
                    title={t('Common.Exit the panel')}
                    deleteTitle={t('Common.yes')}
                    loadingConfirmButton={result.isLoading}
                    CloseModal={() => setShowModal(false)}
                    DeleteFunction={() => logout()}
                    cancelTitle={t('Common.no')}
                    deleteAgreeTitle={t('Common.Do you want to leave the panel')}
                    open={showModal}
                />
                <AddAdmin
                    openModal={mode == 'add' ? true : false}
                    ChangeOpen={() => dispatch(setAdminFormMode('none'))}
                />
            </div>
        </>
    )
}

export default Header
