import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, UserDataType } from "../../../interface/EntityUser";
import { PermissionData } from "../../../interface/EntityPermission";
import { ProductDetailData } from "../../../interface/EntityProduct";

const initialState: {
    mode: ActionMode,
    data: ProductDetailData | null,
    deleteItem: ProductDetailData | null,

    filter: {
        page: number,
        searchName: string
    }

} = {
    mode: "none",
    data: null,
    deleteItem: null,

    filter: {
        page: 1,
        searchName: ""
    }
}


export const ProductForm = createSlice({
    name: 'UserForm',
    initialState,
    reducers: {
        setProductFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },
        setFilterProduct: (state, action: { payload: any }) => { 
            state.filter = {...state.filter,...{...action.payload}}
        },
        emptyProductFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'none'
            return newState;
        },
        setProductFormData: (state, action: { payload: ProductDetailData }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },
        emptyProductFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },
        setProductDeleteItem: (state, action: { payload: ProductDetailData }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptyProductDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    emptyProductFormMode,
    setFilterProduct
} = ProductForm.actions

export default ProductForm.reducer