import { useDispatch, useSelector } from 'react-redux'
import ButtonBrand from '../../../../shared/button/ButtonBrand'
import Drawer from '../../../../shared/drawer/Drawer'
import { useTranslation } from 'next-i18next'
import { setAdminFormMode } from '../../../../../data/slice/admin/AdminForm'
import InputTextWithoutForm from '../../../../shared/form/input/InputTextWithoutForm'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { RootState } from '../../../../../data/store'
import { useRoleQuery } from '../../../../../data/services/Role'
import SelectWithoutForm from '../../../../shared/form/input/SelectWithoutForm'

interface IPops {
  page: number
  setFilter: Dispatch<SetStateAction<{}>>
  filter: {
    page: number
    role_id: string | undefined
    name: string | undefined
  }
  getData: any
}

const FilterAdmin: FC<IPops> = ({ page, filter, setFilter, getData }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const mode = useSelector((state: RootState) => state.AdminForm.mode)

  const [name, setName] = useState('')
  const { data } = useRoleQuery({ page })
  const [role, setRole] = useState('all')
  const [roleOptions, setRoleOptions] = useState<
    { value: string; label: string }[]
  >([])

  const handleFilter = async () => {
    await setFilter({
      ...filter,
      ...{
        name: name.length >= 3 ? name : undefined,
        role_id: role === 'all' ? undefined : role,
        page: page
      }
    })
    getData()
    dispatch(setAdminFormMode('none'))
  }

  const handleRoles = async () => {
    let roles: { value: string; label: string }[] = [
      { value: 'all', label: t('Common.all') }
    ]
    await data?.data.data.map(role => {
      roles.push({ value: role.id.toString(), label: role.name })
    })
    await setRoleOptions(roles)
  }

  useEffect(() => {
    if (data?.success == true) {
      handleRoles()
    }
  }, [data])

  return (
    <Drawer
      Open={mode === 'filter' ? true : false}
      txtHead='headersh'
      changeOpenClose={() => dispatch(setAdminFormMode('none'))}
      anchor='left'
    >
      <div className='mt-6 p-4 rtl'>
        <p className='text-center font-bold text-2xl mb-10'>
          {t('Common.filter')}
        </p>
        <div className='flex flex-col gap-6'>
          <InputTextWithoutForm
            classContainer='w-full'
            value={name}
            // getValue={(e: any) => dispatch(changeSearchNameAdmin(e))}
            getValue={(e: any) => setName(e)}
            placeholder={t('Common.adminName')}
            label={t('Common.adminName')}
          />
          <SelectWithoutForm
            classContainer=''
            label={t('Common.selectRole')}
            options={roleOptions}
            placeholder={t('Common.selectRole')}
            getValue={(e: { label: string; value: string }) => {
              setRole(e.value)
              // handleChnageRole(e.value)}
            }}
          />
          <ButtonBrand
            type='button'
            className='w-30'
            click={() => {
              // dispatch(setAdminFormMode('filter'))
              handleFilter()
            }}
          >
            <b> {t('Txt.filter')} </b>
          </ButtonBrand>
        </div>
      </div>
    </Drawer>
  )
}
export default FilterAdmin
