
import { useTranslation } from 'next-i18next';
import ButtonBrand from '../../shared/button/ButtonBrand';
import { useDispatch } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { setTransactionFormMode } from '../../../data/slice/transaction/TransactionForm';


function Header() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return (
        <section className='flex flex-col gap-10'>
            <div className='w-ful flex justify-between items-center'>
                <h1 className='text-lg md:text-2xl'>{t('Menu.transactions')}</h1>
                <div className='flex justify-start items-center gap-2 md:gap-10'>

                    <ButtonBrand
                        className='!text-nowrap'
                        click={() => {
                            dispatch(setTransactionFormMode('filter'))
                        }}
                    >
                        <div className='flexRow items-center gap-2'>
                            <span>
                                <FilterAltIcon fill='var(--light)' />
                            </span>
                            <b> {t('Txt.filter')} </b>
                        </div>
                    </ButtonBrand>

                </div>
            </div>
        </section>
    )
}

export default Header