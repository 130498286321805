import { FormProps } from '../../../../../interface/Element'
import InputTextarea from '../../../../shared/form/input/InputTextarea';
import InputTxt from '../../../../shared/form/input/InputTxt'
import SelectTypeShow from '../../SelectTypeShow';
import { useTranslation } from 'next-i18next'

export default function FormItem({ control, errors }: FormProps) {
    const { t } = useTranslation();
    return (
        <>
            <div className="flexCol gap-4   flex-wrap">
                <div className="">
                    <InputTxt keyValue='key' label={t("Common.title")} name='key' control={control} errors={errors} />
                </div>
                <div className="">
                    <InputTextarea keyValue='value' label={t("Common.text")} name='value' control={control} errors={errors} />

                </div>
                <div className="">
                    <SelectTypeShow    control={control} errors={errors} />

                </div>

            </div>
        </>
    )
}
