import React, { useEffect, useState } from 'react'


import { useTranslation } from 'next-i18next';
import ModalFull from '../../../shared/modal/ModalFull';
import TabBar from '../../../../layout/tabBar';
import TabBtn from '../../../shared/Html/TabBtn';
import EditPage from './edit';
import { Loader } from '../../../shared/Loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../data/store';
import SectionsPage from './section';

export default function PageActions({ openModal, ChangeOpen, }: { openModal: boolean, ChangeOpen: Function, }) {
    const { t } = useTranslation()
    const [OpenAdd, setOpenAdd] = useState(false);
    const [tab, setTab] = useState("edit");
    const Data = useSelector((state: RootState) => state.PageForm);

    useEffect(() => {
        setOpenAdd(openModal);
    }, [openModal])


    return (
        <>
            <ModalFull ChangeOpen={(open: boolean) => { setOpenAdd(open); ChangeOpen(open); }} Open={OpenAdd} title={Data.data?.name ?? ""}>

                <TabBar>
                    <div className='w-full' onClick={() => setTab("edit")}><TabBtn active={tab === "edit"} txt={t("Txt.edit")} /></div>
                    <div className='w-full' onClick={() => setTab("sections")}><TabBtn active={tab === "sections"} txt={t("Menu.sections")} /></div>

                </TabBar>




                {tab === "edit" && <EditPage />}
                {tab === "sections" && <SectionsPage />}
                
          


        </ModalFull >
        </>
    )
}

