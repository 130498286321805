import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "next-i18next"
import { SubmitHandler, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"
import { BlogUpdatePropsApi } from "../../interface/EntityBlog"
import FormItemBlog from "../../components/blog/form/actions/FormItem"
import ButtonBrand from "../../components/shared/button/ButtonBrand"
import { useBlogAddMutation, useBlogDetailQuery, useBlogEditMutation } from "../../data/services/Blog"
import { useEffect } from "react"
import { ToastError, ToastSuccess } from "../../utility/ToastFunction"
import { baseUrl } from "../../config/ApiConf"

const PageBlog = () => {
    const params = useParams()
    const { id } = params
    const { t } = useTranslation()
    const [addBlog, resultAddBlog] = useBlogAddMutation()
    const [editBlog, resultEdit] = useBlogEditMutation();
    const { data } = useBlogDetailQuery({ id: id ? parseInt(id) : 0 }, { skip: id === undefined })
    const navigate = useNavigate();
    const Schema = Yup.object().shape({
        // image: Yup.mixed().required(t("Validation.require")),
        description: Yup.string().required(t("Validation.require")),
        name: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<BlogUpdatePropsApi> = (data) => {

        if (data?.name !== undefined) {
            let params: any = data
            if (params.image === undefined) {
                delete (params['image']);
            }

            if (id == undefined) {
                console.log("enter if")
                addBlog(params)
            } else {
                editBlog(params)
            }

        }
    }

    useEffect(() => {
        if (resultAddBlog.isSuccess || resultEdit.isSuccess) {
            ToastSuccess(t("Common.successAlarm"))
            navigate("/setting/blogs")

        }
        if (resultAddBlog.isError || resultEdit.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [resultAddBlog, resultEdit])
    useEffect(() => {
        if (data?.data) {
            reset({
                id: data.data?.id,
                name: data.data?.name,
                summery: data.data?.summery,
                description: data.data?.description,
                category_id: data.data?.category_id,
                title_seo: data.data?.title_seo,
                description_seo: data.data?.description_seo,
                alt: data.data?.alt

            });
        }
    }, [data])

    return (
        <div className="flexCol gap-4 pageLayout">
            {id && <div className='w-[50%] h-auto my-2 mx-auto'>
                <img src={baseUrl + data?.data.pathFile} className='w-full h-full object-fill  rounded-[--radius]' />

            </div>}
            <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                <FormItemBlog control={control} errors={errors} getValues={getValues} setValue={setValue} />

                <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]' >
                    <div className="w-1/3">
                        <ButtonBrand type='submit' loading={resultAddBlog.isLoading || resultEdit.isLoading} key={"buttonForm"} click={OnFinish} >
                            <b>{id ? t("Common.editBlog"): t("Common.createBlog")}</b>
                        </ButtonBrand>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default PageBlog