
export const GetItemLocal=(name:string)=>{
    return localStorage.getItem(name);
}

export const SetItemLocal=(name:string,value:any)=>{
    return localStorage.setItem(name,value);
}

export const RemoveItemLocal=(name:string)=>{
    return localStorage.removeItem(name);
}


export const CheckItemRtlLocal=()=>{
    let dir=localStorage.getItem('dir')??"rtl"
    return dir==="rtl"?true:false;
}
