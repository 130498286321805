import { useEffect, useState } from 'react'
import DialogComponent from '../../../../shared/drawer/Dialog'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store';
import { useTranslation } from 'next-i18next';
import ButtonDanger from '../../../../shared/button/ButtonDanger';
import ButtonBrandOuter from '../../../../shared/button/ButtonBrandOuter';
import GrayLine from '../../../../shared/Html/GrayLine';
import { ToastError, ToastSuccess } from '../../../../../utility/ToastFunction';
import { useBannerRemoveMutation } from '../../../../../data/services/banner';
import { emptyBannerDeleteItem, emptyBannerFormMode } from '../../../../../data/slice/banner/BannerForm';

export default function DeleteBannerComponent() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const deleteItem = useSelector((state: RootState) => state.BannerForm)
    const [open, setOpen] = useState(false);
    const [SliderRemove, results] = useBannerRemoveMutation()

    const Delete = () => {
        SliderRemove({ id: deleteItem.deleteItem?.id?? 0 })
    }

    const CloseModal = () => {
        dispatch(emptyBannerFormMode())
        dispatch(emptyBannerDeleteItem())
        setOpen(false)
    }

    useEffect(() => {
        if (results.isSuccess) {
            ToastSuccess(t("Common.successResponse"))
            CloseModal()

        }
        if (results.isError) {
            ToastError(results.error.toString())
        }
    }, [results])

    useEffect(() => {
        setOpen(deleteItem.mode === "delete")
    }, [deleteItem])

    return (
        <>
            <DialogComponent Open={open} handleCloseFun={CloseModal}>
                <div className="flexCol gap-4 rounded-[--radius]">
                    <div className="text-lg font-bold px-8 py-2">{t("Common.delete")} </div>
                    <GrayLine />
                    <div className='px-8 py-2'>
                        <p>{t("Common.deleteAgree")}</p>
                    </div>

                    <div className="flexRow gap-2 px-8 py-2">
                        <ButtonDanger loading={results.isLoading} click={() => { Delete() }}>
                            <b>{t("Common.delete")}</b>
                        </ButtonDanger>
                        <ButtonBrandOuter click={() => { CloseModal() }}>
                            <b>{t("Common.cancel")}</b>
                        </ButtonBrandOuter>
                    </div>

                </div>
            </DialogComponent>
        </>
    )
}

 