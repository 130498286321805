import { UserAddPropsApi, UserDetail, UserListApi, UserListFilterApi, UserRemovePropsApi, UserUpdatePropsApi } from "../../interface/EntityUser";

import { ChangeToFormData, changeToQueryParams, ChangeToQueryString } from "../../utility/Function";
import { USER_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'
import { ID } from "../../interface/EntityCommon";

export const UserApi = createApi({
    reducerPath: 'UserApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ["User"],
    endpoints: (builder) => ({
        User: builder.query<UserListApi, UserListFilterApi>({
            query: (body) => ({
                url: USER_PATH+changeToQueryParams(body),
                method: 'GET',
            }),
            
            providesTags: ['User'],
        }),

        UserDetail: builder.query<UserDetail, ID>({
            query: (body) => ({
                url: USER_PATH+`/${body.id}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),


        UserAdd: builder.mutation<void, UserAddPropsApi>({
            query: (body) => ({
                url: USER_PATH,
                method: 'POST',
                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['User'],
        }),

        UserEdit: builder.mutation<void, UserUpdatePropsApi>({
            query: ({ id, ...res }) => ({
                url: USER_PATH + "/" + id,
                method: 'PUT',
                body: (res)
            }),
            invalidatesTags: ['User'],
        }),

        UserEditData: builder.mutation<void, UserUpdatePropsApi>({
            query: ({ id, ...res }) => ({
                url: USER_PATH + "/" + id,
                method: 'PUT',
                body: (res)
            }),
            invalidatesTags: ['User'],
        }),

        UserRemove: builder.mutation<void, UserRemovePropsApi>({
            query: (id) => ({
                url: USER_PATH + "/" + id,
                method: 'DELETE',
            }),
            invalidatesTags: ['User'],
        }),



    }),



})

export const {
    useUserQuery,
    useUserDetailQuery,
    useUserAddMutation,
    useUserEditMutation,
    useUserEditDataMutation,
    useUserRemoveMutation
} = UserApi


