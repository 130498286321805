import { Route, Routes } from "react-router-dom";

import AdminsPage from "./page/admin/Index";
import Category from "./page/Category";
import { Home } from "@mui/icons-material";
import LayoutWithAuth from "./layout/Inner/LayoutWithAuth";
import { Loader } from "./components/shared/Loader";
import LoginPage from "./page/auth/login/Index";
import OrdersPage from "./page/order/Index";
import PaymentsPage from "./page/transaction";
import Product from "./page/product/Index";
import RolePage from "./page/role";
import SupplierPage from "./page/supplier/Index";
import SupportPage from "./page/support/Index";
import { UrlS } from "./config/Urls";
import UsersPage from "./page/user/Index";
import AboutPage from "./page/about";
import TermPage from "./page/term";
import SiteInfoPage from "./page/siteInfo";
import SliderPage from "./page/slider";
import BannerPage from "./page/banner";
import BlogPage from "./page/blog";
import FaqPage from "./page/faq";
import Sections from "./page/sections";
import Pages from "./page/pages";
import DiscountPage from "./page/discounts";
import PageProfile from "./page/profile";
import PageReview from "./page/review";
import PageBlog from "./page/blog/addBlog";

// const Map = React.lazy(() => import("./pages/Map"));

export default function Router() {
    return (
        <Routes>


            <Route path="/" element={<LoginPage />} />

            <Route element={<LayoutWithAuth />} >
                <Route path={UrlS.dashboard.main} element={<Home />} />
                <Route path={UrlS.profile.main} element={<PageProfile />} />
                <Route path={UrlS.product.main} element={<Product />} />
                <Route path={UrlS.suppliers.main+UrlS.suppliers.supplier} element={<SupplierPage />} />
                <Route path={UrlS.support.main} element={<SupportPage />} />
                <Route path={UrlS.user.main} element={<UsersPage />} />
                <Route path={UrlS.orders.main} element={<OrdersPage />} />
                <Route path={UrlS.transactions.main} element={<PaymentsPage />} />
                <Route path={UrlS.discounts.main} element={<DiscountPage />} />
                <Route path={UrlS.review.main} element={<PageReview />} />
                <Route path={UrlS.blog.add} element={<PageBlog />} />
                <Route path={UrlS.blog.edit} element={<PageBlog />} />


                <Route path={UrlS.admin.main} >
                    <Route path={UrlS.admin.admin.substring(1)} element={<AdminsPage />} />
                    <Route path={UrlS.admin.role.substring(1)} element={<RolePage />} />
                    <Route path={UrlS.admin.pages.substring(1)} element={<Pages />} />
                    <Route path={UrlS.admin.sections.substring(1)} element={<Sections />} />
                </Route>

                <Route path={UrlS.setting.main} >
                    <Route path={UrlS.setting.categories.substring(1)} element={<Category />} />
                    <Route path={UrlS.setting.about.substring(1)} element={<AboutPage />} />
                    <Route path={UrlS.setting.terms.substring(1)} element={<TermPage />} />
                    <Route path={UrlS.setting.site_infos.substring(1)} element={<SiteInfoPage />} />
                    <Route path={UrlS.setting.sliders.substring(1)} element={<SliderPage />} />
                    <Route path={UrlS.setting.banners.substring(1)} element={<BannerPage />} />
                    <Route path={UrlS.setting.blogs.substring(1)} element={<BlogPage />} />
                    <Route path={UrlS.setting.faq.substring(1)} element={<FaqPage />} />
                </Route>


            </Route>

        </Routes>

    );
}

const Loading = () => <>
    <div className="w-full h-screen flex justify-center items-center"><Loader /></div>
</>

