import { ItemProps, MenuItemProps } from "../interface/Menu";

import AdminIcon from "../components/shared/Icon/Admin";
import BoxIcon from "../components/shared/Icon/Box";
import DashboardIcon from "../components/shared/Icon/Dashboard";
import OrderIcon from "../components/shared/Icon/Order";
import ProductIcon from "../components/shared/Icon/Product";
import SettingIcon from "../components/shared/Icon/Setting";
import SupportIcon from "../components/shared/Icon/Support";
import TransactionsBulkIcon from "../components/shared/Icon/TransactionsBulk";
import DiscountIcon from '@mui/icons-material/Discount';
import { UrlS } from "./Urls";
import UserIcon from "../components/shared/Icon/User";

const IconSize = '25';
const IconColor = 'var(--murkyLight)';



const AdminsChildren: ItemProps[] = [
    {
        name: "admin",
        url: UrlS.admin.main + UrlS.admin.admin,
        keyPermission: "read-admin",
        keyGroupPermission: "admin",

    },
    {
        name: "roles",
        url: UrlS.admin.main + UrlS.admin.role,
        keyPermission: "read-role",
        keyGroupPermission: "role",
    },
    {
        name: "pages",
        url: UrlS.admin.main + UrlS.admin.pages,
        keyPermission: "public",
        keyGroupPermission: "page",
    },
    // {
    //     name: "sections",
    //     url: UrlS.admin.main + UrlS.admin.sections,
    //     keyPermission: "public",
    //     keyGroupPermission: "section",
    // },
];

const SettingChildren: ItemProps[] = [
    {
        name: "about",
        url: UrlS.setting.main + UrlS.setting.about,
        keyPermission: "public",
        keyGroupPermission: "about",

    },
    {
        name: "terms",
        url: UrlS.setting.main + UrlS.setting.terms,
        keyPermission: "public",
        keyGroupPermission: "term",
    },
    {
        name: "categories",
        url: UrlS.setting.main + UrlS.setting.categories,
        keyPermission: "public",
        keyGroupPermission: "category",
    },
    {
        name: "slider",
        url: UrlS.setting.main + UrlS.setting.sliders,
        keyPermission: "public",
        keyGroupPermission: "slider",
    },

    {
        name: "banner",
        url: UrlS.setting.main + UrlS.setting.banners,
        keyPermission: "public",
        keyGroupPermission: "banner",
    },

    {
        name: "blog",
        url: UrlS.setting.main + UrlS.setting.blogs,
        keyPermission: "public",
        keyGroupPermission: "blog",
    },
    {
        name: "siteInfos",
        url: UrlS.setting.main + UrlS.setting.site_infos,
        keyPermission: "public",
        keyGroupPermission: "siteInfo",
    },

    {
        name: "faq",
        url: UrlS.setting.main + UrlS.setting.faq,
        keyPermission: "public",
        keyGroupPermission: "faq",

    },
];

export const Menus: MenuItemProps[] = [
    {
        name: "dashboard",
        url: UrlS.dashboard.main,
        icon: <DashboardIcon size={IconSize} fill={IconColor} />,
        haveChildren: false,
        keyPermission: "public",
        keyGroupPermission: "",
    },
    // {
    //     name: "profile",
    //     url: UrlS.profile.main,
    //     icon: <DashboardIcon size={IconSize} fill={IconColor} />,
    //     haveChildren: false,
    //     keyPermission: "public",
    //     keyGroupPermission: "",
    // },
    {
        name: "products",
        url: "/products",
        icon: <ProductIcon size={IconSize} fill={IconColor} />,
        haveChildren: false,
        keyPermission: "read-product",
        keyGroupPermission: "product",
    },
    {
        name: "discounts",
        url: "/discounts",
        icon: <DiscountIcon      />,
        haveChildren: false,
        // keyPermission: "read-discount",
        // keyGroupPermission: "discount",
        keyPermission: "read-discount",
        keyGroupPermission: "discount",
    },
    {
        name: "orders",
        url: "/orders",
        icon: <OrderIcon size={IconSize} fill={IconColor} />,
        haveChildren: false,
        keyPermission: "read-order",
        keyGroupPermission: "order",
    },
    {
        name: "suppliers",
        url: UrlS.suppliers.main + UrlS.suppliers.supplier,
        icon: <BoxIcon size={IconSize} fill={IconColor} />,
        haveChildren: false,
        keyPermission: "read-supplier",
        keyGroupPermission: "supplier",
    },
    {
        name: "transactions",
        url: UrlS.transactions.main,
        icon: <TransactionsBulkIcon size={IconSize} fill={IconColor} />,
        haveChildren: false,
        keyPermission: "read-transaction",
        keyGroupPermission: "transaction",
    },
    {
        name: "support",
        url: "/support",
        icon: <SupportIcon size={IconSize} fill={IconColor} />,
        haveChildren: false,
        keyPermission: "read-ticket",
        keyGroupPermission: "ticket",
    },
    {
        name: "users",
        url: UrlS.user.main,
        icon: <UserIcon size={IconSize} fill={IconColor} />,
        haveChildren: false,
        keyPermission: "read-user",
        keyGroupPermission: "user",
    },
    {
        name: "admin",
        url: UrlS.admin.main,
        icon: <AdminIcon size={IconSize} fill={IconColor} />,
        haveChildren: true,
        children: AdminsChildren,
        keyPermission: "read-admin",
        keyGroupPermission: "",
    },
    {
        name: "setting",
        url: "/setting",
        icon: <SettingIcon size={IconSize} fill={IconColor} />,
        haveChildren: true,
        children: SettingChildren,
        keyPermission: "public",
        keyGroupPermission: "",
    },

];

export const getMenu = (name: string) => Menus.find((item) => item.name === name)