import { useTranslation } from 'next-i18next';
import React from 'react'
import { useUserDetailQuery } from '../../../../data/services/User';
import { ID } from '../../../../interface/EntityCommon';
import ImageComponent from '../../../shared/Html/Image';
import TitleValue from '../../../shared/Html/TitleValue';

export default function WalletUser({ id }: ID) {
    const { t } = useTranslation();
    const { data, isLoading } = useUserDetailQuery({ id: id })
    return (
        <>


            <div className='flexCol gap-4 text-lg '>

                <div className="flexRow gap-1 md:w-[50%] md:mx-auto">
                    <TitleValue title={t("Txt.walletMony")} value={data?.data?.wallet.balance} />
                </div>
            </div>
        </>



    )
}
