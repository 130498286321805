import { ProductDetailData, ShowFile } from '../../../../interface/EntityProduct'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from "yup"

import ButtonBrand from '../../../shared/button/ButtonBrand'
import FileInput from '../../../shared/form/upload/FileInput'
import FileInputMulti from '../../../shared/form/upload/FileInputMulti'
import Gallery from './Gallery'
import GrayLine from '../../../shared/Html/GrayLine'
import SelectInput from '../../../shared/form/select/SelectInput'
import SelectTypeShow from '../SelectTypeShow'
import { useProductEditGalleryMutation, useProductEditGalleryVideoMutation } from '../../../../data/services/Product'
import { useState ,useEffect} from 'react'
import { useTranslation } from 'next-i18next'
import InputTxt from '../../../shared/form/input/InputTxt'
import { ConstructionOutlined } from '@mui/icons-material'
import { refreshPage } from '../../../../utility/Function'
import { ToastSuccess } from '../../../../utility/ToastFunction'
import { yupResolver } from '@hookform/resolvers/yup'

function GalleryData({ ...data }: ProductDetailData) {
    const { t } = useTranslation();

    const Schema = Yup.object().shape({
        alt: Yup.string().required(t("Validation.require")),
        show: Yup.string().required(t("Validation.require")),
    });

    const [emptyFile, setEmptyFile] = useState(false)
    const [AddToGallery, results] = useProductEditGalleryMutation();
    const [AddToGalleryVideo, resultVideo] = useProductEditGalleryVideoMutation();
    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        defaultValues: { showType: "both" },
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<{ images: File[], show: ShowFile,alt:string }> = (param) => {
       
        if (param?.show !== undefined) {
            AddToGallery({ ...param, ...{ id: data.id } })
            setEmptyFile(true)
            // refreshPage()
        }
    }

    const OnFinishVideo: SubmitHandler<{ images: File[], show: ShowFile ,alt:string }> = (param) => {
        if (param?.show !== undefined) {
            AddToGalleryVideo({ ...param, ...{ id: data.id } })
            setEmptyFile(true)
            // refreshPage()
        }
    }

    useEffect(()=>{
        if(results.isSuccess == true){
            ToastSuccess(t("Common.successAlarm"))
            reset()
        }
        console.log("result =>",results)
    },[results])

    return (
        <>
            <div className='FormModal'>

                <div className='flexCol items-center p-4 my-4 border rounded-[--radius]'>

                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))} className='flexCol gap-4 w-full p-4'   >

                        <div className='w-full '>
                            <FileInputMulti
                                isMulti
                                control={control}
                                errors={errors}
                                emptyFile={emptyFile}
                                setValue={setValue}
                                getValues={() => { }}
                                changeValue={(file: File) => console.log("thumb", file)}
                                name="images"
                                accept='image/png,image/jpeg,image/jpg,image/gif'
                                title={t("Txt.chooseImg")} />

                        </div>
                        <div className="w-full">
                            <InputTxt control={control} errors={errors} keyValue='alt' label={t("Common.alt")} name='alt' placeholder={t("Common.alt")} />
                        </div>
                        <div className='w-full '>
                            <SelectTypeShow errors={errors} control={control} />
                        </div>

                        <div className='my-4 w-full flex justify-center'>

                            <ButtonBrand type='submit' click={() => { }} loading={results.isLoading}>
                                <b>{t("Common.saveButton")}</b>
                            </ButtonBrand>

                        </div>
                    </form>

                </div>
                <div className='flexCol items-center p-4 my-4 border rounded-[--radius]'>

                    <form onSubmit={handleSubmit((values: any) => OnFinishVideo(values))} className='flexCol gap-4 w-full p-4'   >


                        <div className='w-full '>
                            <FileInputMulti
                                isMulti
                                control={control}
                                errors={errors}
                                emptyFile={emptyFile}
                                setValue={setValue}
                                getValues={() => { }}
                                changeValue={(file: File) => console.log("thumb", file)}
                                name="videos"
                                accept="video/mp4, video/mov"
                                title={t("Txt.chooseVideo")} />

                        </div>

                        <div className='w-full '>
                            <SelectTypeShow control={control} />
                        </div>

                        <div className='my-4 w-full flex justify-center'>

                            <ButtonBrand type='submit' click={() => { }} loading={resultVideo.isLoading}>
                                <b>{t("Common.saveButton")}</b>
                            </ButtonBrand>

                        </div>
                    </form>

                </div>
                {data !== undefined && <Gallery data={data.gallery} />}

            </div>

        </>
    )
}

export default GalleryData