import React from 'react'
import { FormComponentProps } from '../../../../interface/Element'
import InputTxt from '../../../shared/form/input/InputTxt'
import { useTranslation } from 'next-i18next'


import CheckBoxPermission from '../../../permission/form/checkBox'

export default function FormItem({ control, getValues, setValue, errors }: FormComponentProps) {
    const { t } = useTranslation()
    return (
        <>
            <div className='flexCol flex-wrap w-full'>
                <div className='w-[45%]'>
                    <InputTxt
                        control={control}
                        errors={errors}
                        keyValue='name'
                        label={t("Common.title")}
                        name='name'
                        placeholder={t("Common.title")} />
                </div>


                <div className='flexRow flex-wrap '>
                    <CheckBoxPermission
                        getValues={getValues}
                        setValue={setValue}
                        changeF={(value: string, checked: boolean) => console.log(value, checked)}
                        control={control} />
                </div>

            </div>

        </ >


    )
}
