import FileInput from '../../../shared/form/upload/FileInput'
import { FormComponentProps } from '../../../../interface/Element'
import { ImgUploadType } from '../../../../config/App'
import InputTextarea from '../../../shared/form/input/InputTextarea'
import InputTxt from '../../../shared/form/input/InputTxt'
import PasswordTxt from '../../../shared/form/input/PasswordTxt'
import React from 'react'
import RoleSelect from '../../../role/select'
import { useTranslation } from 'next-i18next'

export default function FormItem({ control, getValues, setValue, errors,emptyFile=true }: FormComponentProps) {
    const { t } = useTranslation()
    return (
        <>
            <div className='flexCol flex-wrap w-full'>

              

                <InputTextarea
                    control={control}
                    errors={errors}
                    keyValue='text'
                    label={t("Common.text")}
                    name='text'
                    placeholder={t("Common.text")} />

                <FileInput
                    emptyFile={emptyFile}
                    changeValue={(file: File) => console.log("thumb", file)}
                    getValues={getValues}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    name="file"
                    accept={ImgUploadType}
                    title={t("Txt.chooseImg")} />





            </div>

        </ >


    )
}
