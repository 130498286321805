"use client"
import React, { useRef } from "react";
import { useState, useEffect } from 'react'
import { MapContainer, TileLayer, LayersControl, Marker } from "react-leaflet";
import './style.css'
import 'leaflet/dist/leaflet.css';
import LocationMarkers from "./LocationMarkers";
import { MapProps } from "./interface";
import { ApiKey, ApiRevers, ApiSearch, LatIran, LngIran } from "./conf"
import LocationMarkersElement from "./LocationMarkersElement";
import EmptyMarker from "./EmptyMarker"
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

const Maps = (props: MapProps) => {
    const { ChangeData, data, canChangeLocation = true, dragging = true, scrollWheelZoom = true, editMode = false, findCurrentLocation = false } = props
    const [center, setCenter] = useState<any>();
    const [trigger, setTrigger] = useState<boolean>(false)


    // async function GetAddressDetail(param: { lat: string, lng: string }) {
    async function GetAddressDetail(param: { lat: string, lng: string }) {
        const response = await fetch(`${ApiRevers}?lat=${param.lat}&lng=${param?.lng}`, {
            headers: {
                "Api-Key": ApiKey
            }
        });
        const dataM = await response.json();
        await ChangeData({ ...dataM, ...param });

        // fetch(`${ApiRevers}?format=jsonv2&lat=${param.lat}&lon=${param.lng}&accept-language=fa`)
        //     .then(response => response.json())
        //     .then(data => {
        //         ChangeData(data);
        //     });
    }
    async function findLocation(term:string) {
        const response = await fetch(`${ApiSearch}?term=${term}`, {
            headers: {
                "Api-Key": ApiKey
            }
        });
        const dataM = await response.json();
        console.log(dataM)
    }


    useEffect(() => {
        if (navigator?.geolocation) {
            navigator.geolocation.getCurrentPosition((location) => {
                if (location) {
                    let lat = location.coords.latitude
                    let lng = location.coords.longitude
                    setCenter([lat, lng]);
                }
            }, () => {
                console.log('run getCurrentPosition error');
                setCenter({ lat: LatIran, lng: LngIran })
            });
        }

        // }
    }, []);


    const styleOver = {
        zIndex: 1000,
        width: "100%",
        height: "100%",
        // background: "#93fb1f",
        // opacity: ".4",
    }


    return <>
        {findCurrentLocation && <button type="button" onClick={() => setTrigger(!trigger)} style={{ zIndex: 500 }} className="absolute btn-dtm w-[50px] pointer left-0 bottom-0"><GpsFixedIcon /></button>}
        {/* <button type="button" onClick={() => findLocation("میدان آزادی")} style={{ zIndex: 500 }} className="absolute btn-dtm w-[50px] pointer left-20 bottom-0">find</button> */}
        {center &&
            <MapContainer
                center={canChangeLocation ? center : [data?.lat, data?.lng]}
                zoom={13}
                className='leaflet'
                scrollWheelZoom={scrollWheelZoom}
                dragging={dragging}>
                <TileLayer

                    attribution=''
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* <LocationMarker /> */}
                {canChangeLocation ? <LocationMarkers trigger={trigger} editMode={editMode} setCenter={setCenter} GetAddressDetail={GetAddressDetail} data={data}
                    canChangeLocation={canChangeLocation} /> : <EmptyMarker position={[data?.lat, data?.lng]} />}
            </MapContainer>

        }
    </>
}

export default Maps;

