import React from 'react'
import SwitchComponent from '../../../shared/form/switch'
import { Loader } from '../../../shared/Loader'
import { useUserEditMutation } from '../../../../data/services/User'

export default function ({ block,id }: { block: 1 | 0,id:number }) {
    const [edit, results] = useUserEditMutation()

    return (
        <>
            {results.isLoading ? <Loader /> : <SwitchComponent checked={block === 1} handleChange={(data: any) => { edit({ block: data ? 1 : 0, id }) }} />}

        </>
    )
}

