import React from 'react'
import Header from '../../components/transaction/header'
import ListData from '../../components/transaction/list'

export default function TransactionPage() {
  return (
    
      <>
        <div className="flexCol gap-4 pageLayout">

          <Header />
        
          <ListData />

       </div>
      </>
      )
}
