export const UrlS = {
  dashboard: {
    main: '/dashboard'
  },
  profile: {
    main: '/profile'
  },

  product: {
    main: '/products'
  },

  orders: {
    main: '/orders'
  },
  review: {
    main: '/review/:id'
  },

  suppliers: {
    main: '/supplier',
    supplier: '/suppliers',
    factor: '/factors'
  },

  transactions: {
    main: '/transactions'
  },
  support: {
    main: '/support'
  },
  user: {
    main: '/users'
  },
  discounts: {
    main: '/discounts'
  },
  blog:{
    add:"/blogs/new",
    edit:"/blogs/edit/:id",
  },

  admin: {
    main: '/admin',
    admin: '/admins',
    role: '/roles',
    pages: '/pages',
    sections: '/sections'
  },

  setting: {
    main: '/setting',
    about: '/about',
    categories: '/categories',
    terms: '/terms',
    site_infos: '/site_infos',
    sliders: '/sliders',
    banners: '/banners',
    blogs: '/blogs',
    faq: '/faq',
  },
  login: {
    main: '/'
  }
}
