import { ADMIN_PATH, ADMIN_STORE_PATH, LOGOUT_PATH } from "../../config/ApiConf";
import { ResultApi } from "../../interface/Api";
import { AdminAddPropsApi, AdminEditPropsApi, AdminListApi, AdminListFilterApi } from "../../interface/EntityAdmin";
import { ID } from "../../interface/EntityCommon";
import { ChangeToFormData, changeToQueryParams, ChangeToQueryString } from "../../utility/Function";

import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const AdminApi = createApi({
    reducerPath: 'adminApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Admin"],

    endpoints: (builder) => ({
        Admin: builder.query<AdminListApi, AdminListFilterApi>({
            query: (body) => ({
                url: ADMIN_PATH + changeToQueryParams(body),
                // url: ADMIN_PATH + ChangeToQueryString(body),
                method: 'GET',

            })
            ,
            providesTags: ['Admin'],
        }),


        AdminAdd: builder.mutation<void, AdminAddPropsApi>({
            query: (body) => ({
                url: ADMIN_STORE_PATH,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Admin'],
        }),

        AdminEdit: builder.mutation<void, AdminEditPropsApi>({
            query: (body) => ({
                url: ADMIN_PATH + "/" + body.id,
                method: 'POST',

                body:{...{"_method":"PUT"},...body},
            }),
            invalidatesTags: ['Admin'],
        }),


        AdminRemove: builder.mutation<void, ID>({
            query: (body) => ({
                url: ADMIN_PATH + "/" + body.id,
                method: 'delete',

    
            }),
            invalidatesTags: ['Admin'],
        }),
        logout: builder.mutation<ResultApi, void>({
            query: () => ({
              url: LOGOUT_PATH,
              method: 'POST',
              Accept: "application/json"
            })
            ,
          }),
      

    }),



})

export const { useAdminQuery, useAdminAddMutation, useAdminEditMutation,useAdminRemoveMutation ,useLogoutMutation} = AdminApi


