import React from 'react'
import { SvgProps } from '../../../interface/Element'

function ArrowDown({ fill = "#A1A0A3", size = "20px", stroke = "#000000" }: SvgProps) {
    return (
        <>
            <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.97979 5.3131C3.1573 5.13559 3.43508 5.11945 3.63082 5.26469L3.6869 5.3131L8.00001 9.62599L12.3131 5.3131C12.4906 5.13559 12.7684 5.11945 12.9642 5.26469L13.0202 5.3131C13.1977 5.49061 13.2139 5.76839 13.0686 5.96413L13.0202 6.02021L8.35356 10.6869C8.17605 10.8644 7.89828 10.8805 7.70254 10.7353L7.64646 10.6869L2.97979 6.02021C2.78453 5.82495 2.78453 5.50837 2.97979 5.3131Z" fill={fill} />
            </svg>
        </>
    )
}

export default ArrowDown