import Header from "../../components/support/header";
import ListData from "../../components/support/list";

export default function SupportPage() {
  return (
    <div className="flexCol gap-4 pageLayout">
      <Header />
      <ListData />
    </div>
  );
}
