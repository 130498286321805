import * as Yup from "yup"

import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'



import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from "next-i18next"
import ModalFull from "../../../../shared/modal/ModalFull"
import FormItem from "../FormItem"
import ButtonBrand from "../../../../shared/button/ButtonBrand"
import { useRoleAddMutation } from "../../../../../data/services/Role"
import { ToastError, ToastSuccess } from "../../../../../utility/ToastFunction"
import PageMarginBottom from "../../../../shared/Html/PageMarginBottom"
import { useRolePermissionAddMutation, useRolePermissionQuery } from "../../../../../data/services/RolePermission"

export default function AddRole({ openModal, ChangeOpen }: { openModal: boolean, ChangeOpen: Function }) {

    const { t } = useTranslation();
    const [OpenAdd, setOpenAdd] = useState(false);
    const [permissions, setPermissions] = useState<string[]>([])
    const [roleAdd, results] = useRoleAddMutation()
    const [RolePermissionAdd, resultPermission] = useRolePermissionAddMutation()
    const [loading, setLoading] = useState(results.isLoading)
    const Schema = Yup.object().shape({
        name: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<any> = (data) => {

        if (data?.name !== undefined) {
            setLoading(true)
            let keys = Object.keys(data);
            let permissions = keys.filter((item) => item !== 'name' && data[item])
            setPermissions(permissions)
            roleAdd({ name: data.name });
        }
    }

    useEffect(() => {
        setOpenAdd(openModal);
        reset();
    }, [openModal, reset])

    useEffect(() => {
        if (results.isSuccess) {
            let roleId = results?.data?.data?.id
            RolePermissionAdd({
                role_id: roleId,
                permissions
            })


        }
        if (results.isError) {
            setLoading(false);
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    useEffect(() => {
        if (resultPermission.isSuccess) {
            setLoading(false);
            ToastSuccess(t("Common.successAlarm"))
            setOpenAdd(false);
        }
        if (resultPermission.isError) {
            setLoading(false);
            ToastError(t("Common.errorAlarm"))
        }
    }, [resultPermission])


    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => { setOpenAdd(open); ChangeOpen(open); }} Open={OpenAdd} title={t("Txt.addRole")}>
                <div className="FormModal !h-auto">
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />

                        <div className='w-full flex justify-center mt-10'>
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={loading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>

                    <PageMarginBottom />


                </div>

            </ModalFull>
        </>
    )
}

