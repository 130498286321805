import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, BlogDataType } from "../../../interface/EntityBlog";
import { PermissionData } from "../../../interface/EntityPermission";

const initialState: {
    mode: ActionMode,
    data: BlogDataType | null,
    deleteItem:BlogDataType|null,
    permissions:PermissionData[]
    
} = {
    mode: "none",
    data: null,
    deleteItem:null,
    permissions:[]

}


export const BlogForm = createSlice({
    name: 'BlogForm',
    initialState,
    reducers: {
        setBlogFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },

        emptyBlogFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'none'
            return newState;
        },

        setBlogFormData: (state, action: { payload: BlogDataType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },

        setBlogPermissionsFormData: (state, action: { payload: PermissionData[] }) => {
            let newState = state;
            newState['permissions'] = action.payload
            return newState;
        },

        emptyBlogFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },
        emptyBlogPermissionsFormData:(state)=>{
            let newState=state
            newState['permissions']=[];
            return newState;
        },


        setBlogDeleteItem: (state, action: { payload: BlogDataType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptyBlogDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setBlogFormMode,
    emptyBlogFormMode,
    setBlogFormData,
    emptyBlogFormData,
    setBlogDeleteItem,
    emptyBlogDeleteItem
    
} = BlogForm.actions

export default BlogForm.reducer