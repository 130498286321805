import { TERM_ADMIN_PATH, TERM_PATH } from "../../config/ApiConf";
import {  TermDataProps, TextUpdateApi } from "../../interface/EntitySetting";
import { ChangeToFormData } from "../../utility/Function";

import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const TermApi = createApi({
    reducerPath: 'termApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Term"],

    endpoints: (builder) => ({
        TermData: builder.query<TermDataProps, void>({
            query: () => ({
                url: TERM_PATH,
                method: 'GET',
                
            })
            ,
            providesTags: ['Term'],
        }),


        TermUpdate: builder.mutation<void, TextUpdateApi>({
            query: (body) => ({
                url: TERM_ADMIN_PATH,
                method: 'POST',
                
                body:ChangeToFormData(body)
            }),
            invalidatesTags: ['Term'],
        }),

       

    }),



})

export const { useTermDataQuery, useTermUpdateMutation } = TermApi


