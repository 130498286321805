import * as Yup from 'yup'

import { KeyValue, ProductData } from '../../../../../../interface/EntityProduct';
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';

import ButtonBrand from '../../../../../shared/button/ButtonBrand';
import FormItem from '../FormItem'
import { useProductAddKeyMutation } from '../../../../../../data/services/Product';
import { useTranslation } from 'next-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

function AddKeys({ ...data }: ProductData) {
    const { t } = useTranslation()
    const [ProductAddKey, results] = useProductAddKeyMutation()

    const Schema = Yup.object().shape({
        key: Yup.string().required(t("Validation.require")),
        value: Yup.string().required(t("Validation.require")),
        show: Yup.string().required(t("Validation.require")),
    });
    const { handleSubmit, control, formState: { errors }, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<KeyValue> = (param) => {
        if (param.show !== undefined) {
            ProductAddKey({ ...param, ...{ product_id: data.id } })
        }
    }


    useEffect(() => {
        if(results.isSuccess)
        reset();
    }, [results, reset])


    return (
        <>
            <form onSubmit={handleSubmit((values: any) => OnFinish(values))} className='flexCol gap-10'   >

                <div className='flexCol gap-4 p-4'>
                    <FormItem control={control} errors={errors} />
                </div>
                <div className='flex justify-center'>
                    <div className='w-1/2'>
                        <ButtonBrand type='submit' loading={results.isLoading} click={() => { }}>
                            {t("Common.saveButton")}
                        </ButtonBrand>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddKeys