import { useEffect, useState } from 'react'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import FormItem from '../FormItem';
import { ToastError, ToastSuccess } from '../../../../../utility/ToastFunction';
import ButtonBrand from '../../../../shared/button/ButtonBrand';
import ModalFull from '../../../../shared/modal/ModalFull';
import PageMarginBottom from '../../../../shared/Html/PageMarginBottom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store';
import { useFaqEditMutation } from '../../../../../data/services/Faq';
import { FaqUpdatePropsApi } from '../../../../../interface/EntityFaq';
import { setFaqFormMode } from '../../../../../data/slice/faq/FaqForm';

export default function EditFaq() {

    const { t } = useTranslation();
    const Data = useSelector((state: RootState) => state.FaqForm);
    const dispatch = useDispatch()
    const [Edit, results] = useFaqEditMutation();
    const [OpenAdd, setOpenAdd] = useState(false);

    const Schema = Yup.object().shape({
        
        
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema),

    });

    const OnFinish: SubmitHandler<FaqUpdatePropsApi> = (data) => {

        if (data?.title !== undefined) {
            let params:any=data
            
            Edit(params)
        }
    }

    useEffect(() => {
        if (Data.mode === 'edit') {
            setOpenAdd(true);
            reset({
                id:Data.data?.id,
                title:Data.data?.title,
                text:Data.data?.text,
                category_id:Data.data?.category_id,
            });
        } else {
            setOpenAdd(false);
        }
    }, [Data.mode, reset])



    useEffect(() => {
        if (results.isSuccess) {

            dispatch(setFaqFormMode("none"))
            ToastSuccess(t("Common.successAlarm"))
            window.location.reload();
        }

        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => {
                setOpenAdd(open);
                dispatch(setFaqFormMode(open ? "edit" : "none"));


            }} Open={OpenAdd} title={t("Txt.editFaq")}>
                <div className="FormModal !h-auto">
                  
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />

                        <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]' >
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>

                    <PageMarginBottom />


                </div>

            </ModalFull>

        </>
    )
}
