import * as Yup from "yup"

import { SubmitHandler, useForm } from 'react-hook-form';
import { ToastError, ToastSuccess } from '../../../../../utility/ToastFunction';
import { emptyAdminFormData, setAdminFormMode } from '../../../../../data/slice/admin/AdminForm';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
import { useSupportAddMutation, useSupportDetailQuery } from "../../../../../data/services/Support";

import ButtonBrand from '../../../../shared/button/ButtonBrand';
import FormItem from '../index';
import GrayLine from "../../../../shared/Html/GrayLine";
import ModalFull from '../../../../shared/modal/ModalFull';
import { RootState } from '../../../../../data/store';
import SupportText from "./SupportText";
import { useTranslation } from 'next-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

export default function ViewSupport({ openModal, ChangeOpen }: { openModal: boolean, ChangeOpen: Function }) {

    const { t } = useTranslation();
    const Data = useSelector((state: RootState) => state.SupportForm.data);
    const { data } = useSupportDetailQuery({ id: Data?.id ?? 0 })
    const [OpenAdd, setOpenAdd] = useState(false);
    const [adminReply, results] = useSupportAddMutation()

    const Schema = Yup.object().shape({
        text: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema),

    });

    const OnFinish: SubmitHandler<any> = (data) => {
        if (data?.text !== undefined) {
            let params=data;
            if(params.file===undefined)
                delete params['file'];

            adminReply({ ...params, ...{ id: Data?.id } })
        }
    }

    useEffect(() => {
        setOpenAdd(openModal);
        reset();
    }, [openModal, reset])

    useEffect(() => {
        if (results.isSuccess) {
         
            reset();

        }
    }, [openModal, reset, results])



    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => { setOpenAdd(open); ChangeOpen(open); }} Open={OpenAdd} title={t("Txt.replyToTicket")}>
                <div className="FormModal !h-auto">
                    <strong className="text-2xl" >{data?.data.title}</strong>

                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />
                        <div className='w-full flex justify-center mb-4' >
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>
                    <GrayLine />
                    <div className="flexCol max-h-80 overflow-y-auto gap-y-4 p-4">
                        {
                            data?.data.message.map((item) => <>
                                <div className={`w-full flex ${item.is_reply ? "justify-start" : "justify-end"}`}>
                                    <SupportText data={item} />
                                </div>

                            </>)
                        }
                    </div>


                </div>

            </ModalFull>

        </>
    )
}
