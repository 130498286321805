import { useEffect, useRef, useState } from 'react';

import { Controller } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import FileIcon from './img/file-alt.svg';
import { FileUpload } from './interface'
import UploadP from './img/upload.svg'

const FileInput = ({ accept, title, changeValue, name, control, errors, setValue, getValues, emptyFile = false, isMulti = false }: FileUpload) => {
    const Refs = useRef<any>(null);
    const [fileName, setFileName] = useState("");
    const [imgSrc, setImgSrc] = useState('');

    const SetImageValue = (file: File) => {
        let reader: any = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function (e: any) {
            setImgSrc(reader.result)
        };
    }

    const changeV = (file: any, fn: Function) => {
        if (file === undefined)
            return;

        for (let i = 0; i < file.length; i++) {

            if (file[i].type.includes("image")) {
                SetImageValue(file[i])

            } else {
                setImgSrc(FileIcon);
            }

            if (changeValue !== undefined) {
                changeValue(file[i])
            }

            if (setValue !== undefined) {
                console.log('run setValue')
                setValue(name, file[i])
            }
            fn(file[i])
        }



    }

    const RemoveFile = () => {
        if (Refs.current !== null) {
            Refs.current.value = ''
            setFileName("")
            setImgSrc('')
            //reset(name);
        }
    }

    useEffect(() => {
        if (emptyFile !== undefined && emptyFile) {
            RemoveFile();
        }
    }, [emptyFile])

    useEffect(() => {
        let value = getValues(name)
        if (value !== undefined && value !== "" && typeof value!=='string') {
            setFileName(value.name)
            SetImageValue(value)

        }else{
            RemoveFile();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues(name ?? "files")])

    return (
        <div className='flex flex-col gap-1'>
            <div className='bg-[--light]  rounded-xl p-3 w-full h-[180px]'>
                <div className="w-full h-full  rounded-xl border border-dashed border-[--brandLight] p-2 relative flex flex-col gap-2 text-[--brandLight] items-center">
                    {imgSrc === '' && <div className="w-full h-full  flex flex-col gap-2 items-center absolute">
                        <img className='w-12' src={UploadP} alt="" />
                        <b>{title}</b>
                        <small> {accept} </small>

                    </div>}
                    {imgSrc !== '' &&
                        <div className="w-full h-full p-4 absolute flex flex-col justify-center  text-[--brandLight] items-center">
                            <img src={imgSrc} className='w-[120px] h-[120px] rounded-lg object-cover  ' alt='' />
                            <div className='mb-3 z-30 text-xs' onClick={() => RemoveFile()}><DeleteIcon /></div>

                        </div>
                    }
                    <Controller
                        control={control}

                        render={({ field: { onChange, onBlur, value } }) => (

                            <div className='w-full h-[100px] z-10' >
                                <input
                                    ref={Refs}
                                    className='w-full h-full'
                                    type="file"
                                    accept={fileName !== "" ? fileName : accept}
                                    onChange={(event: any) => { changeV(event.target.files, onChange) }} />
                            </div>

                        )}

                        name={name} />


                </div>
            </div>
            <div>
                <p className='text-red-400 font-bold text-sm mx-2 my-4'>{errors[name]?.message}</p>

            </div>
        </div>
    )
}

export default FileInput