import { useState } from 'react'
import { Loader } from '../../shared/Loader'
import { useAdminQuery } from '../../../data/services/Admin'
import FormAdmin from '../form'
import FilterAdmin from '../form/actions/filter'
import ListDataTable from './../../shared/listData/index'

// i18n
import { useTranslation } from 'next-i18next'

// icons
import SettingIcon from '../../shared/Icon/Setting'

// redux
import {
  setAdminFormData,
  setAdminFormMode
} from '../../../data/slice/admin/AdminForm'
import { useDispatch } from 'react-redux'

function ListData () {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const [filter, setFilter] = useState<any>({
    page: 1,
    role_id: undefined,
    name: undefined
  })

  const { data, isLoading, refetch } = useAdminQuery(filter)

  const Headers = [
    { value: <b>#</b>, width: 'w-[150px]' },
    { value: <b>{t('Txt.name')}</b> },
    { value: <b>{t('Txt.username')}</b> },
    { value: <b>{t('Txt.roleName')}</b> },
    { value: <b>{t('Txt.actions')}</b>, width: 'w-[380px]' }
  ]

  const Rows = data?.data?.data.map(item => {
    return {
      row: [
        { value: <div className='ms-1'>{item.id}</div>, width: 'w-[150px]' },
        { value: <div>{item.name}</div> },
        { value: <div>{item.username}</div> },
        { value: <div>{item.role?.name}</div> },
        {
          value: (
            <div className='flexRow items-center'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  dispatch(setAdminFormData(item))
                  dispatch(setAdminFormMode('edit'))
                }}
              >
                <SettingIcon fill='var(--secondary)' size='30px' />
              </div>
            </div>
          ),
          width: 'w-[380px]'
        }
      ]
    }
  })
  
  return (
    <>
      <FilterAdmin
        page={page}
        setFilter={e => setFilter(e)}
        filter={filter}
        getData={() => refetch()}
      />
      <div className='  py-4'>
        {isLoading && (
          <div className='flexRow justify-center w-full'>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <ListDataTable
            header={Headers}
            setPage={setPage}
            rows={Rows ?? []}
            currentPage={+(data?.data.current_page ?? 1) - 1}
            rowsPerPage={+(data?.data.per_page ?? 0)}
            total={+(data?.data.total ?? 0)}
            handleChangePage={(params1: any, params2: number) =>
              setPage(params2 + 1)
            }
            onRowsPerPageChange={(params1: any, params2: any) =>
              console.log(params1, params2)
            }
          />
        )}
      </div>
      <FormAdmin />
    </>
  )
}
export default ListData
