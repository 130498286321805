import React from 'react'
import { ProductDetailData } from '../../../../interface/EntityProduct'
import TitleValue from '../../../shared/Html/TitleValue'
import { useTranslation } from 'next-i18next'
import GrayLine from '../../../shared/Html/GrayLine'

export default function Detail({ ...data }: ProductDetailData) {
    const { t } = useTranslation()
    return (
        <>
            <div className='flexCol gap-4 text-lg '>
                <div className="flexRow gap-1 md:w-[50%] md:mx-auto">
                    <TitleValue title={t("Txt.name")} value={data.name} />
                </div>
                <GrayLine />

                <div className="flexRow gap-1 md:w-[50%] md:mx-auto">
                    <TitleValue title={t("Txt.code")} value={data.code} />
                </div>
                <GrayLine />

                <div className="flexRow gap-1 md:w-[50%] md:mx-auto">
                    <TitleValue title={t("Txt.packageType")} value={data.package_type.toString()} />
                </div>
                <GrayLine />

                <div className="flexRow gap-1 md:w-[50%] md:mx-auto">
                    <TitleValue title={t("Txt.packageNumber")} value={data.pack_number} />
                </div>
                <GrayLine />

                <div className="flexRow gap-1 md:w-[50%] md:mx-auto">
                    <TitleValue title={t("Txt.price")} value={data.price.toLocaleString()} />
                </div>
                <GrayLine />

                <div className="flexRow gap-1 md:w-[50%] md:mx-auto">
                    <TitleValue title={t("Txt.priceSinglePercent")} value={data.price_single_percent} />
                </div>
                <GrayLine />

                <div className="flexRow gap-1 md:w-[50%] md:mx-auto">
                    <TitleValue title={t("Txt.description")} value={data.description} />
                </div>
                <GrayLine />
 
 
            </div>

        </>
    )
}
