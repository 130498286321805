import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import ModalFull from "../../../shared/modal/ModalFull";
import PageMarginBottom from "../../../shared/Html/PageMarginBottom";
import ButtonBrand from "../../../shared/button/ButtonBrand";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { setUserFormMode } from "../../../../data/slice/user/UserForm";
import FormItemUser from "../FormItemUser";
import { SubmitHandler, useForm } from "react-hook-form";
import { useUserEditDataMutation } from "../../../../data/services/User";
import { ToastError, ToastSuccess } from "../../../../utility/ToastFunction";
import { UserUpdatePropsApi } from "../../../../interface/EntityUser";
import { RootState } from "../../../../data/store";

export const EditUser = () => {

    const Schema = Yup.object().shape({
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset, watch } = useForm<any>({
        resolver: yupResolver(Schema),

    });
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [OpenAdd, setOpenAdd] = useState(false);
    const [Edit, results] = useUserEditDataMutation();
    const { mode, data } = useSelector((state: RootState) => state.UserForm);


    const OnFinish: SubmitHandler<UserUpdatePropsApi> = (data) => {

        if (data?.first_name !== undefined) {
            let params: any = data
            if (data.display_name == "" || data.display_name == undefined || data.display_name == null) {
                delete (params['display_name'])
                delete (params['display_name']);
            }
            Edit(params)
        }
    }

    useEffect(() => {
        if (mode === 'edit' && data !== undefined) {
            setOpenAdd(true);
            reset({
                id: data?.id,
                first_name: data?.first_name,
                last_name: data?.last_name,
                display_name: data?.display_name,
                mobile: data?.mobile,
                type: data?.type,
                confirm: data?.confirm,
                block: data?.block,
                created_at: data?.created_at,
                updated_at: data?.updated_at
            });
        } else {
            setOpenAdd(false);
        }
    }, [mode, data, reset])

    useEffect(() => {
        if (results.isSuccess) {

            dispatch(setUserFormMode("none"))
            ToastSuccess(t("Common.successAlarm"))
        }

        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])
    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => {
                setOpenAdd(open);
                dispatch(setUserFormMode(open ? "edit" : "none"));


            }} Open={OpenAdd} title={t("Common.editDiscount")}>
                <div className="FormModal !h-auto">

                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItemUser control={control} errors={errors} getValues={getValues} setValue={setValue} watch={watch} />

                        <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]' >
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>

                    <PageMarginBottom />


                </div>

            </ModalFull>

        </>
    )
}
export default EditUser