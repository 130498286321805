import { useEffect, useState } from 'react'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import FormItem from '../FormItem';
import { ToastError, ToastSuccess } from '../../../../../utility/ToastFunction';
import ButtonBrand from '../../../../shared/button/ButtonBrand';
import ModalFull from '../../../../shared/modal/ModalFull';
import PageMarginBottom from '../../../../shared/Html/PageMarginBottom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store';
import { baseUrl } from '../../../../../config/ApiConf';
import { useBannerEditMutation } from '../../../../../data/services/banner';
import { BannerUpdatePropsApi } from '../../../../../interface/EntityBanner';
import { setBlogFormMode } from '../../../../../data/slice/blog/BlogForm';
import { useBlogDetailQuery, useBlogEditMutation } from '../../../../../data/services/Blog';
import { BlogUpdatePropsApi } from '../../../../../interface/EntityBlog';

export default function EditBanner() {

    const { t } = useTranslation();
    const Data = useSelector((state: RootState) => state.BlogForm);
    const dispatch = useDispatch()
    const [Edit, results] = useBlogEditMutation();
    const [OpenAdd, setOpenAdd] = useState(false);
    const { data } = useBlogDetailQuery({ id: Data.data?.id ?? 0 }, { skip: Data.data === null })

    const Schema = Yup.object().shape({
        description: Yup.string().required(t("Validation.require")),
        name: Yup.string().required(t("Validation.require")),

    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema),

    });

    const OnFinish: SubmitHandler<BlogUpdatePropsApi> = (data) => {

        if (data?.name !== undefined) {
            let params: any = data
            if (params.image === undefined) {
                delete (params['image']);
            }


            Edit(params)
        }
    }

    useEffect(() => {
        if (Data.mode === 'edit' && data?.data !== undefined) {
            debugger
            setOpenAdd(true);
            reset({
                id: data.data?.id,
                name: data.data?.name,
                summery: data.data?.summery,
                description: data.data?.description,
                category_id: data.data?.category_id,
                title_seo: data.data?.title_seo,
                description_seo: data.data?.description_seo,
                alt: data.data?.alt

            });
        } else {
            setOpenAdd(false);
        }
    }, [Data.mode, data, reset])



    useEffect(() => {
        if (results.isSuccess) {
            setTimeout(() => {
                ToastSuccess(t("Common.successAlarm"))
                dispatch(setBlogFormMode("none"))
            }, 2000)
            // window.location.reload()
        }

        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => {
                setOpenAdd(open);
                dispatch(setBlogFormMode(open ? "add" : "none"));


            }} Open={OpenAdd} title={t("Txt.editBlog")}>
                <div className="FormModal !h-auto">
                    <div className='w-[50%] h-auto my-2 mx-auto'>
                        <img src={baseUrl + Data.data?.pathFile} className='w-full h-full object-fill  rounded-[--radius]' />

                    </div>
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />

                        <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]' >
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>

                    <PageMarginBottom />


                </div>

            </ModalFull>

        </>
    )
}
