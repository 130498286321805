import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, AdminDataType, AdminType } from "../../../interface/EntityAdmin";

const initialState: {
    mode: ActionMode,
    data: AdminDataType | null,
    deleteItem: AdminDataType | null,
} = {
    mode: "none",
    data: null,
    deleteItem: null,
}


export const AdminForm = createSlice({
    name: 'AdminForm',
    initialState,
    reducers: {
        setAdminFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },

        emptyAdminFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'add'
            return newState;
        },

        setAdminFormData: (state, action: { payload: AdminDataType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },


        emptyAdminFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },



        setAdminDeleteItem: (state, action: { payload: AdminDataType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptyAdminDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setAdminFormMode,
    emptyAdminFormMode,
    setAdminFormData,
    emptyAdminFormData,
    setAdminDeleteItem,
    emptyAdminDeleteItem,
} = AdminForm.actions

export default AdminForm.reducer