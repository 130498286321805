import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'next-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import PasswordTxt from '../../../shared/form/input/PasswordTxt'
import ButtonBrand from '../../../shared/button/ButtonBrand'

const EditPassword = () => {
  const { t } = useTranslation()
  const Schema = Yup.object().shape({
    password: Yup.string().required(),
    rePassword: Yup.string().required()
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch
  } = useForm<any>({
    resolver: yupResolver(Schema)
  })
  const OnFinish: SubmitHandler<{
    password: string
    rePassword: string
  }> = data => {
    if (data?.password !== undefined) {
      //   editProfile(data)
    }
  }

  return (
    <div className='w-full'>
      <form
        className='w-full'
        onSubmit={handleSubmit((values: any) => OnFinish(values))}
      >
        <div className='w-full flex justify-between items-center gap-10'>
          <PasswordTxt
            classContainer='w-full'
            control={control}
            errors={errors}
            keyValue='password'
            label={t('Common.password')}
            name='password'
            placeholder={t('Common.password')}
          />
          <PasswordTxt
            classContainer='w-full'
            control={control}
            errors={errors}
            keyValue='rePassword'
            label={t('Common.rePassword')}
            name='rePassword'
            placeholder={t('Common.rePassword')}
          />
        </div>
        <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]'>
          <div className='w-1/3'>
            <ButtonBrand
              type='submit'
              //   loading={results.isLoading}
              key={'buttonForm'}
              click={OnFinish}
            >
              <b>{t('Common.saveButton')}</b>
            </ButtonBrand>
          </div>
        </div>
      </form>
    </div>
  )
}
export default EditPassword
