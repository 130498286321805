export default function TabBtn({ icon, txt, active = false }: { icon?: React.ReactNode, txt: string, active?: boolean }) {
    return (
        <>
            <div className={`p-2 cursor-pointer flexRow gap-2 justify-center items-center border border-[--brand] rounded-[--radius] ${active ? "bg-[--brand]" : ""}`}>
                {icon}
                <b>{txt}</b>
            </div>
        </>
    )
}
