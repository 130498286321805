import { useEffect, useState } from 'react'

import ButtonBrand from '../../shared/button/ButtonBrand'
import PlusIcon from '../../shared/Icon/Plus'
import { useTranslation } from 'next-i18next'
import AddAdmin from '../form/actions/add'
import { useDispatch, useSelector } from 'react-redux'
import { setAdminFormMode } from '../../../data/slice/admin/AdminForm'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { RootState } from '../../../data/store'

function Header () {
  const dispatch = useDispatch()
  const [OpenAdd, setOpenAdd] = useState(false)
  const { t } = useTranslation()
  const mode = useSelector((state: RootState) => state.AdminForm.mode)

  useEffect(() => {
    console.log('mode is : ', mode)
  }, [mode])

  return (
    <section className='w-100 flex flex-col gap-10'>
      <div className='w-100 flex justify-between items-center'>
        <h1 className='text-2xl'>{t('Menu.admins')}</h1>
        <div className='flex gap-6'>
          <ButtonBrand
            className='text-nowrap'
            type='button'
            click={() => {
              setOpenAdd(true)
            }}
          >
            <div className='flexRow items-center gap-2'>
              <span>
                <PlusIcon fill='var(--light)' />
              </span>
              <b> {t('Txt.addAdmin')} </b>
            </div>
          </ButtonBrand>
          <ButtonBrand
            type='button'
            className='w-30'
            click={() => {
              dispatch(setAdminFormMode('filter'))
            }}
          >
            <div className='flexRow items-center gap-2'>
              <span>
                <FilterAltIcon fill='var(--light)' />
              </span>
              <b> {t('Txt.filter')} </b>
            </div>
          </ButtonBrand>
        </div>
      </div>
      {/* <AddAdmin openModal={OpenAdd} ChangeOpen={setOpenAdd} /> */}
      <AddAdmin
        openModal={OpenAdd}
        ChangeOpen={setOpenAdd}
      />
    </section>
  )
}

export default Header
