import React, { useEffect, useState } from 'react'
import SelectInput from '../../../shared/form/select/SelectInput'
import { useTranslation } from 'next-i18next';
import SelectSearch from '../../../shared/form/select/SelectSearch';
import { FormProps, SelectOption } from '../../../../interface/Element';
import { usePackageTypeQuery } from '../../../../data/services/PackageType';

function PackageType({ control, errors }: FormProps) {
    const { t } = useTranslation();

    const {data,isLoading}=usePackageTypeQuery()
    const [options,setOptions]=useState<SelectOption[]>([]);

    useEffect(()=>{
        if(data?.data){
            const newOptions=data.data.map((item)=>{
                return {label:item.name,value:item.id}
            })
            setOptions(newOptions);
        }
    },[data])

    
    return (
        <>
            <SelectSearch
                isLoading={isLoading}
                optionItem={options}
                control={control}
                errors={errors}
                keyValue='package_type'
                label={t("Txt.packageType")}
                name='package_type' />

        </>
    )
}

export default PackageType