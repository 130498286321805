import { useState,useEffect } from 'react'
import FormSupport from '../form'
import { Loader } from '../../shared/Loader'
import { useSupportQuery } from '../../../data/services/Support'
import ListDataTable from './../../shared/listData/index'

// redux
import {
  setSupportFormData,
  setSupportFormMode
} from '../../../data/slice/support/SupportForm'
import { useDispatch } from 'react-redux'

// icon
import EyeIcon from '../../shared/Icon/Eye'

// i18n
import { useTranslation } from 'next-i18next'

function ListData () {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')
  const dispatch = useDispatch()

  const { data, isLoading ,refetch} = useSupportQuery({ page, name })

  const [filter, setFilter] = useState<any>({
    page,
    type: undefined,
    name: undefined,
    active: undefined,
  })
  const handleChangePageNumber = async (pageNumber: number) => {
    await setPage(pageNumber)
    setFilter({...filter,page:pageNumber})
  }

  useEffect(() => {
    refetch()
  }, [page])

  const Headers = [
    { value: <b>#</b>, width: 'w-[150px]' },
    { value: <b>{t('Common.title')}</b> },
    { value: <b>{t('Txt.statusTitle')}</b> },
    { value: <b>{t('Common.name')}</b> },
    { value: <b>{t('Txt.actions')}</b>, width: 'w-[380px]' }
  ]
  const Rows = data?.data?.data.map(item => {
    return {
      row: [
        { value: <div className='ms-1'>{item.id}</div>, width: 'w-[150px]' },

        { value: <div>{item.title}</div> },
        { value: <div>{item.statusTitle}</div> },
        {
          value: <div>{item.user.first_name + ' ' + item.user.last_name}</div>
        },
        {
          value: (
            <div className='flexRow items-center'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  dispatch(setSupportFormMode('view'))
                  dispatch(setSupportFormData(item))
                }}
              >
                <EyeIcon fill='var(--secondary)' size='30px' />
              </div>
            </div>
          ),
          width: 'w-[380px]'
        }
      ]
    }
  })
  return (
    <>
      <div className='  py-4'>
        {isLoading && (
          <div className='flexRow justify-center w-full'>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <ListDataTable
            header={Headers}
            setPage={(e:number) => handleChangePageNumber(e)}
            handleChangePage={(params1: any, params2: number) =>{
              let newPage = params2+1
              handleChangePageNumber(newPage)
            }
            }
            rows={Rows ?? []}
            currentPage={+(data?.data.current_page ?? 1) - 1}
            rowsPerPage={+(data?.data.per_page ?? 0)}
            total={+(data?.data.total ?? 0)}
            onRowsPerPageChange={(params1: any, params2: any) =>
              console.log(params1, params2)
            }
          />
        )}
      </div>

      <FormSupport />
    </>
  )
}

export default ListData
