import * as Yup from "yup"

import { SubmitHandler, useForm } from "react-hook-form";
import  { useEffect, useState } from 'react'

import ButtonOrange from "../../shared/button/ButtonBrand";
import InputTxt from "../../shared/form/input/InputTxt";
import PasswordTxt from "../../shared/form/input/PasswordTxt";
import { SetItemLocal } from "../../../utility/FunctionStorage";
import { SetToken } from "../../../utility/TokenFunction";
import { ToastError } from "../../../utility/ToastFunction";
import { UrlS } from "../../../config/Urls";
import { useLoginMutation } from '../../../data/services/Auth';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";

function LoginForm() {
    const Schema = Yup.object().shape({
        username: Yup.string().required(("اجباری است")),
        password: Yup.string().required(("اجباری است")),
    });

    const { handleSubmit, control,   formState: { errors },   } = useForm<any>({
        resolver: yupResolver(Schema)
    });
    const navigate = useNavigate();
    const [login, results] = useLoginMutation()
    const [loading, setLoading] = useState(results.isLoading)

    const OnFinish: SubmitHandler<any> = (data) => { 
        if (data?.username !== undefined) {
            setLoading(true)
            login(data);
    

        }

    }
    useEffect(() => {
        if (results?.isSuccess) {
            setLoading(false);
            SetItemLocal("userA",results?.data?.data?.token)
            navigate(UrlS.dashboard.main)
        }
        if (results.isError) {
            console.log(results)
            setLoading(false);
            ToastError('نام کاربری یا رمز عبور اشتباه است!')
        }
    }, [navigate, results])
    return (
        <>
            <div className="w-full p-4 flex flex-col gap-y-10 shadow-2xl rounded-[--radius] ResponsiveLayout">
                <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                    <div className="w-full p-2 flex flex-col gap-y-10">
                        <div className='w-full '>
                            <InputTxt control={control} errors={errors} keyValue='username' label="نام کاربری" name='username' placeholder='نام کاربری' />
                        </div>
                        <div className='w-full '>
                            <PasswordTxt control={control} errors={errors} keyValue='password' label=" رمز عبور " name='password' placeholder=" رمز عبور " />
                        </div>
                        <div className='w-full '>
                            <ButtonOrange type='submit' loading={loading} key={"mobileButtonForm"} click={OnFinish} >
                                <b>ورود</b>
                            </ButtonOrange>
                        </div>
                    </div>
                </form>
              
            </div>
        </>
    )
}

export default LoginForm