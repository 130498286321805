
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../data/store'

import EditFaq from './actions/edit'
import DeleteBannerComponent from './actions/delete'
import AddFaq from './actions/add'

export default function FormFaq() {

  const mode = useSelector((state: RootState) => state.FaqForm.mode)

  return (
    <>
      <div className='w-full h-full bg-[--light] p-2 rounded-[--radius]'>
 

        {mode === "delete" && <DeleteBannerComponent/>}
        {mode === "add" && <AddFaq/>}
        {mode==="edit"&&<EditFaq/>}

      </div>
    </>
  )
}
