import React from 'react'
import { FormComponentProps } from '../../../../interface/Element'
import InputTxt from '../../../shared/form/input/InputTxt'
import { useTranslation } from 'next-i18next'
import PasswordTxt from '../../../shared/form/input/PasswordTxt'
import RoleSelect from '../../../role/select'


export default function FormItem({ control, getValues, setValue, errors }: FormComponentProps) {
    const { t } = useTranslation()
    return (
        <>
            <div className='flexCol flex-wrap w-full'>

                <InputTxt
                    control={control}
                    errors={errors}
                    keyValue='name'
                    label={t("Common.name")}
                    name='name'
                    placeholder={t("Common.name")} />


                <InputTxt
                    control={control}
                    errors={errors}
                    keyValue='username'
                    label={t("Txt.username")}
                    name='username'
                    placeholder={t("Txt.username")} />

                <PasswordTxt
                    control={control}
                    errors={errors}
                    keyValue='password'
                    label={t("Common.password")}
                    name='password'
                    placeholder={t("Common.password")} />

                <RoleSelect control={control} errors={errors} name='role_id' />




            </div>

        </ >


    )
}
