
import GrayLine from '../../../shared/Html/GrayLine';
import TitleValue from '../../../shared/Html/TitleValue';
import { useTranslation } from 'next-i18next';
import { useUserDetailQuery } from '../../../../data/services/User';
import { Loader } from '../../../shared/Loader';
import { ID } from '../../../../interface/EntityCommon';

function InfoUser({id}:ID) {

  const { t } = useTranslation();
  const { data, isLoading } = useUserDetailQuery({ id: id })

  return (
    <>
      {
        isLoading ? <><Loader /></> :


          <div className='flexCol gap-4 text-lg '>

            <div className="flexRow gap-1 md:w-[50%] md:mx-auto">
              <TitleValue title={t("Txt.name")} value={data?.data?.first_name + " " + data?.data?.last_name} />
            </div>

            <GrayLine />

            <div className="flexRow gap-1 md:w-[50%] md:mx-auto">
              <TitleValue title={t("Common.mobile")} value={data?.data?.mobile} />
            </div>

            <GrayLine />

            <div className="flexRow gap-1 md:w-[50%] md:mx-auto">
              <TitleValue title={t("Txt.type")} value={t("Txt.type_" + data?.data?.type)} />
            </div>

          </div>
      }
    </>
  )
}

export default InfoUser