
import { changeToQueryParams, ChangeToQueryString } from "../../utility/Function";
import { ID } from "../../interface/EntityCommon";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'
import { Transaction_PATH } from "../../config/ApiConf";
import { TransactionDetailData, TransactionListApi, TransactionListFilterApi } from "../../interface/EntityTransaction";

export const TransactionApi = createApi({
    reducerPath: 'TransactionApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ["Transaction"],
    endpoints: (builder) => ({
        Transaction: builder.query<TransactionListApi, TransactionListFilterApi>({
            query: (body) => ({
                url: Transaction_PATH+changeToQueryParams(body),
                method: 'GET',
            })
            ,
            providesTags: ['Transaction'],
        }),
        TransactionDetail: builder.query<TransactionDetailData, ID>({
            query: (body) => ({
                url: Transaction_PATH+'/'+body.id,
                method: 'GET',
            })
            ,
            providesTags: ['Transaction'],
        })
    })
})

export const {
    useTransactionQuery,
    useTransactionDetailQuery,
  
} = TransactionApi


