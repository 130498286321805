import { useSectionQuery } from '../../../../../data/services/Section'
import InputCheckbox from '../../../../shared/form/input/InputCheckbox'
import { FormProps } from '../../../../../interface/Element'
import { useProductSectionQuery } from '../../../../../data/services/Product'

export interface SectionsPAgeProps extends FormProps{
    page_id: number 
    product_id: number 
}

export default function SectionsPAge({ page_id,control,product_id,errors,changeF,getValues }: SectionsPAgeProps) {

    const { data } = useSectionQuery({ page: 1, page_id })

    return (
        <>

            {data?.data.map((item) => {
                return <>
                    {/* <LabelTxt className="bg-transparent !text-[--secondaryTxt] font-bold" txt={item.title} /> */}
                    <div className="flexRow flex-wrap gap-4">

                        <div className='w-[180px]'>
                            <InputCheckbox
                                getValues={getValues}
                                keyValue={item.id.toString()}
                                label={item.title}
                                name={item.id.toString()}
                                changeF={changeF}
                                defaultValue={item.id.toString()}
                                control={control}
                            />
                        </div>


                    </div>
                </>
            })}

        </>
    )
}
