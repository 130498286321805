import { createSlice } from "@reduxjs/toolkit"

const initialState:any[]=[]


export const OrderData = createSlice({
    name: 'OrderData',
    initialState,
    reducers: {
        setOrderData: (state, action: { payload:any[] }) => {
            return [...state,...action.payload];
        },
   
        emptyOrderData: (state) => {
            state=initialState
            return state
        },
       
    }
})

export const { setOrderData, emptyOrderData } = OrderData.actions

export default OrderData.reducer