import { useEffect, useState } from 'react'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import FormItem from '../FormItem';
import { ToastError, ToastSuccess } from '../../../../../utility/ToastFunction';
import ButtonBrand from '../../../../shared/button/ButtonBrand';
import ModalFull from '../../../../shared/modal/ModalFull';
import PageMarginBottom from '../../../../shared/Html/PageMarginBottom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store';
import { setRoleFormMode } from '../../../../../data/slice/role/RoleForm';
import { usePageEditMutation } from '../../../../../data/services/Page';

export default function EditPage() {

    const { t } = useTranslation();
    const Data = useSelector((state: RootState) => state.PageForm);
    const dispatch = useDispatch()
    const [OpenAdd, setOpenAdd] = useState(false);
    const [pageEdit, results] = usePageEditMutation()
    const [loading, setLoading] = useState(results.isLoading)
    const Schema = Yup.object().shape({
        name: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema),

    });

    const OnFinish: SubmitHandler<any> = (data) => {

        if (data?.name !== undefined) {
            setLoading(true)

            pageEdit(data);
        }
    }

    useEffect(() => {
        reset();
    }, [])



    useEffect(() => {
        if (results.isSuccess) {
            setLoading(false);
            ToastSuccess(t("Common.successAlarm"))
            setOpenAdd(false);
            dispatch(setRoleFormMode("none"))
        }
        if (results.isError) {
            setLoading(false);
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    useEffect(() => {
        if (Data.mode === 'edit') {
            setOpenAdd(true);
            reset({
                id: Data.data?.id,
                name: Data.data?.name,
                url: Data.data?.url,
                priority: Data.data?.priority,
            });
        } else {
            setOpenAdd(false);
        }
    }, [Data.mode, reset])


    return (
        <>

                 <div className="FormModal !h-auto">
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />

                        <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]' >
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={loading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>

                    <PageMarginBottom />


                </div>

         

        </>
    )
}
