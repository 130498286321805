import React, { useEffect, useState } from 'react'
import { useCategoryQuery } from '../../../data/services/Category'
import logo from '../../../asset/img/logo.png'
import { baseUrl } from '../../../config/ApiConf';
import GrayLine from '../../shared/Html/GrayLine';
import ButtonBrandLightIcon from '../../shared/button/ButtonOuterBrandIcon';
import EditIcon from '../../shared/Icon/Edit';
import TrashIcon from '../../shared/Icon/Trash';
import PlusIcon from '../../shared/Icon/Plus';
import { useDispatch, useSelector } from 'react-redux';
import { emptyCategoryFormData, emptyCategoryParentAllFormData, emptyCategoryParentFormData, setCategoryDeleteItem, setCategoryFormData, setCategoryFormMode, setCategoryParentFormData } from '../../../data/slice/category/CategoryForm';
import { RootState } from '../../../data/store';
import { CategoryDataType } from '../../../interface/EntityCategory';
import { useTranslation } from 'next-i18next';

export default function Show() {

  const {t}=useTranslation();
  const Data = useSelector((state: RootState) => state.CategoryForm)
  const [param, setParam] = useState({ parent_id: "null",category_type:Data.type })
  const { data } = useCategoryQuery(param);
  const dispatch = useDispatch()
  useEffect(() => {
    if (Data.data !== null) {
      if (Data.mode === "addChild")
        setParam({ parent_id: Data.data.id.toString() ,category_type:Data.type})
    }

  }, [Data])

  useEffect(() => {
        setParam({...param,parent_id:"null" ,category_type:Data.type})
    

  }, [Data.type])
  const AddToChild = (item: CategoryDataType) => {
    dispatch(setCategoryFormMode("addChild"));
    dispatch(setCategoryFormData(item))
    dispatch(setCategoryParentFormData(item))
  }

  const BackToParent = (item: CategoryDataType) => {
    let CategoryIndex=Data.parents.findIndex((data)=>data.id===item.id);
    let parent=CategoryIndex>0?Data.parents[CategoryIndex-1].id:"null"
    dispatch(emptyCategoryFormData())
    setParam({parent_id:parent?.toString()??"null",category_type:Data.type})
    dispatch(emptyCategoryParentFormData(item))
    if(CategoryIndex-1<0){
      dispatch(emptyCategoryParentAllFormData())
    }
  }

  return (
    <section>
      <div className='flexRow gap-2'>
        {Data.parents.map((item) => <>
          <b className='cursor-pointer' onClick={()=>BackToParent(item)}>{item?.parentData?.name??t("Txt.mainCategory")}</b>
        </>)}

      </div>
      <div className="flexRow p-2 flex-wrap gap-4">
        {data?.data.map((item) => <>
          <div className='px-2 py-4 rounded-[--radius] flexCol bg-[--light] h-[250px] w-48 gap-4'>
            <img className='rounded-[--radius] w-full h-[120px] object-cover' src={item.attach?.pathFile ? baseUrl + item.attach?.pathFile : logo} alt="" />
            <strong>{item?.name}</strong>
            <GrayLine />
            <div className='flexRow gap-2'>
              <ButtonBrandLightIcon click={() => { AddToChild(item) }}>
                <PlusIcon size='15px' fill={'var(--secondaryTxt)'} />
              </ButtonBrandLightIcon>

              <ButtonBrandLightIcon click={() => { dispatch(setCategoryFormMode("edit")); dispatch(setCategoryFormData(item)) }}>
                <EditIcon size='15px' fill={'var(--secondaryTxt)'} />
              </ButtonBrandLightIcon>

              <ButtonBrandLightIcon click={() => { dispatch(setCategoryFormMode("delete")); dispatch(setCategoryDeleteItem(item)) }}>
                <TrashIcon size='15px' fill={'var(--error)'} />
              </ButtonBrandLightIcon>

            </div>
          </div>
        </>)}

      </div>
    </section>
  )
}
