import { FormComponentProps } from '../../../../interface/Element'
import InputTxt from '../../../shared/form/input/InputTxt'
import InputTextarea from '../../../shared/form/input/InputTextarea'
import NumberInput from '../../../shared/form/input/NumberInput'
import PackageType from './PackageType'
import { useTranslation } from 'next-i18next'
import CategorySelect from '../../../category/select/CategorySelect'
import { useEffect, useState } from 'react'

export default function FormItem({ control, errors, getValues, setValue, watch }: FormComponentProps) {
    const { t } = useTranslation();
    const [pricePercent,setPricePercent]=useState(0)
  
    const SetPricePercentChange=()=>{
        const price= watch("price")
        const percent:any = watch("price_single_percent")
        setPricePercent( ((parseFloat(price) * parseInt(percent))/100) + parseFloat(price));
    }

    useEffect(()=>{
        SetPricePercentChange()
    },[])


    return (
        <>
            <div className="w-full p-2 flexRow flex-wrap gap-8">
                <div className='w-full sm:w-[45%] '>
                    <InputTxt control={control} errors={errors} keyValue='name' label="نام محصول" name='name' placeholder='نام محصول' />
                </div>
                <div className='w-full sm:w-[45%] '>
                    <InputTxt control={control} errors={errors} keyValue='slug' label="آدرس محصول" name='slug' placeholder='آدرس محصول' />
                </div>

                <div className='w-full sm:w-[45%] '>
                    <InputTxt control={control} errors={errors} keyValue='code' label="کد محصول" name='code' placeholder='کد محصول' />
                </div>
                <div className='w-full sm:w-[45%] '>
                    <CategorySelect control={control} errors={errors} name='category_id' />

                </div>
                <div className='w-full sm:w-[45%] '>
                    <PackageType control={control} errors={errors} />
                </div>
                <div className='w-full sm:w-[45%] '>
                    <NumberInput
                        control={control} errors={errors} keyValue='pack_number' label={t("Txt.packageNumber")} name='pack_number' placeholder="" />
                </div>


                <div className='w-full sm:w-[45%]'>
                    <NumberInput
                        control={control} changeF={() => SetPricePercentChange()} errors={errors} keyValue='price' label={t("Txt.price")} name='price' placeholder="" />
                </div>
                <div className='w-full sm:w-[45%] '>
                    <NumberInput control={control}  changeF={() => SetPricePercentChange()} errors={errors} keyValue='price_single_percent' label={t("Txt.priceSinglePercent")} name='price_single_percent' placeholder="" />
                </div>
                <div className="w-full text-center">
                    {<p>{pricePercent.toLocaleString()}</p>}
                </div>
                <div className='w-full sm:w-[45%] '>
                    <InputTxt control={control} errors={errors} keyValue='price_description' label="توضیح قیمت" name='price_description' placeholder='توضیح قیمت' />
                </div>


                <div className='w-full sm:w-[45%] '>
                    <InputTxt errors={errors} label={t("Common.titleSeo")} name="title_seo" control={control}
                        keyValue="title_seo" />
                </div>
                <div className='w-full sm:w-[45%] '>
                    <InputTextarea errors={errors} label={t("Common.descriptionSeo")} name="description_seo" control={control}
                        keyValue="description_seo" />
                </div>
                <div className='w-full sm:w-[45%] '>
                    <InputTextarea control={control} errors={errors} keyValue='description' label={t("Txt.description")} name='description' placeholder={t("Txt.description")} />
                </div>
                <div className='w-full sm:w-[45%] '>
                    <InputTxt errors={errors} label={t("Common.alt")} name="alt" control={control}
                        keyValue="alt" />
                </div>



            </div>
        </>
    )
}
