import React from 'react'
import { FormComponentProps } from '../../../../interface/Element'
import InputTxt from '../../../shared/form/input/InputTxt'
import { useTranslation } from 'next-i18next'


import CheckBoxPermission from '../../../permission/form/checkBox'
import FileInput from '../../../shared/form/upload/FileInput'
import { ImgUploadType } from '../../../../config/App'
import InputTextarea from '../../../shared/form/input/InputTextarea'

export default function FormItem({ control, getValues, setValue, errors }: FormComponentProps) {
    const { t } = useTranslation()
    return (
        <>
            <div className='flexCol flex-wrap w-full items-center'>
                <div className='w-[45%]'>
                    <InputTxt control={control} errors={errors} keyValue='link' label={t("Txt.link")} name='link' placeholder={t("Txt.link")} />
                </div>
             
                <div className='w-[45%]'>
                    <FileInput
                        changeValue={(file: File) => console.log(file)}
                        getValues={getValues}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        name="image"
                        accept={ImgUploadType}
                        title={t("Txt.chooseImg")} />
                </div>

            </div>

        </ >


    )
}
