import * as Yup from "yup"

import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'



import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from "next-i18next"
import ModalFull from "../../../../shared/modal/ModalFull"
import FormItem, { IUser } from "../FormItemDiscount"
import ButtonBrand from "../../../../shared/button/ButtonBrand"
import { ToastError, ToastSuccess } from "../../../../../utility/ToastFunction"
import PageMarginBottom from "../../../../shared/Html/PageMarginBottom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../../data/store"
import { setSliderFormMode } from "../../../../../data/slice/slider/SliderForm"
import { IDiscount } from "../../../../../interface/EntityDiscount"
import { setDiscountFormMode } from "../../../../../data/slice/discount/DiscountForm"
import { useAddDiscountMutation } from "../../../../../data/services/Discount"

export default function AddDiscount() {

    const { t } = useTranslation();
    const DataMod = useSelector((state: RootState) => state.DiscountForm);
    const [OpenAdd, setOpenAdd] = useState(false);
    const [Add, results] = useAddDiscountMutation()
    const dispatch = useDispatch()
    const Schema = Yup.object().shape({
        name: Yup.string().required(t("Validation.require")),
        code: Yup.string().required(t("Validation.require")).min(3),
        allowed_number: Yup.string().required(t("Validation.require")),
        reductionType: Yup.string().required(t("Validation.require")),
        type: Yup.string().required(t("Validation.require")),
        amount: Yup.string().required(t("Validation.require")),
        active: Yup.string().required(t("Validation.require")),
        start_date: Yup.string().required(t("Validation.require")),
        expire_date: Yup.string().required(t("Validation.require"))
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset, watch } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<IDiscount> = (data) => {
        let newUsers: number[] = []
        if (data?.type !== undefined && data?.type === "users") {
            data.users.map((user: IUser) => newUsers.push( user?.value ))
            setValue("users", newUsers)
        }


        if (data?.name !== undefined) {
            // console.log("change : ",changeToFormDataDiscount(data))
            Add(data);
        }
    }

    useEffect(() => {
        if (DataMod.mode === 'add') {
            setOpenAdd(true);
            reset();
        } else {
            setOpenAdd(false);
        }
    }, [DataMod.mode, reset])

    useEffect(() => {
        if (results.isSuccess) {
            ToastSuccess(t("Common.successAlarm"))
            setOpenAdd(false);
            dispatch(setSliderFormMode("none"));
        }
        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    return (
        <>

            <ModalFull
                ChangeOpen={
                    (open: boolean) => {
                        setOpenAdd(open);
                        dispatch(setDiscountFormMode(open ? "add" : "none"));
                    }
                }
                Open={OpenAdd} title={t("Common.addDiscount")}>
                <div className="FormModal !h-auto">
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} watch={watch} />
                        <div className='w-full flex justify-center mt-10'>
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>
                    <PageMarginBottom />
                </div>

            </ModalFull>
        </>
    )
}
