import { useState } from 'react'
import { Loader } from '../../shared/Loader'
import { useTranslation } from 'next-i18next'
import { useSliderQuery } from '../../../data/services/Slider'
import FormSliders from '../form/Index'
import { baseUrl } from '../../../config/ApiConf'
import { NullValue } from '../../../utility/Function'
import ImageComponent from '../../shared/Html/Image'
import ListDataTable from './../../shared/listData/index'


// icons
import TrashIcon from '../../shared/Icon/Trash'
import SettingIcon from '../../shared/Icon/Setting'

// redux
import { useDispatch } from 'react-redux'
import {
  setSliderDeleteItem,
  setSliderFormData,
  setSliderFormMode
} from '../../../data/slice/slider/SliderForm'

function ListData () {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const { data, isLoading } = useSliderQuery()
  const dispatch = useDispatch()

  const Headers = [
    { value: <b>#</b>, width: 'w-[150px]' },
    { value: <b>{t('Txt.title')}</b> },
    { value: <b>{t('Txt.text')}</b> },
    { value: <b>{t('Txt.smallImage')}</b> },
    { value: <b>{t('Txt.largeImage')}</b> },
    { value: <b>{t('Txt.link')}</b> },
    { value: <b>{t('Txt.actions')}</b>, width: 'w-[380px]' }
  ]
  const Rows = data?.data?.map(item => {
    return {
      row: [
        { value: <div className='ms-1'>{item.id}</div>, width: 'w-[150px]' },

        { value: <div>{NullValue(item.title)}</div> },
        { value: <div>{NullValue(item.text)}</div> },
        {
          value: (
            <div className=' w-full h-full'>
              <img
                className='rounded-md h-full w-full object-cover '
                src={baseUrl + item.pathFile}
                loading='lazy'
              />
            </div>
          )
        },
        {
          value: (
            <div className=' w-full h-full'>
              <ImageComponent
                src={item.pathFileImage2}
                className='rounded-md h-full w-full object-cover '
              />
            </div>
          )
        },
        {
          value: (
            <div className='p-4'>
              <a className='rounded-md w-full' href={item.link}>
                {NullValue(item.link)}
              </a>
            </div>
          )
        },
        {
          value: (
            <div className='flexRow items-center'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  dispatch(setSliderFormMode('edit'))
                  dispatch(setSliderFormData(item))
                }}
              >
                <SettingIcon fill='var(--secondary)' size='30px' />
              </div>

              <div
                className='cursor-pointer'
                onClick={() => {
                  dispatch(setSliderFormMode('delete'))
                  dispatch(setSliderDeleteItem(item))
                }}
              >
                <TrashIcon fill='var(--error)' size='30px' />
              </div>
            </div>
          ),
          width: 'w-[380px]'
        }
      ]
    }
  })
  return (
    <>
      <div className='  py-4'>
        {isLoading && (
          <div className='flexRow justify-center w-full'>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <ListDataTable
            header={Headers}
            setPage={setPage}
            rows={Rows ?? []}
            // currentPage={+(data?.data.current_page ?? 1) - 1}
            // rowsPerPage={+(data?.data.per_page ?? 0)}
            // total={+(data?.data.total ?? 0)}
            handleChangePage={(params1: any, params2: number) =>
              setPage(params2 + 1)
            }
            onRowsPerPageChange={(params1: any, params2: any) =>
              console.log(params1, params2)
            }
          />
        )}
      </div>
      <FormSliders />
    </>
  )
}

export default ListData
