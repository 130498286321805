import { useState, useEffect } from 'react'
import { Marker, Popup, useMapEvents } from "react-leaflet";

// import markerIconPng from "./mark.png"
import markerIconPng from "./Vector.svg"
import { Icon } from 'leaflet'
import { MapMarket } from './interface';

export default function LocationMarkers(props: MapMarket) {
    const { setCenter, GetAddressDetail, data, canChangeLocation, editMode, trigger } = props;
    const [position, setPosition] = useState<any>(null);
    //const map = useMap();
    const map = useMapEvents({

        click(e) {
            setCenter(e.latlng)
            setPosition([e.latlng.lat, e.latlng.lng])
            map.flyTo(e.latlng, map.getZoom())
            GetAddressDetail(e.latlng);

        },
        locationfound(e) {
            // if (!editMode) {
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
            // }
        },
    })


    useEffect(() => {

        if (data !== undefined) {
            if (Object.keys(data).length > 0) {

                if (data.lat !== '' && data.lng !== '') {
                    let newData = { lat: data.lat, lng: data.lng }
                    // setCenter(newData);
                    // setCenter(newData)
                    setPosition([data.lat, data.lng])
                    // GetAddressDetail(newData);
                    ///@ts-ignore
                    map.flyTo(newData, map.getZoom())
                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // }, [data,  setCenter])
        // }, [data, setCenter])
        // }, [data])
    }, [])


    const handleGetCurrentLocation = () => {
        console.log("get curent location")
        if (navigator?.geolocation) {

            navigator.geolocation.getCurrentPosition((location) => {

                if (location) {
                    let lat = location.coords.latitude
                    let lng = location.coords.longitude
                    setPosition([lat, lng])
                    map.flyTo({ lat, lng })
                }

            });
        }
    }


    useEffect(() => {
        handleGetCurrentLocation()
    }, [trigger])


    useEffect(() => {
        if (navigator?.geolocation) {
            navigator.geolocation.getCurrentPosition((location) => {
                if (location) {
                    let lat = location.coords.latitude
                    let lng = location.coords.longitude
                    setPosition([lat, lng])
                    map.flyTo({ lat, lng })
                }

            });
        }
        // }
    }, [map]);

    return position === null ? null : (
        <Marker position={position}
            icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
        >
            <Popup>شما اینجا هستی</Popup>
        </Marker>
    )
}