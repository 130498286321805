import { KeyValue, ProductDetailData } from '../../../../interface/EntityProduct'
import { useEffect, useState } from 'react'

import AddKeys from './action/add';
import ButtonOuterBrandIcon from '../../../shared/button/ButtonOuterBrandIcon';
import { CapitalizeFirstLetter, refreshPage } from '../../../../utility/Function';
import EditIcon from '../../../shared/Icon/Edit';
import EditKeys from './action/edit';
import GrayLine from '../../../shared/Html/GrayLine';
import RemoveDialog from '../../../shared/dialog/RemoveDialog';
import TitleValue from '../../../shared/Html/TitleValue';
import TrashIcon from '../../../shared/Icon/Trash';
import { useProductRemoveKeyMutation } from '../../../../data/services/Product';
import { useTranslation } from 'next-i18next';

function Keys({ ...data }: ProductDetailData) {


    const { t } = useTranslation()
    const [ModalDeleteShow, setModalDeleteShow] = useState(false)
    const [removeItem, setRemoveItem] = useState<KeyValue | null>(null);
    const [editItem, setEditItem] = useState<KeyValue >({id:0,key:"",product_id:0,show:"both",value:""});
    const [mode, setMode] = useState<"add" | "edit">('add');
    const [RemoveKey, results] = useProductRemoveKeyMutation()

    const RemoveIcon = () => {
        if (removeItem !== null)
            RemoveKey({ id: removeItem.id })
    }

    useEffect(() => {
        if (results.isSuccess) {
            setModalDeleteShow(false);
            setRemoveItem(null);
            // refreshPage()
        }

    }, [results])
    return (
        <>
            <div className='FormModal'>
                <div className='border p-6 my-4 rounded-[--radius]'>
                   {mode==="add"&&<AddKeys {...data} />}
                   {mode==="edit"&&<EditKeys  data={editItem} cancel={setMode} />}
                </div>


                <div className='flexRow gap-4 flex-wrap my-6 p-4 '>
                    {data.keys.map((item) => <>
                        <div className="w-[350px] shadow-xl flexCol gap-4 rounded-[--radius] p-4">
                            <TitleValue title={t("Common.title")} value={item.key} key={item.key} />
                            <GrayLine />
                         
                            <TitleValue title={t("Common.text")} value={item.value} key={item.value  } />
                            
                            <GrayLine />
                            <TitleValue title={t("Txt.typeShowRequest")} value={t("Txt.show"+CapitalizeFirstLetter(item.show))} key={item.product_id + "m"} />
                            <GrayLine />
                            <div className="flexRow gap-2">
                                <ButtonOuterBrandIcon click={() => { setEditItem(item); setMode("edit") }}>
                                    <EditIcon fill='var(--secondary)' />
                                </ButtonOuterBrandIcon>
                                <ButtonOuterBrandIcon click={() => { setRemoveItem(item); setModalDeleteShow(true) }}>
                                    <TrashIcon fill='var(--error)' />
                                </ButtonOuterBrandIcon>
                            </div>

                        </div>
                    </>)}

                </div>
            </div>


            {ModalDeleteShow && <RemoveDialog
                open={ModalDeleteShow}
                DeleteFunction={() => RemoveIcon()}
                CloseModal={() => setModalDeleteShow(false)}
                cancelTitle={t("Common.cancel")}
                deleteAgreeTitle={""}
                deleteTitle={t("Common.delete")}
                loadingConfirmButton={results.isLoading}
                title={t("Txt.removeItem") + removeItem?.key}
            />}
        </>
    )


}

export default Keys