import { baseUrl } from '../../../../config/ApiConf'
import { GalleryData, ProductDetailData, ShowFile } from '../../../../interface/EntityProduct'

export default function Gallery({ data, typeShow = [ "both" , "single" , "wholesaler"] }: { data: GalleryData[], typeShow?: ShowFile[] }) {
// console.log(data)
    return (
        <>
            <div className='flexRow flex-wrap gap-4 p-4'>
                {data.map((item) => {
                    if (!typeShow.includes(item.show))
                        return <></>

                    return <div className='w-[300px] h-[200px]  '>
                        {item.type === 'image' && <img className='w-full h-full rounded-[--radius] object-cover object-center aspect-auto' src={baseUrl + item.pathFile} alt="" />}
                        {item.type === 'video' &&
                            <video width="320" height="240" className=' rounded-[--radius]' controls>
                                <source src={baseUrl + item.pathFile} />
                            </video>}

                    </div>
                }
                )}

            </div>

        </>
    )
}
