import { PackageTypeAddPropsApi, PackageTypeListApi } from "../../interface/EntityPackageType";

import { ChangeToFormData } from "../../utility/Function";
import { GetToken } from "../../utility/TokenFunction";
import { PackageType_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const PackageTypeApi = createApi({
    reducerPath: 'packageTypeApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["PackageType"],

    endpoints: (builder) => ({
        PackageType: builder.query<PackageTypeListApi, void>({
            query: () => ({
                url: PackageType_PATH,
                method: 'GET',
                
            })
            ,
            providesTags: ['PackageType'],
        }),


        PackageTypeAdd: builder.mutation<void, PackageTypeAddPropsApi>({
            query: (body) => ({
                url: PackageType_PATH,
                method: 'POST',
                
                body:ChangeToFormData(body)
            }),
            invalidatesTags: ['PackageType'],
        }),

       

    }),



})

export const { usePackageTypeQuery, usePackageTypeAddMutation } = PackageTypeApi


