import { useState } from 'react'
import { useFaqQuery } from '../../../data/services/Faq'
import FormFaq from '../form/Index'
import ListDataTable from './../../shared/listData/index'
import { Loader } from '../../shared/Loader'

// i18n
import { useTranslation } from 'next-i18next'

// icons
import SettingIcon from '../../shared/Icon/Setting'
import TrashIcon from '../../shared/Icon/Trash'

// redux
import { useDispatch } from 'react-redux'
import {
  setFaqDeleteItem,
  setFaqFormData,
  setFaqFormMode
} from '../../../data/slice/faq/FaqForm'

function ListData () {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)

  const { data, isLoading, refetch } = useFaqQuery()
  const dispatch = useDispatch()

  const Headers = [
    { value: <b>#</b>, width: 'w-[150px]' },
    { value: <b>{t('Txt.title')}</b> },
    { value: <b>{t('Txt.text')}</b> },
    { value: <b>{t('Txt.actions')}</b>, width: 'w-[380px]' }
  ]

  const Rows = data?.data?.map(item => {
    return {
      row: [
        { value: <div className='ms-1'>{item.id}</div>, width: 'w-[150px]' },

        {
          value: (
            <div className=' w-full h-full'>
              <p>{item.title}</p>
            </div>
          )
        },
        {
          value: (
            <div className='w-full h-full'>
              <p>{item.text}</p>
            </div>
          )
        },
        {
          value: (
            <div className='flexRow items-center'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  dispatch(setFaqFormMode('edit'))
                  dispatch(setFaqFormData(item))
                }}
              >
                <SettingIcon fill='var(--secondary)' size='30px' />
              </div>

              <div
                className='cursor-pointer'
                onClick={() => {
                  dispatch(setFaqFormMode('delete'))
                  dispatch(setFaqDeleteItem(item))
                }}
              >
                <TrashIcon fill='var(--error)' size='30px' />
              </div>
            </div>
          ),
          width: 'w-[380px]'
        }
      ]
    }
  })

  return (
    <>
      <div className='  py-4'>
        {isLoading && (
          <div className='flexRow justify-center w-full'>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <ListDataTable
            header={Headers}
            setPage={setPage}
            rows={Rows ?? []}
            // currentPage={+(data?.data.current_page ?? 1) - 1}
            // rowsPerPage={+(data?.data.per_page ?? 0)}
            // total={+(data?.data.total ?? 0)}
            handleChangePage={(params1: any, params2: number) =>
              setPage(params2 + 1)
            }
            onRowsPerPageChange={(params1: any, params2: any) =>
              console.log(params1, params2)
            }
          />
        )}
      </div>

      <FormFaq />
    </>
  )
}

export default ListData
