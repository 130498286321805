import * as Yup from "yup"

import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'



import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from "next-i18next"
import { useSectionAddMutation, useSectionEditMutation } from "../../../../../../../data/services/Section"
import { ToastError, ToastSuccess } from "../../../../../../../utility/ToastFunction"
import ButtonBrand from "../../../../../../shared/button/ButtonBrand"
import FormItem from "../FormItem"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../../../data/store"
import { SectionDataType } from "../../../../../../../interface/EntitySection"
import ButtonOuterBrandIcon from "../../../../../../shared/button/ButtonOuterBrandIcon"
import ButtonBrandOuter from "../../../../../../shared/button/ButtonBrandOuter"
import { baseUrl } from "../../../../../../../config/ApiConf"


export default function EditSection({ data, changeAction }: { data: SectionDataType, changeAction: Function }) {

    const { t } = useTranslation();
    const [sectionAdd, results] = useSectionEditMutation()
    const Data = useSelector((state: RootState) => state.PageForm)
    const Schema = Yup.object().shape({
        title: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<any> = (data) => {

    //   console.log("run edit",data)
        if (data?.title !== undefined) {
            let params = data;
            params['page_id'] = Data.data?.id

            if(params.image===undefined){
                delete(params['image']);
            }

            sectionAdd(params);
        }
    }

    useEffect(() => {
        // reset(data);

        reset({
            id: data.id,
            title: data.title,
            priority: data.priority,
            user_type_show: data.user_type_show,
            link:data.link
        });
    }, [data])



    useEffect(() => {
        if (results.isSuccess) {

            ToastSuccess(t("Common.successAlarm"))
            reset();
            changeAction("add")
        }
        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    return (
        <>

            <div className="FormModal !h-auto">
                <div className='w-[50%] h-auto my-2 mx-auto'>
                    {data.image && <img src={baseUrl + data.pathFile} className='w-full h-full object-fill  rounded-[--radius]' />}

                </div>
                <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                    <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />

                    <div className='w-full flex justify-center mt-10 gap-2'>
                        <div className="w-1/3">
                            <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                <b>{t("Common.edit")}</b>
                            </ButtonBrand>


                        </div>
                        <div className="w-1/3">


                            <ButtonBrandOuter type='button' click={() => changeAction("add")} >
                                <b>{t("Common.cancel")}</b>
                            </ButtonBrandOuter>
                        </div>
                    </div>
                </form>



            </div>

        </>
    )
}

