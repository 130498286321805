import React from 'react'
import { SvgProps } from '../../../interface/Element'

function AdminIcon({ fill = "#000000", size = "20px", stroke = "none" }: SvgProps) {
    return (
        <>
            <svg fill={fill} height={size} width={size} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink ="http://www.w3.org/1999/xlink"
                viewBox="0 0 24 24" enable-background="new 0 0 24 24" xmlSpace="preserve">
                <g id="user-admin">
                    <path d="M22.3,16.7l1.4-1.4L20,11.6l-5.8,5.8c-0.5-0.3-1.1-0.4-1.7-0.4C10.6,17,9,18.6,9,20.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5
		c0-0.6-0.2-1.2-0.4-1.7l1.9-1.9l2.3,2.3l1.4-1.4l-2.3-2.3l1.1-1.1L22.3,16.7z M12.5,22c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5
		s1.5,0.7,1.5,1.5S13.3,22,12.5,22z"/>
                    <path d="M2,19c0-3.9,3.1-7,7-7c2,0,3.9,0.9,5.3,2.4l1.5-1.3c-0.9-1-1.9-1.8-3.1-2.3C14.1,9.7,15,7.9,15,6c0-3.3-2.7-6-6-6
		S3,2.7,3,6c0,1.9,0.9,3.7,2.4,4.8C2.2,12.2,0,15.3,0,19v5h8v-2H2V19z M5,6c0-2.2,1.8-4,4-4s4,1.8,4,4s-1.8,4-4,4S5,8.2,5,6z"/>
                </g>
            </svg>

        </>
    )
}

export default AdminIcon