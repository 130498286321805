import * as React from 'react'

import { duration, styled } from '@mui/material/styles'

import Box from '@mui/material/Box'
import { Global } from '@emotion/react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'

const drawerBleeding = 10

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  Open: boolean
  txtHead?: string
  height?: string
  children: React.ReactNode
  changeOpenClose: Function
  windows?: () => Window
  anchor?: 'top' | 'right' | 'left' | 'bottom'
  dir?: 'rtl' | 'ltr'
}

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'var(--light)',
  color: 'var(--infoTxt)'
}))

export default function Drawer (props: Props) {
  const {
    windows,
    Open,
    height,
    children,
    txtHead = '',
    changeOpenClose,
    anchor = 'bottom',
    dir = "rtl"
  } = props

  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: Open ? 'var(--brandLight)' : 'transparent',
    //backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)'
  }))

  // This is used only for the example
  const container =
    windows !== undefined ? () => windows().document.body : undefined

  return (
    <>
      <SwipeableDrawer
        dir={dir}
        sx={{
          height: Open ? height : '0px',
          display: `${Open ? '' : 'none'}`
        }}
        transitionDuration={500}
        container={container}
        anchor={anchor}
        open={Open}
        onClose={() => changeOpenClose()}
        onOpen={() => changeOpenClose()}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -32,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            visibility: 'visible',
            right: 0,
            left: 0
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: 'var(--light)', textAlign: 'center' }}>
            {txtHead ?? ''}
          </Typography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto'
          }}
        >
          {children}
        </StyledBox>
      </SwipeableDrawer>
    </>
  )
}
