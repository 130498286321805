import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../data/store'
import Drawer from '../../../shared/drawer/Drawer'
import { setUserFormMode } from './../../../../data/slice/user/UserForm'
import { useTranslation } from 'next-i18next'
import ButtonBrand from '../../../shared/button/ButtonBrand'
import InputTextWithoutForm from '../../../shared/form/input/InputTextWithoutForm'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import SelectWithoutForm from '../../../shared/form/input/SelectWithoutForm'

interface IProps {
    page: number
    setFilter: Dispatch<SetStateAction<{}>>
    filter: {
      page: number
      confirm: string | undefined
      block: string | undefined
      type: string | undefined
      search_user: string | undefined
    }
    getData: any
  }


const FilterUsers:FC<IProps> = ({filter,getData,page,setFilter}) => {

  const mode = useSelector((state: RootState) => state.UserForm.mode)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [name,setName] = useState<string>("")
  const [userType, setUserType] = useState('all')
  const [userConfirm, setUserConfirm] = useState('all')
  const [userBlock, setUserBlock] = useState('all')

  const optionUserType = [
    { label: t('Common.all'), value: 'all' },
    { label: t('Common.single'), value: 'single' },
    { label: t('Common.wholesale'), value: 'wholesale' }
  ]

  const optionUserConfirm = [
    { label: t('Common.all'), value: 'all' },
    { label: t('Common.confirm'), value: '1' },
    { label: t('Common.reject'), value: '0' }
  ]
  const optionUserBlock = [
    { label: t('Common.all'), value: 'all' },
    { label: t('Common.blocked'), value: '1' },
    { label: t('Common.free'), value: '0' }
  ]

  const handleFilter = async () => {
    await setFilter({
        ...filter, ...{
          search_user: name.length >= 3 ? name : undefined,
          page,
          type: userType === "all" ? undefined : userType,
          block: userBlock === "all" ? undefined : userBlock,
          confirm: userConfirm === "all" ? undefined : userConfirm
        }
      })
    getData()
    dispatch(setUserFormMode('none'))
  }

  return (
    <Drawer
      Open={mode === 'filter' ? true : false}
      txtHead='headersh'
      changeOpenClose={() => dispatch(setUserFormMode('none'))}
      anchor='left'
    >
      <div className='mt-6 p-4 rtl'>
        <p className='text-center font-bold text-2xl mb-10'>
          {t('Common.filter')}
        </p>
        <div className='flex flex-col gap-6'>
          <InputTextWithoutForm
            classContainer='w-full'
            value={name}
            // getValue={(e: any) => dispatch(changeSearchNameAdmin(e))}
            getValue={(e: any) => setName(e)}
            placeholder={t('Common.searchByNameMobile')}
            label={t('Common.searchByNameMobile')}
          />
          <SelectWithoutForm
            classContainer=''
            label={t('Common.selectType')}
            options={optionUserType}
            placeholder={t('Common.selectType')}
            getValue={(e: { label: string; value: string }) => {
              setUserType(e.value)
            }}
          />
          <SelectWithoutForm
            classContainer=''
            label={t('Common.selectStateConfirm')}
            options={optionUserConfirm}
            placeholder={t('Common.selectStateConfirm')}
            getValue={(e: { label: string; value: string }) => {
              setUserConfirm(e.value)
            }}
          />
          <SelectWithoutForm
            classContainer=''
            label={t('Common.selectStateConfirm')}
            options={optionUserBlock}
            placeholder={t('Common.selectStateConfirm')}
            getValue={(e: { label: string; value: string }) => {
              setUserBlock(e.value)
            }}
          />
          <ButtonBrand
            type='button'
            className='w-30'
            click={() => {
              // dispatch(setAdminFormMode('filter'))
              handleFilter()
            }}
          >
            <b> {t('Txt.filter')} </b>
          </ButtonBrand>
        </div>
      </div>
    </Drawer>
  )
}
export default FilterUsers
