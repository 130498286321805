import { useState } from 'react';

import { Loader } from '../../shared/Loader';
import SettingIcon from '../../shared/Icon/Setting';
import Table from '../../shared/listData/Table';

import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import TrashIcon from '../../shared/Icon/Trash';
import { baseUrl } from '../../../config/ApiConf';
import { NullValue } from '../../../utility/Function';
import FormBanner from '../form/Index';
import { useBlogQuery } from '../../../data/services/Blog';
import { setBlogDeleteItem, setBlogFormData, setBlogFormMode } from '../../../data/slice/blog/BlogForm';
import FormBlog from '../form/Index';
import AvatarImage from '../../shared/Html/avatar/AvatarImage';
function ListData() {
    const { t } = useTranslation()
    const [page, setPage] = useState(1);
    
    const { data, isLoading, refetch } = useBlogQuery({ page })
    const dispatch = useDispatch();

    const Headers = [
        { value: <b>#</b>, width: "w-[150px]" },
        { value: <b>{t("Txt.name")}</b> },
        { value: <b>{t("Txt.summery")}</b> },
        { value: <b>{t("Txt.actions")}</b>, width: "w-[380px]" },

    ];

    const Rows = data?.data?.data.map((item) => {
        return {
            row: [
                { value: <div className='ms-1'>{item.id}</div>, width: "w-[150px]" },
                
                
                { value: <div className='flexRow items-center gap-2 '>
                    <AvatarImage height='h-[50px]' width='w-[50px]' img={baseUrl + item.pathFile} />
                    <b className='truncate'>{item.name}</b>
                  </div> },
                { value: <div className='ms-1'>{item.summery}</div> },
              
                {
                    value: <div className='flexRow items-center'>
                        <div className='cursor-pointer' onClick={() => {
                            dispatch(setBlogFormMode("edit"));
                            dispatch(setBlogFormData(item));
                        }}>
                            <SettingIcon fill="var(--secondary)" size='30px' />
                        </div>

                        <div className='cursor-pointer' onClick={() => {
                            dispatch(setBlogFormMode("delete"));
                            dispatch(setBlogDeleteItem(item));
                        }}>
                            <TrashIcon fill="var(--error)" size='30px' />
                        </div>

                    </div>, width: "w-[380px]"
                }


            ]
        }
    })



    return (
        <>
            <div className='  py-4' >
                {isLoading && <div className='flexRow justify-center w-full'><Loader /></div>}
                {!isLoading && <Table
                    currentPage={+(data?.data.current_page ?? 1) - 1}
                    total={+(data?.data.total ?? 0)}
                    rowsPerPage={+(data?.data.per_page ?? 0)}
                    header={Headers} rows={Rows ?? []}
                    handleChangePage={(params1: any, params2: number) => setPage(params2 + 1)}
                    onRowsPerPageChange={(params1: any, params2: any) => console.log(params1, params2)}
                />}
            </div>

            <FormBlog />


        </>
    )
}

export default ListData