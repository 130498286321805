import { useTranslation } from 'next-i18next'
import React from 'react'
import { FormComponentProps } from '../../../../../../interface/Element';
import InputTxt from '../../../../../shared/form/input/InputTxt';
import NumberInput from '../../../../../shared/form/input/NumberInput';
import SelectTypeShow from '../../../../../product/actions/SelectTypeShow';
import FileInput from '../../../../../shared/form/upload/FileInput';
import { ImgUploadType } from '../../../../../../config/App';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../data/store';

function FormItem({ control, getValues, setValue, errors }: FormComponentProps) {
  const Data = useSelector((state: RootState) => state.PageForm)
  const {t}=useTranslation();

  return (
    <div className='flexCol flex-wrap w-full'>
      <div className='w-full'>
        <InputTxt
          control={control}
          errors={errors}
          keyValue='title'
          label={t("Common.name")}
          name='title'
          placeholder={t("Common.name")} />
      </div>
      <div className='w-full'>
        <SelectTypeShow control={control} errors={errors} name='user_type_show' />
      </div>
      <div className='w-full'>
        <NumberInput
          control={control}
          errors={errors}
          keyValue='priority'
          label={t("Common.priority")}
          name='priority'
          placeholder={t("Common.priority")} />
      </div>
      <div className='w-full'>
        <InputTxt
          control={control}
          errors={errors}
          keyValue='link'
          label={t("Common.link")}
          name='link'
          placeholder={t("Common.link")} />
      </div>
    
    {/* {Data?.data?.id ===1 &&   */}
    <div className='w-[100%]'>
                    <FileInput
                        changeValue={(file: File) => console.log(file)}
                        getValues={getValues}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        name="image"
                        accept={ImgUploadType}
                        title={t("Txt.chooseImg")} />
                </div>
                {/* } */}


    </div>

  )
}

export default FormItem