import { useDispatch, useSelector } from 'react-redux'
import Drawer from '../../../../shared/drawer/Drawer'
import { RootState } from '../../../../../data/store'
import { setOrderFormMode } from '../../../../../data/slice/order/OrderForm'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import InputTextWithoutForm from '../../../../shared/form/input/InputTextWithoutForm'
import SelectWithoutForm from '../../../../shared/form/input/SelectWithoutForm'
import { useOrderAllStatusQuery } from '../../../../../data/services/Order'
import { SelectOption } from '../../../../../interface/Element'
import ButtonBrand from '../../../../shared/button/ButtonBrand'

interface IProps {
  page: number
  setFilter: Dispatch<SetStateAction<{}>>
  filter: {
    page: number
    confirm: string | undefined
    block: string | undefined
    type: string | undefined
    search_user: string | undefined
  }
  getData: any
}

const FilterOrders: FC<IProps> = ({ filter, getData, page, setFilter }) => {
  const mode = useSelector((state: RootState) => state.OrderForm.mode)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const { data, isLoading } = useOrderAllStatusQuery()
  const [options, setOptions] = useState<{ label: string; value: string }[]>([])
  const [orderStatus, setOrderStatus] = useState('all')


  
  const handleFilter = async () => {
    await setFilter({
      ...filter,
      ...{
        name: name.length >= 3 ? name : undefined,
        page,
        order_status_id: orderStatus === 'all' ? undefined : orderStatus,
      }
    })
    getData()
    dispatch(setOrderFormMode('none'))
  }


  useEffect(() => {
    if (data?.data) {
      const newOptions = data.data.map(item => {
        return {
          label: item?.name,
          value: item.id.toString()
        }
      })
      setOptions(newOptions)
    }
  }, [data])

  return (
    <Drawer
      Open={mode === 'filter' ? true : false}
      txtHead='headersh'
      changeOpenClose={() => dispatch(setOrderFormMode('none'))}
      anchor='left'
    >
      <div className='mt-6 p-4 rtl'>
        <p className='text-center font-bold text-2xl mb-10'>
          {t('Common.filter')}
        </p>
        <div className='flex flex-col gap-6'>
          <InputTextWithoutForm
            classContainer=''
            getValue={(e: any) => setName(e)}
            placeholder={t('Common.name')}
            label={t('Common.name')}
          />
          <SelectWithoutForm
            label={t('Common.status')}
            options={[{ label: t('Common.all'), value: 'all' }, ...options]}
            placeholder={t('Common.status')}
            getValue={(e: { label: string; value: string }) =>
              setOrderStatus(e.value)
            }
          />
           <ButtonBrand
            className='w-30'
            click={() => {
              handleFilter()            }}
          >
            <b> {t('Txt.filter')} </b>
          </ButtonBrand>
        </div>
      </div>
    </Drawer>
  )
}

export default FilterOrders
