import { useAboutDataQuery, useAboutUpdateMutation } from '../../data/services/About'
import { useEffect, useState } from 'react';

import ButtonBrand from '../../components/shared/button/ButtonBrand';
import CkComponent from '../../components/shared/ck';
import { Loader } from '../../components/shared/Loader';
import { useTranslation } from 'next-i18next';
import { ToastSuccess } from '../../utility/ToastFunction';

export default function AboutPage() {

  const { t } = useTranslation();
  const { data, isLoading, refetch } = useAboutDataQuery();
  const [txt, setTxt] = useState(data?.data.text);
  const [update, result] = useAboutUpdateMutation()

  const submitHandler = () => {
    update({ text: txt ?? "" });
    
  }

  useEffect(()=>{
    if(result.isSuccess){
      refetch()
      ToastSuccess(t("Common.successAlarm"))

    }

  },[result])

  useEffect(()=>{
    if(data?.data.text)
      setTxt(data?.data.text)

  },[data])

  return (
    <>
      <div className="flexCol gap-4 pageLayout">

        {isLoading && <div className='flex justify-center w-full'><Loader /></div>}
        {!isLoading && <CkComponent txt={data?.data.text ?? ""} changeFun={setTxt} />}

        <div className='flex justify-center'>
          <ButtonBrand loading={result.isLoading} click={() => submitHandler()}>
            {t("Common.saveButton")}
          </ButtonBrand>

        </div>

      </div>
    </>
  )
}
