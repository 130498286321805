import { useEffect, useState } from 'react'

import ArrowDown from '../../components/shared/Icon/ArrowDown'
import ArrowUp from '../../components/shared/Icon/ArrowUp'
import { MenuItemsProps } from '../../interface/Element'
import { useTranslation } from 'next-i18next'
import { Link, useLocation } from 'react-router-dom'
import { setClickMenu } from '../../data/slice/sidebar/Sidebar'
import { useDispatch } from 'react-redux'

const MenuItems = ({
  icon,
  title,
  children,
  keys,
}: MenuItemsProps) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const url = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (url.pathname.includes(keys)) setOpen(true)
  }, [url, keys])

  return (
    <>
      <div
        className={`rounded-[--radius] flex flex-col transition-all duration-300 gap-y-[12px] ${open ? ' border  text-[--light] p-2' : ''
          } `}
      >
        <div
          onClick={() => {
            setOpen(prevOpen => !prevOpen)
          }}
          className={`flex flex-row gap-x-[12px] min-h-[48px] transition-all duration-300 px-2 items-center cursor-pointer  rounded-[--radius] hover:bg-[--brandLight] ${open ? 'bg-[--brandLight]' : 'ps-[10px]'
            }`}
        >
          {icon}
          <span className='flex-grow  '>{title}</span>
          {open ? (
            <ArrowUp fill='var(--light)' />
          ) : (
            <ArrowDown fill='var(--murkyLight)' />
          )}
        </div>

        {children.map(item => (
          <div
            className={`text-[--secondary] ps-[36px] rounded-[--radius] hover:bg-[--brandLight] min-h-[38px] transition-all duration-300 flex items-center hover:text-[--light] cursor-pointer ${open ? '' : 'hidden'
              }`}
          >
            <Link
              to={item.url}
              onClick={() => {
                dispatch(setClickMenu(true))
              }}
            >
              {' '}
              <b
                className={`${url.pathname == item.url ? 'text-[--brand]' : ''
                  }`}
              >
                {t(`Menu.${item.name}`)}
              </b>
            </Link>
          </div>
        ))}
      </div>
    </>
  )
}

export default MenuItems
