import { FC } from 'react'
import {
  TablePropsData,
  TableResponsiveProps
} from '../../../interface/ListData'
import List from './List'
import Table from './Table'

const ListData: FC<TableResponsiveProps> = ({
  // Rows,
  setPage,
  header,
  rows,
  currentPage,
  handleChangePage,
  onRowsPerPageChange,
  rowsPerPage,
  total,
  showPage = true
}) => {
  const width = window.innerWidth

  return (
    <>
      {width > 750 ? (
        <Table
          currentPage={currentPage}
          total={total}
          rowsPerPage={rowsPerPage}
          header={header}
          rows={rows ?? []}
          handleChangePage={(params1: any, params2: number) =>{
            // let newPage = params2+1
            // console.log("params2 is : ",params2)
            setPage((params2 + 1))
            // handleChangePage(newPage ? newPage : params2+1)
            // setPage(newPage)

          }
          }
          onRowsPerPageChange={(params1: any, params2: any) =>
            console.log(params1, params2)
          }
        />
      ) : (
        <List rows={rows} header={header} currentPage={currentPage} total={total} rowsPerPage={rowsPerPage}  handleChangePage={(params1: any, params2: number) =>
          setPage(params2 + 1)
        } onRowsPerPageChange={onRowsPerPageChange} />
      )}
    </>
  )
}

export default ListData
