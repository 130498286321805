import { BannerList,BannerAddPropsApi,BannerRemovePropsApi, BannerUpdatePropsApi } from "../../interface/EntityBanner";

import { ChangeToFormData } from "../../utility/Function";
import {  BANNER_GET_PATH,BANNER_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const BannerApi = createApi({
    reducerPath: 'BannerApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["banner"],

    endpoints: (builder) => ({
        Banner: builder.query<BannerList, void>({
            query: () => ({
                url: BANNER_GET_PATH,
                method: 'GET',
            }),
            providesTags: ['banner'],
        }),

        BannerAdd: builder.mutation<any, BannerAddPropsApi>({
            query: (body) => ({
                url: BANNER_PATH,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['banner'],
        }),

        BannerEdit: builder.mutation<void, BannerUpdatePropsApi>({
            query: ({ id, ...res }) => ({
                url: BANNER_PATH + "/" + id,
                method: 'POST',
                body: ChangeToFormData({...res,...{"_method":"PUT"}})
           
            }),
            invalidatesTags: ['banner'],
        }),

        BannerRemove: builder.mutation<void, BannerRemovePropsApi>({
            query: (body) => ({
                url: BANNER_PATH + "/" + body.id,
                method: 'DELETE',

            }),
            invalidatesTags: ['banner'],
        }),



    }),



})

export const {
    useBannerQuery,
    useBannerAddMutation,
    useBannerEditMutation,
    useBannerRemoveMutation
} = BannerApi


