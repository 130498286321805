import { TablePagination } from "@mui/material";
import { CardListTable } from "../../../interface/ListData";
import { useTranslation } from "next-i18next";

const List=({header,rows,currentPage,total,rowsPerPage,handleChangePage,onRowsPerPageChange}:CardListTable)=>{

  const {t} = useTranslation()

    return <>
          <div className='md:hidden w-full flex flex-col gap-8'>
        {rows.map((row) => (
          <div className='w-full rounded-lg shadow p-4'>
            <div className='w-full flex flex-col gap-4'>
              {header.map((item,index) => (
                <div className='flex items-center gap-4'>{item.value } : {row.row[index]?.value}</div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <TablePagination
          sx={{ direction: 'initial' }}
          labelRowsPerPage={t('Common.labelRowsPerPage')}
          labelDisplayedRows={({ from, to, count }) =>
            `${t('Common.displayingRecord')} ${from}-${to} ${t(
              'Common.ofTotal'
            )} ${count}`
          }
          component='div'
          count={total ?? 0}
          page={currentPage ?? 1}
          onPageChange={(event: React.MouseEvent | null, page: number) =>
            handleChangePage ? handleChangePage(event, page) : () => {}
          }
          rowsPerPage={rowsPerPage ?? 0}
          rowsPerPageOptions={[rowsPerPage ?? 0]}
          onRowsPerPageChange={(event: React.ChangeEvent) =>
            onRowsPerPageChange ? onRowsPerPageChange(event) : () => {}
          }
        />
      </>
}

export default List;
