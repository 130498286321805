
import { ChangeToFormData, ChangeToQueryString } from "../../utility/Function";
import { PAGE_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'
import { PageAddPropsApi,  PageList,  PageListFilterApi, PageRemovePropsApi, PageUpdatePropsApi } from "../../interface/EntityPage";

export const PageApi = createApi({
    reducerPath: 'PageApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Page"],

    endpoints: (builder) => ({
        Page: builder.query<PageList, PageListFilterApi>({
            query: (body) => ({
                url: PAGE_PATH+ChangeToQueryString(body),
                method: 'GET',

            })
            ,
            providesTags: ['Page'],
        }),


        PageAdd: builder.mutation<any, PageAddPropsApi>({
            query: (body) => ({
                url: PAGE_PATH,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Page'],
        }),

        PageEdit: builder.mutation<void, PageUpdatePropsApi>({
            query: ({ id, ...res }) => ({
                url: PAGE_PATH + "/" + id,
                method: 'PUT',

                body: (res)
            }),
            invalidatesTags: ['Page'],
        }),

        PageRemove: builder.mutation<void, PageRemovePropsApi>({
            query: (body) => ({
                url: PAGE_PATH + "/" + body.id,
                method: 'DELETE',

            }),
            invalidatesTags: ['Page'],
        }),



    }),



})

export const {
    usePageQuery,
    usePageAddMutation,
    usePageEditMutation,
    usePageRemoveMutation
} = PageApi


