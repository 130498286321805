import * as Yup from "yup"

import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'



import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from "next-i18next"
import ModalFull from "../../../../shared/modal/ModalFull"
import FormItem from "../FormItem"
import ButtonBrand from "../../../../shared/button/ButtonBrand"
import { ToastError, ToastSuccess } from "../../../../../utility/ToastFunction"
import PageMarginBottom from "../../../../shared/Html/PageMarginBottom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../../data/store"
import { setSliderFormMode } from "../../../../../data/slice/slider/SliderForm"
import { FaqAddPropsApi } from "../../../../../interface/EntityFaq"
import { useFaqAddMutation } from "../../../../../data/services/Faq"

export default function AddFaq() {

    const { t } = useTranslation();
    const DataMod = useSelector((state: RootState) => state.FaqForm);
    const [OpenAdd, setOpenAdd] = useState(false);
    const [Add, results] = useFaqAddMutation()
    const dispatch = useDispatch()
    const Schema = Yup.object().shape({
        title: Yup.string().required(t("Validation.require")),
        text: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<FaqAddPropsApi> = (data) => {

        if (data?.title !== undefined) {
            Add(data);
        }
    }

    useEffect(() => {
        if (DataMod.mode === 'add') {
            setOpenAdd(true);
            reset();
        } else {
            setOpenAdd(false);
        }
    }, [DataMod.mode, reset])

    useEffect(() => {
        if (results.isSuccess) {
            ToastSuccess(t("Common.successAlarm"))
            setOpenAdd(false);
            dispatch(setSliderFormMode("none"));

        }
        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    return (
        <>

            <ModalFull
                ChangeOpen={
                    (open: boolean) => {
                        setOpenAdd(open);
                        dispatch(setSliderFormMode(open ? "add" : "none"));
                    }
                }
                Open={OpenAdd} title={t("Txt.addFaq")}>
                <div className="FormModal !h-auto">
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />

                        <div className='w-full flex justify-center mt-10'>
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>

                    <PageMarginBottom />


                </div>

            </ModalFull>
        </>
    )
}

