import { yupResolver } from '@hookform/resolvers/yup';
import { usePageQuery } from '../../../../data/services/Page'
import { ProductDetailData } from '../../../../interface/EntityProduct'
import { Loader } from '../../../shared/Loader';
import SectionsPAge from './sections';
import { SubmitHandler, useForm } from 'react-hook-form';
import GrayLine from '../../../shared/Html/GrayLine';
import ButtonBrand from '../../../shared/button/ButtonBrand';
import { useTranslation } from 'next-i18next';
import { useProductSectionQuery, useProductSectionUpdateMutation } from '../../../../data/services/Product';
import { useEffect } from 'react';
import { useSectionQuery } from '../../../../data/services/Section';

export default function ShowIn({ ...data }: ProductDetailData) {

  const { t } = useTranslation()
  const { data: DataPage, isLoading } = usePageQuery({ page: 1 });
  const [updateSection, results] = useProductSectionUpdateMutation();
  const {data:ProductSection}=useProductSectionQuery({id:data.id})
  const { data:SectionsData } = useSectionQuery({ page: 1 })

  const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
    //resolver: yupResolver(Schema)
  });

  const OnFinish: SubmitHandler<any> = (params) => {
    if (params !== null && params !== undefined) {
      let keys = Object.keys(params);
      let sections = keys.filter((item) => params[item])
      updateSection({ product_id: data.id, sections })
    }

  }

  useEffect(() => {
    if (ProductSection?.data) {
      let values: any = {};
      SectionsData?.data.forEach((item) => values[item.id] = ProductSection.data.findIndex((data) => data.section_id === item.id) < 0 ? false : true)

      reset(values);
    }
  }, [ProductSection,SectionsData])

  return (
    <>
      {isLoading && <div className='flexRow justify-center items-center'> <Loader /></div>}
      {!isLoading &&
        <div className='FormModal flexCol gap-4'>
          <form onSubmit={handleSubmit((values: any) => OnFinish(values))}  >
            {
              DataPage?.data.map((item) => <>
                <div className='flexRow justify-between gap-6 p-4'>
                  <strong>{item.name}</strong>
                  <SectionsPAge page_id={item.id} product_id={data.id} control={control} getValues={getValues} errors={errors} />
                </div>


                <GrayLine />

              </>)
            }
            <div className='my-4'>
              <ButtonBrand click={OnFinish} loading={results.isLoading} type='submit'>
                {t("Common.saveButton")}
              </ButtonBrand>
            </div>
          </form>


        </div>}
    </>
  )
}
