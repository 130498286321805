import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, FaqDataType } from "../../../interface/EntityFaq";
import { PermissionData } from "../../../interface/EntityPermission";

const initialState: {
    mode: ActionMode,
    data: FaqDataType | null,
    deleteItem:FaqDataType|null,
    permissions:PermissionData[]
    
} = {
    mode: "none",
    data: null,
    deleteItem:null,
    permissions:[]

}


export const FaqForm = createSlice({
    name: 'FaqForm',
    initialState,
    reducers: {
        setFaqFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },

        emptyFaqFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'none'
            return newState;
        },

        setFaqFormData: (state, action: { payload: FaqDataType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },

        setFaqPermissionsFormData: (state, action: { payload: PermissionData[] }) => {
            let newState = state;
            newState['permissions'] = action.payload
            return newState;
        },

        emptyFaqFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },
        emptyFaqPermissionsFormData:(state)=>{
            let newState=state
            newState['permissions']=[];
            return newState;
        },


        setFaqDeleteItem: (state, action: { payload: FaqDataType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptyFaqDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setFaqFormMode,
    emptyFaqFormMode,
    setFaqFormData,
    emptyFaqFormData,
    setFaqDeleteItem,
    emptyFaqDeleteItem
    
} = FaqForm.actions

export default FaqForm.reducer