import Header from "../../components/order/header";
import ListData from "../../components/order/list";
import FilterOrder from "../../components/order/filter";

export default function OrdersPage() {
  return (
    <>
      <div className="flexCol gap-4 pageLayout">
   
        <Header />
        <FilterOrder/>
        <ListData/>
      

    

      </div>
    </>
  );
}
