import { useEffect, useState } from 'react';
import { useProductQuery, useProductRemoveMutation } from '../../data/services/Product';
import AvatarImage from '../shared/Html/avatar/AvatarImage';
import DetailProduct from './detail/Index';
import { Loader } from '../shared/Loader';
import ProductActions from './actions/Index';
import { ProductData } from '../../interface/EntityProduct';
import RemoveDialog from '../shared/dialog/RemoveDialog';
import { baseUrl } from '../../config/ApiConf';
import { getAttachUrlProduct } from '../../utility/Function';


// icons
import SettingIcon from '../shared/Icon/Setting';
import TrashIcon from '../shared/Icon/Trash';
import EyeIcon from '../shared/Icon/Eye';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
// i18n
import { useTranslation } from 'next-i18next';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../data/store';

// table
import ListDataTable from './../shared/listData/index';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

function ListData() {
  const { searchName } = useSelector((state: RootState) => state.ProductForm.filter)

  const { t } = useTranslation()
  const [page, setPage] = useState(1);
  const [name, setName] = useState("")
  const [removeItem, setRemoveItem] = useState<ProductData | null>(null);
  const [RemoveProduct, results] = useProductRemoveMutation()

  const { data, isLoading, refetch } = useProductQuery({ page, name: searchName })
  const [id, setId] = useState(0);
  const [Open, setOpen] = useState(false);
  const [OpenAction, setOpenAction] = useState(false);
  const [ModalDeleteShow, setModalDeleteShow] = useState(false);

  const RemoveRecord = () => {
    if (removeItem !== null)
      RemoveProduct({ id: removeItem.id })
  }

  useEffect(() => {
    if (results.isSuccess) {
      setModalDeleteShow(false);
      setRemoveItem(null);

    }

  }, [results])
  const Headers = [
    { value: <b>#</b>, width: "w-[150px]" },
    { value: <b >{t("Txt.name")}</b> },
    // { value: <b>{t("Txt.code")}</b> },
    { value: <b>{t("Txt.price")}</b> },
    { value: <b>{t("Txt.description")}</b> },
    { value: <b>{t("Txt.actions")}</b>, width: "w-[520px]" },

  ];
  useEffect(() => {
    if (searchName.length > 1) {
      refetch()
    }
  }, [searchName])
  const Rows = data?.data.data?.map((item) => {
    return {
      row: [
        { value: <div className='ms-1'>{item.id}</div>, width: "w-[150px]" },
        {
          value: <div className='flexRow items-center  gap-2  '>
            <AvatarImage height='h-[50px]' width='w-[50px]' img={getAttachUrlProduct(item.gallery, "thumb").length > 0 ? `${baseUrl}${getAttachUrlProduct(item.gallery, "thumb")[0]?.pathFile} ` : undefined} />
            <b className='truncate'>{item.name}</b>
          </div>
        },
        // { value: <div>{item.code}</div> },
        { value: <div >{item.price}</div> },
        { value: <div>{item.description.slice(0, 20) + "..."}</div> },
        {
          value: <div className='flexRow items-center w-[700px] gap-2 '>
            <div className='cursor-pointer' onClick={() => { setOpen(true); setId(item.id); }}><EyeIcon fill='var(--secondary)' size='30px' /></div>
            <div className='cursor-pointer' onClick={() => { setOpenAction(true); setId(item.id) }}><SettingIcon fill="var(--secondary)" size='30px' /></div>
            <div className='cursor-pointer' onClick={() => { setModalDeleteShow(true); setRemoveItem(item) }}><TrashIcon fill="var(--error)" size='30px' /></div>
            <div className='cursor-pointer' onClick={() => { setModalDeleteShow(true); setRemoveItem(item) }}>
              <Link to={`/review/${item.id}`}>
                <Tooltip title="نقد و بررسی">
                  <MarkUnreadChatAltIcon sx={{ color: "var(--brandLight)" }} />
                </Tooltip>
              </Link>

            </div>
          </div>, width: "w-[520px]"
        }
      ]
    }
  })
  return (
    <>
      <div className='  py-4' >
        {isLoading && <div className='flexRow justify-center w-full'><Loader /></div>}
        {!isLoading &&
          <ListDataTable
            header={Headers}
            setPage={setPage}
            rows={Rows ?? []}
            currentPage={+(data?.data.current_page ?? 1) - 1}
            rowsPerPage={+(data?.data.per_page ?? 0)}
            total={+(data?.data.total ?? 0)}
            handleChangePage={(params1: any, params2: number) =>
              setPage(params2 + 1)
            }
            onRowsPerPageChange={(params1: any, params2: any) =>
              console.log(params1, params2)
            }
          />
        }
      </div>
      <DetailProduct openModal={Open} id={id} ChangeOpen={setOpen} />
      <ProductActions openModal={OpenAction} id={id} ChangeOpen={setOpenAction} />
      {ModalDeleteShow && <RemoveDialog
        open={ModalDeleteShow}
        DeleteFunction={() => RemoveRecord()}
        CloseModal={() => setModalDeleteShow(false)}
        cancelTitle={t("Common.cancel")}
        deleteAgreeTitle={""}
        deleteTitle={t("Common.delete")}
        loadingConfirmButton={results.isLoading}
        title={t("Txt.removeItem") + removeItem?.name}
      />}

    </>
  )
}

export default ListData