import React, { useState } from 'react'

import ButtonBrand from '../../shared/button/ButtonBrand';
import PlusIcon from '../../shared/Icon/Plus';
import { useTranslation } from 'next-i18next';
import AddRole from '../form/actions/add';

function Header() {
    const [OpenAdd, setOpenAdd] = useState(false);
    const { t } = useTranslation()


    return (
        <>
            <h1 className="text-2xl">{t("Menu.roles")}</h1>
            <div className="w-60">
                <ButtonBrand click={() => { setOpenAdd(true) }} >
                    <div className="flexRow items-center gap-2">
                        <span><PlusIcon fill="var(--light)" /></span>
                        <b> {t("Txt.addRole")} </b>
                    </div>
                </ButtonBrand>
            </div>

            {<AddRole openModal={OpenAdd} ChangeOpen={setOpenAdd} />}


        </>
    )
}

export default Header