import Header from "../../components/discounts/header";
import ListData from "../../components/discounts/list/index";

export default function DiscountPage() {
    return (
        <>
            <div className="flexCol gap-4 pageLayout">
                <Header />
                <ListData />

            </div>
        </>
    );
}
