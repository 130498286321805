import { useEffect, useRef, useState } from 'react';

import { Controller } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import FileIcon from './img/file-alt.svg';
import { FileUpload } from './interface'
import UploadP from './img/upload.svg'

const FileInputMulti = ({ accept, title, changeValue, name, control, errors, setValue, getValues, emptyFile = false, isMulti = false, reset }: FileUpload) => {
    const Refs = useRef<any>(null);
    const [fileName, setFileName] = useState("");
    const [imgSrc, setImgSrc] = useState<string[]>([]);

    const SetImageValue = (file: File) => {
        let reader: any = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function (e: any) {
            setImgSrc(imgSrc => [...imgSrc, reader.result])


        };
    }

    const changeV = (file: any, fn: Function) => {
        if (file === undefined)
            return;

        for (let i = 0; i < file.length; i++) {

            if (file[i].type.includes("image")) {
                SetImageValue(file[i])

            }

            if (changeValue !== undefined) {
                changeValue(file[i])
            }

        }
        // if (setValue !== undefined) {
        //     console.log('run setValue')
        //     setValue(name, file)

        // }

        fn(file)

    }

    const RemoveFile = (index: number) => {
        

        if (Refs.current !== null) {
            setImgSrc([]);
            setFileName("")
            let files = (Refs.current.files)
            let newFiles=new DataTransfer()
            for(let i=0;i<files.length;i++){
                if(i!==index){
                    newFiles.items.add(files[i]);
                    SetImageValue(files[i])
                }
            }
            Refs.current.files = newFiles.files;

            // Refs.current.value = ''
            // setFileName("")
            // setImgSrc([])
             if (reset)
                reset(name);
        }
    }

    useEffect(() => {
        
        if (Refs.current !== null) {
            setImgSrc([]);
            setFileName("")
            let newFiles=new DataTransfer()
            Refs.current.files = newFiles.files;
             if (reset)
                reset(name);
        }
    }, [emptyFile])

    useEffect(() => {
        let value = getValues(name)
        if (value !== undefined && value !== "" && typeof value!=='string') {
            setFileName(value.name)
            SetImageValue(value)

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues(name ?? "files")])

    return (
        <div className='flex flex-col gap-1'>
            <div className='bg-[--light]  rounded-xl p-3 w-full h-[250px]'>
                <div className="w-full h-full  rounded-xl border border-dashed border-[--brandLight] p-2 relative flexCol gap-2 text-[--brandLight] justify-center items-center overflow-y-auto">
                    <div className='flexRow gap-2 w-full h-full flex-wrap'>

                        {imgSrc.length === 0 && <div className="w-full h-full  flex flex-col gap-2 items-center ">
                            <img className='w-12' src={UploadP} alt="" />
                            <b>{title}</b>
                            <small> {accept} </small>

                        </div>}
                        {imgSrc.length > 0 && <>
                            {imgSrc.map((item, index) => <div className="w-auto h-full p-4  flex flex-col justify-center  text-[--brandLight] items-center">
                                <img src={item} className='w-[120px] h-[120px] rounded-lg object-cover  ' alt='' />
                                <div className='mb-3 z-30 text-xs' onClick={() => RemoveFile(index)}><DeleteIcon /></div>

                            </div>)}
                        </>

                        }
                    </div>
                    <Controller
                        control={control}

                        render={({ field: { onChange, onBlur, value } }) => (

                            <div className='w-full h-full z-10' >
                                <input
                                    ref={Refs}
                                    className='w-full h-full'
                                    type="file"
                                    multiple={isMulti}
                                    accept={fileName !== "" ? fileName : accept}
                                    onChange={(event: any) => { changeV(event.target.files, onChange) }} />
                            </div>

                        )}

                        name={name} />


                </div>
            </div>
            <div>
                <p className='text-red-400 font-bold text-sm mx-2 my-4'>{errors[name]?.message}</p>

            </div>
        </div>
    )
}

export default FileInputMulti