import * as React from 'react';


import { ThemeProvider, createTheme } from '@mui/material/styles';

import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalaliV3';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { faIR } from '@mui/x-date-pickers/locales/faIR';
import useTheme from '@mui/system/useTheme';
import { PickersLocaleText } from '@mui/x-date-pickers';
import { InputTDateType } from './interface';
import useRtlDetermine from '../../hook/RtlDetermine';

export default function DateP({
  label,
  control,
  rules,
  name,
  errors,
  minDate,
  getValues,
  maxDate
}: InputTDateType) {
  const existingTheme = useTheme();
  const dir = useRtlDetermine();
  const theme = React.useMemo(
    () => createTheme({ direction: dir }, existingTheme),
    [existingTheme]
  );
  const customPtBRLocaleText: Partial<PickersLocaleText<any>> = {
    okButtonLabel: "تایید",
    cancelButtonLabel: "لغو",
  
    
  };
  return (
    <>
      <Controller
        control={control}
        // rules={rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <ThemeProvider theme={theme}>
            <div dir={dir}>
              <label htmlFor="" className={"LabelClass"}>{label}</label>

              <LocalizationProvider dateAdapter={AdapterDateFnsJalali}
                localeText={{...faIR.components.MuiLocalizationProvider.defaultProps.localeText,...customPtBRLocaleText}}

              >
                <DatePicker 
                label="" 
              
                value={new Date(value)??new Date()} 
                className="InputClass w-full" 
                onChange={(value:any)=>{
                  onChange( value?.toLocaleDateString("en-CA",
                  // onChange( value?.toLocaleTimeString("en-CA",
                    // onChange( value?.toLocaleString("en-CA", { hour12: false}

                  //   {
                  //   year: 'numeric',
                  //   month: 'numeric',
                  //   day: 'numeric',
                  // }
                ));
                }}
                minDate={minDate} 
                maxDate={maxDate} />
              </LocalizationProvider>
            </div>
          </ThemeProvider>

        )}

        name={name} />


      <div>
        <p className='text-red-400 font-bold text-sm m-2'>{errors[name]?.message}</p>

      </div>
    </>
  );
}