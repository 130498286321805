import React from 'react'
import DialogComponent from '../drawer/Dialog'
import GrayLine from '../Html/GrayLine'
import ButtonDanger from '../button/ButtonDanger'
import ButtonBrandOuter from '../button/ButtonBrandOuter'
import { title } from 'process'

interface RemoveDialogProps{
    open:boolean,
    CloseModal:Function,
    DeleteFunction:Function,
    title:string,
    deleteAgreeTitle:string,
    loadingConfirmButton:boolean,
    deleteTitle:string,
    cancelTitle:string,

}

function RemoveDialog({open,CloseModal,DeleteFunction,title,deleteAgreeTitle,cancelTitle,deleteTitle,loadingConfirmButton}:RemoveDialogProps) {
  return (
    <DialogComponent Open={open} handleCloseFun={CloseModal}>
    <div className="flexCol gap-4 rounded-[--radius]">
        <div className="text-lg font-bold px-8 py-2">{title}</div>
        <GrayLine />
        <div className='px-8 py-2'>
            <p>{deleteAgreeTitle}</p>
        </div>

        <div className="flexRow gap-2 px-8 py-2">
            <ButtonDanger loading={loadingConfirmButton} click={ DeleteFunction}>
                <b>{deleteTitle}</b>
            </ButtonDanger>
            <ButtonBrandOuter click={() => { CloseModal() }}>
                <b>{cancelTitle}</b>
            </ButtonBrandOuter>
        </div>

    </div>
</DialogComponent>
  )
}

export default RemoveDialog