import { FormProps } from '../../../../../interface/Element'
import InputColor from '../../../../shared/form/input/Color'
import InputTxt from '../../../../shared/form/input/InputTxt'
import NumberInput from '../../../../shared/form/input/NumberInput'
import React from 'react'
import { useTranslation } from 'next-i18next'
import ColorSelect from '../color'
import SizeSelect from '../size'

export default function FormItem ({control,errors}:FormProps) {
    const {t}=useTranslation();
  return (
    <>
    <div className="flexRow gap-4 justify-between flex-wrap">
                        <div className="w-[200px]">
                            <SizeSelect control={control} name='size' errors={errors} />
                            {/* <InputTxt keyValue='size' label={t("Txt.size")} name='size' control={control} errors={errors} /> */}
                        </div>
                        <div className="w-[200px]">
                            <NumberInput keyValue='amount' label={t("Txt.amount")} name='amount' control={control} errors={errors} />
                        </div>
                        <div className="w-[200px]">
                           <ColorSelect  name='color' control={control} errors={errors}/>
                            {/* <InputColor keyValue='color' label={t("Txt.color")} name='color' control={control} errors={errors} /> */}
                        </div>
                    </div>
                    </>
  )
}
