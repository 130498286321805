import { ButtonProps, ButtonPropsIcon } from "../../../interface/Element"

const ButtonOuterBrandIcon = ({ click, children,height='h-10',width='w-10' }: ButtonPropsIcon) => {
  return (
    <>
      <button onClick={() => click()} className={`${width} ${height} rounded-full border border-[--brand] hover:bg-[--brand] flex justify-center items-center`}>
        {children}
      </button>
    </>
  )
}

export default ButtonOuterBrandIcon