import { useDispatch, useSelector } from 'react-redux'
import Drawer from '../../../../shared/drawer/Drawer'
import {
  setDiscountFormMode
} from '../../../../../data/slice/discount/DiscountForm'
import { useTranslation } from 'next-i18next'
import ButtonBrand from '../../../../shared/button/ButtonBrand'
import InputTextWithoutForm from '../../../../shared/form/input/InputTextWithoutForm'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import SelectWithoutForm from '../../../../shared/form/input/SelectWithoutForm'
import { RootState } from '../../../../../data/store'

interface IProps {
  page: number
  setFilter: Dispatch<SetStateAction<{}>>
  filter: {
    page: number
    confirm: string | undefined
    block: string | undefined
    type: string | undefined
    search_user: string | undefined
  }
  getData: any
}

const FilterDiscount: FC<IProps> = ({ filter, getData, page, setFilter }) => {
  const mode = useSelector((state: RootState) => state.DiscountForm.mode)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const optionDiscountState = [
    { label: t('Common.all'), value: 'all' },
    { label: t('Common.deActive'), value: '0' },
    { label: t('Common.active'), value: '1' }
  ]

  const optionDiscountType = [
    { label: t('Common.all'), value: 'all' },
    { label: t('Common.general'), value: 'general' },
    { label: t('Common.users'), value: 'users' }
  ]

  const optionDiscountReductionType = [
    { label: t('Common.all'), value: 'all' },
    { label: t('Common.percent'), value: 'percent' },
    { label: t('Common.amount'), value: 'amount' }
  ]
  const [discountType, setDiscounType] = useState('all')
  const [discountStatus, setDiscounStatus] = useState('all')
  const [discountReductionType, setDiscounReductionType] = useState('all')

  const handleFilter = async () => {
    console.log("enter filter : ",filter)
    await setFilter({
      ...filter,
      ...{
        name: name.length >= 3 ? name : undefined,
        page,
        type: discountType === 'all' ? undefined : discountType,
        active: discountStatus === 'all' ? undefined : discountStatus,
        reductionType: discountReductionType == 'all' ? undefined : discountReductionType
      }
    })
    getData()
    dispatch(setDiscountFormMode('none'))
  }

  return (
    <Drawer
    Open={mode === 'filter' ? true : false}
    txtHead='headersh'
    changeOpenClose={() => dispatch(setDiscountFormMode('none'))}
      anchor='left'
    >
      <div className='mt-6 p-4 rtl'>
        <p className='text-center font-bold text-2xl mb-10'>
          {t('Common.filter')}
        </p>
        <div className='flex flex-col gap-6'>
          <InputTextWithoutForm
            classContainer=''
            getValue={(e: any) => setName(e)}
            placeholder={t('Common.DiscountName')}
            label={t('Common.DiscountName')}
          />
          <SelectWithoutForm
            label={t('Common.status')}
            options={optionDiscountState}
            placeholder={t('Common.status')}
            getValue={(e: { label: string; value: string }) =>
            setDiscounStatus(e.value)
          }
          />

          <SelectWithoutForm
            label={t('Common.users')}
            options={optionDiscountType}
            placeholder={t('Common.users')}
            getValue={(e: { label: string; value: string }) =>
             setDiscounType(e.value)
            }
          />

          <SelectWithoutForm
            label={t('Common.reductionType')}
            options={optionDiscountReductionType}
            placeholder={t('Common.reductionType')}
            getValue={(e: { label: string; value: string }) =>
              setDiscounReductionType(e.value)
          }
          />
          <ButtonBrand
            className='w-30'
            click={() => {
              handleFilter()            }}
          >
            <b> {t('Txt.filter')} </b>
          </ButtonBrand>
        </div>
      </div>
    </Drawer>
  )
}
export default FilterDiscount
