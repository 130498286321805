
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../data/store'

import EditSlider from './actions/edit'
import DeleteBannerComponent from './actions/delete'
import AddBlog from './actions/add'

export default function FormBlog() {

  const mode = useSelector((state: RootState) => state.BlogForm.mode)

  return (
    <>
      <div className='w-full h-full bg-[--light] p-2 rounded-[--radius]'>
 

        {mode === "delete" && <DeleteBannerComponent/>}
        {mode === "add" && <AddBlog/>}
        {mode==="edit"&&<EditSlider/>}

      </div>
    </>
  )
}
