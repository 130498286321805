import { BlogList,BlogAddPropsApi,BlogRemovePropsApi, BlogUpdatePropsApi, BlogDetail, BlogListFilterApi, BlogListApi, BlogDataType } from "../../interface/EntityBlog";

import { ChangeToFormData } from "../../utility/Function";
import {  BLOG_GET_PATH, BLOG_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'
import { ID } from "../../interface/EntityCommon";

export const BlogApi = createApi({
    reducerPath: 'BlogApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Blog"],

    endpoints: (builder) => ({
        Blog: builder.query<BlogListApi, BlogListFilterApi>({
            query: () => ({
                url: BLOG_GET_PATH,
                method: 'GET',
            }),
            providesTags: ['Blog'],
        }),
        BlogDetail: builder.query<BlogDetail, ID>({
            query: (body) => ({
                url: BLOG_GET_PATH+"/"+body.id,
                method: 'GET',
            }),
            providesTags: ['Blog'],
        }),
        BlogAdd: builder.mutation<any, BlogAddPropsApi>({
            query: (body) => ({
                url: BLOG_PATH,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Blog'],
        }),

        BlogEdit: builder.mutation<void, BlogUpdatePropsApi>({
            query: ({ id, ...res }) => ({
                url: BLOG_PATH + "/" + id,
                method: 'POST',
                body: ChangeToFormData({...res,...{"_method":"PUT"}})
           
            }),
            invalidatesTags: ['Blog'],
        }),

        BlogRemove: builder.mutation<void, BlogRemovePropsApi>({
            query: (body) => ({
                url: BLOG_PATH + "/" + body.id,
                method: 'DELETE',

            }),
            invalidatesTags: ['Blog'],
        }),



    }),



})

export const {
    useBlogQuery,
    useBlogDetailQuery,
    useBlogAddMutation,
    useBlogEditMutation,
    useBlogRemoveMutation
} = BlogApi


