import { useTranslation } from 'next-i18next';
import { FormProps, SelectOption } from '../../../interface/Element';
import { useEffect, useState } from 'react';
import { useRoleAllQuery } from '../../../data/services/Role';
import SelectInput from '../../shared/form/select/SelectInput';
 
export default function RoleSelect({ control, errors,name="role_id" }: FormProps) {
    const {t}=useTranslation()
    const {data,isLoading}=useRoleAllQuery()
    const [options,setOptions]=useState<SelectOption[]>([]);

    useEffect(()=>{
        if(data?.data){
            const newOptions=data.data.map((item)=>{
                return {
                    label:item.name,
                    value:item.id
                }
            })
            setOptions(newOptions);
        }
    },[data])
    
    return (
        <>
            <SelectInput
                options={options}
                control={control}
                errors={errors}
                keyValue={name}
                label={t("Txt.roleName")}
                name={name} 
                changeF={()=>{}}/>

        </>
    )
}

 