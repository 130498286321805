import * as Yup from "yup"

import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ToastError, ToastSuccess } from "../../../utility/ToastFunction"


import ModalFull from '../../shared/modal/ModalFull'
import { useProductCommentQuery, useProductDetailQuery } from '../../../data/services/Product'

import { Loader } from "../../shared/Loader"
import DetailP from "./info"
import { useTranslation } from "next-i18next"
import EyeIcon from "../../shared/Icon/Eye"
import TabBtn from "../../shared/Html/TabBtn"
import TabBar from "../../../layout/tabBar"
import CommentList from "./comment/CommentList"

function DetailProduct({ openModal, ChangeOpen, id }: { openModal: boolean, ChangeOpen: Function, id: number }) {
    const [OpenAdd, setOpenAdd] = useState(false);
    const { data, isLoading } = useProductDetailQuery({ id }, { skip: id == 0 || id === null });
    const { data: dataComment, isLoading: loadingComment } = useProductCommentQuery({ id }, { skip: id == 0 || id === null })
    const { t } = useTranslation();
    const [tab, setTab] = useState<string>("detail");

    useEffect(() => {
        setOpenAdd(openModal);
    }, [openModal])

    return (
        <>
            <ModalFull ChangeOpen={(open: boolean) => { setOpenAdd(open); ChangeOpen(open); }} Open={OpenAdd} title={isLoading ? "" : data?.data.name ?? ""}>

                <TabBar>
                    <div className="w-full" onClick={() => setTab("detail")}>
                        <TabBtn active={tab === "detail"} icon={<EyeIcon size="20px" />} txt={t("Txt.info")} />
                    </div>
                    <div className="w-full" onClick={() => setTab("comments")}>
                        <TabBtn active={tab === "comments"} icon={<EyeIcon size="20px" />} txt={t("Txt.comments")} />
                    </div>
                </TabBar>

                {isLoading && <div className="flex justify-center w-full"><Loader /></div>}
                {data?.data && tab === "detail" && <DetailP  {...data?.data} />}
                {dataComment?.data && tab === "comments" && <CommentList comments={dataComment?.data} />}


            </ModalFull>
        </>
    )
}

export default DetailProduct