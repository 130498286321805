import * as Yup from "yup"

import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'



import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from "next-i18next"
import { useSectionAddMutation } from "../../../../../../../data/services/Section"
import { ToastError, ToastSuccess } from "../../../../../../../utility/ToastFunction"
import ButtonBrand from "../../../../../../shared/button/ButtonBrand"
import FormItem from "../FormItem"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../../../data/store"


export default function AddSection() {

    const { t } = useTranslation();
    const [sectionAdd, results] = useSectionAddMutation()
    const Data = useSelector((state: RootState) => state.PageForm)
    const Schema = Yup.object().shape({
        title: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<any> = (data) => {

        if (data?.title !== undefined) {
            let params = data;
            params['page_id'] = Data.data?.id
            if (data.link == undefined || "undefined") {
                delete(params['link']);
            }
            sectionAdd(params);
        }
    }

    useEffect(() => {
        reset();
    }, [])



    useEffect(() => {
        if (results.isSuccess) {

            ToastSuccess(t("Common.successAlarm"))
            reset();
        }
        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    return (
        <>

            <div className="FormModal !h-auto">
                <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                    <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />

                    <div className='w-full flex justify-center mt-10'>
                        <div className="w-1/3">
                            <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                <b>{t("Common.saveButton")}</b>
                            </ButtonBrand>
                        </div>
                    </div>
                </form>



            </div>

        </>
    )
}

