
import { useTranslation } from 'next-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store';
import { useState } from 'react';
import { useSectionQuery } from '../../../../../data/services/Section';
import TrashIcon from '../../../../shared/Icon/Trash';
import GrayLine from '../../../../shared/Html/GrayLine';
import ButtonOuterBrandIcon from '../../../../shared/button/ButtonOuterBrandIcon';
import EditIcon from '../../../../shared/Icon/Edit';
import AddSection from './form/add';
import EditSection from './form/edit';
import { SectionDataType } from '../../../../../interface/EntitySection';
import DeleteSectionComponent from './form/delete';
import { baseUrl } from '../../../../../config/ApiConf';

export default function SectionsPage() {

    const { t } = useTranslation();
    const Data = useSelector((state: RootState) => state.PageForm);

    const [action, setAction] = useState("add");
    const [dataEdit, setDataEdit] = useState<SectionDataType>({ id: 0, page_id: 0, priority: 2, title: "", user_type_show: "", link: "" });


    const { data, isLoading } = useSectionQuery({ page: 1, page_id: Data.data?.id });
    const dispatch = useDispatch();



    return (
        <>
            {
                action === "add" && <AddSection />
            }

            {
                action === "edit" && <EditSection data={dataEdit} changeAction={setAction} />
            }
            {
                action === "delete" && <DeleteSectionComponent data={dataEdit} openDelete={action === "delete"} setAction={setAction} />
            }
            <div className="flexRow p-2 flex-wrap gap-4">
                {data?.data.map((item) => <>
                    <div className='px-2 py-4 rounded-[--radius] flexCol bg-[--light] h-[220px] w-48 gap-4 shadow-lg border'>
                        
                        <img className=' rounded-full w-full h-[100px] object-cover' src={item?.pathFile ? baseUrl +item.pathFile : ""} alt="" />
                        <strong>{item?.title}</strong>
                        <GrayLine />
                        <div className='flexRow gap-2'>
                            <ButtonOuterBrandIcon click={() => { setAction("edit"); setDataEdit(item) }}>
                                <EditIcon size='15px' fill={'var(--secondaryTxt)'} />
                            </ButtonOuterBrandIcon>

                            <ButtonOuterBrandIcon click={() => { setAction("delete"); setDataEdit(item) }}>
                                <TrashIcon size='15px' fill={'var(--error)'} />
                            </ButtonOuterBrandIcon>

                        </div>
                    </div>
                </>)}

            </div>
        </>
    )
}
