import Header from '../../components/banner/header'
import ListData from '../../components/banner/list/Index'

export default function BannerPage() {
    return (
        <>
            <div className="flexCol gap-4 pageLayout">
                <Header />
                <ListData />
            </div>
        </>
    )
}
