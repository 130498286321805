import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, SliderDataType } from "../../../interface/EntitySlider";
import { PermissionData } from "../../../interface/EntityPermission";

const initialState: {
    mode: ActionMode,
    data: SliderDataType | null,
    deleteItem:SliderDataType|null,
    permissions:PermissionData[]
    
} = {
    mode: "none",
    data: null,
    deleteItem:null,
    permissions:[]

}


export const SliderForm = createSlice({
    name: 'SliderForm',
    initialState,
    reducers: {
        setSliderFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },

        emptySliderFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'none'
            return newState;
        },

        setSliderFormData: (state, action: { payload: SliderDataType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },

        setSliderPermissionsFormData: (state, action: { payload: PermissionData[] }) => {
            let newState = state;
            newState['permissions'] = action.payload
            return newState;
        },

        emptySliderFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },
        emptySliderPermissionsFormData:(state)=>{
            let newState=state
            newState['permissions']=[];
            return newState;
        },


        setSliderDeleteItem: (state, action: { payload: SliderDataType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptySliderDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setSliderFormMode,
    emptySliderFormMode,
    setSliderFormData,
    emptySliderFormData,
    setSliderDeleteItem,
    emptySliderDeleteItem
    
} = SliderForm.actions

export default SliderForm.reducer