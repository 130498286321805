import { SvgProps } from '../../../interface/Element'

function PlusIcon({ fill = "#000000", size = "20px", stroke = "none" }: SvgProps) {
    return (
        <>

            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12H18M12 6V18" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </>
    )
}

export default PlusIcon