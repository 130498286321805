import { useTranslation } from 'next-i18next';
import React from 'react'
import { useUserDetailQuery } from '../../../../data/services/User';
import { ID } from '../../../../interface/EntityCommon';
import ImageComponent from '../../../shared/Html/Image';

export default function AttachmentUser({ id }: ID) {
  const { t } = useTranslation();
  const { data, isLoading } = useUserDetailQuery({ id: id })
  return (
    <>
      <div className='flexRow flex-wrap gap-10 p-4 justify-center'>
        {data?.data?.images?.map((item) => {


          return <div className='w-[300px] h-auto flexCol gap-2 items-center'>

            <ImageComponent
              className='w-full h-full rounded-[--radius] object-cover object-center aspect-auto'
              src={item.pathFile}
            />
            <b>{t("Txt."+item.type)}</b>


          </div>
        }
        )}

      </div>
    </>
  )
}
