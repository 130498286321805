import { useTranslation } from 'next-i18next';
import  { useEffect, useState } from 'react'
import { useSectionRemoveMutation } from '../../../../../../../data/services/Section';
import { ToastError, ToastSuccess } from '../../../../../../../utility/ToastFunction';
import DialogComponent from '../../../../../../shared/drawer/Dialog';
import GrayLine from '../../../../../../shared/Html/GrayLine';
import ButtonDanger from '../../../../../../shared/button/ButtonDanger';
import ButtonBrandOuter from '../../../../../../shared/button/ButtonBrandOuter';
import { SectionDataType } from '../../../../../../../interface/EntitySection';
 

function DeleteSectionComponent({data,openDelete,setAction}:{data:SectionDataType,openDelete:boolean,setAction:Function}) {
    const { t } = useTranslation()
  
    const [open, setOpen] = useState(openDelete);
    const [DeleteCategoryApi,results]=useSectionRemoveMutation()

    const DeleteCategory =()=>{
        DeleteCategoryApi({id:data.id})
    }

    const CloseModal = () => {
    
        setOpen(false)
        setAction("add")
    }

    useEffect(() => {
        if (results.isSuccess) {
            ToastSuccess(t("Common.successResponse"))
            CloseModal()

        }
        if (results.isError) {
            ToastError(results.error.toString())
        }
    }, [results])

 

    return (
        <>
            <DialogComponent Open={open} handleCloseFun={CloseModal}>
                <div className="flexCol gap-4 rounded-[--radius]">
                    <div className="text-lg font-bold px-8 py-2">{t("Common.delete")} {data.title}</div>
                    <GrayLine />
                    <div className='px-8 py-2'>
                        <p>{t("Common.deleteAgree")}</p>
                    </div>

                    <div className="flexRow gap-2 px-8 py-2">
                        <ButtonDanger loading={results.isLoading} click={() => { DeleteCategory() }}>
                            <b>{t("Common.delete")}</b>
                        </ButtonDanger>
                        <ButtonBrandOuter click={() => { CloseModal() }}>
                            <b>{t("Common.cancel")}</b>
                        </ButtonBrandOuter>
                    </div>

                </div>
            </DialogComponent>
        </>
    )
}

export default DeleteSectionComponent