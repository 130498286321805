import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, UserDataType } from "../../../interface/EntityUser";

const initialState: {
    mode: ActionMode,
    data: UserDataType | null,
    deleteItem: UserDataType | null,
} = {
    mode: "none",
    data: null,
    deleteItem: null,
}


export const UserForm = createSlice({
    name: 'UserForm',
    initialState,
    reducers: {
        setUserFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },

        emptyUserFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'add'
            return newState;
        },
        setUserFormData: (state, action: { payload: UserDataType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },

        emptyUserFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },


        setUserDeleteItem: (state, action: { payload: UserDataType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptyUserDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setUserFormMode,
    emptyUserFormMode,
    setUserFormData,
    emptyUserFormData,
    setUserDeleteItem,
    emptyUserDeleteItem
} = UserForm.actions

export default UserForm.reducer