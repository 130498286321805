import Header from '../../components/blog/header'
import ListData from '../../components/blog/list/Index'

export default function BlogPage() {
    return (
        <>
            <div className="flexCol gap-4 pageLayout">
                <Header />
                <ListData />
            </div>
        </>
    )
}
