import React from 'react'
import { baseUrl } from '../../../config/ApiConf'

function ImageComponent({
    src,
    className='rounded-md h-full w-full object-cover ',
    loading="lazy",
    alt=""
}:
    {
        src: String,
        className: string,
        loading?: "lazy" | "eager",
        alt?: string
    }) {
    return (
        <img className={className} src={baseUrl + src} loading='lazy' alt={alt} />

    )
}

export default ImageComponent