import { useDispatch, useSelector } from 'react-redux'
import Drawer from '../../../../shared/drawer/Drawer'
import { useTranslation } from 'next-i18next'
import ButtonBrand from '../../../../shared/button/ButtonBrand'
import InputTextWithoutForm from '../../../../shared/form/input/InputTextWithoutForm'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import SelectWithoutForm from '../../../../shared/form/input/SelectWithoutForm'
import { RootState } from '../../../../../data/store'
import { setTransactionFormMode } from '../../../../../data/slice/transaction/TransactionForm'

interface IProps {
    page: number
    setFilter: Dispatch<SetStateAction<{}>>
    filter: {
        page: number
        type: string | undefined
        name: string | undefined,
        is_pay: string | undefined
    }
    getData: any
}

const FilterTransactions: FC<IProps> = ({ filter, getData, page, setFilter }) => {
    const mode = useSelector((state: RootState) => state.TransactionForm.mode)

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [name, setName] = useState<string>('')
    const optionTransactionIsPay = [
        { label: t('Common.all'), value: 'all' },
        { label: t('Common.not paid'), value: '0' },
        { label: t('Common.paid'), value: '1' }
    ]
    const optionTransactionType = [
        { label: t('Common.all'), value: 'all' },
        { label: t('Common.purchase'), value: 'purchase' },
        { label: t('Common.credit'), value: 'credit' }
    ]
    const [transactionType, setTransactionType] = useState('all')
    const [transactionStatus, setTransactionStatus] = useState('all')

    const handleFilter = async () => {
        console.log("enter filter : ", filter)
        await setFilter({
            ...filter,
            ...{
                name: name.length >= 3 ? name : undefined,
                page,
                type: transactionType === 'all' ? undefined : transactionType,
                is_pay: transactionStatus === 'all' ? undefined : transactionStatus,
                
            }
        })
        getData()
        dispatch(setTransactionFormMode('none'))
    }

    return (
        <Drawer
            Open={mode === 'filter' ? true : false}
            txtHead='headersh'
            changeOpenClose={() => dispatch(setTransactionFormMode('none'))}
            anchor='left'
        >
            <div className='mt-6 p-4 rtl'>
                <p className='text-center font-bold text-2xl mb-10'>
                    {t('Common.filter')}
                </p>
                <div className='flex flex-col gap-6'>
                    <InputTextWithoutForm
                        classContainer=''
                        getValue={(e: any) => setName(e)}
                        placeholder={t("Txt.PaidPerson")}
                        label={t("Txt.PaidPerson")}
                    />
                    <SelectWithoutForm
                        label={t('Common.status')}
                        options={optionTransactionIsPay}
                        placeholder={t('Common.status')}
                        getValue={(e: { label: string; value: string }) =>
                            setTransactionStatus(e.value)
                        }
                    />
                    {/* <SelectWithoutForm
                        label={t('Common.type')}
                        options={optionTransactionType}
                        placeholder={t('Common.type')}
                        getValue={(e: { label: string; value: string }) =>
                            setTransactionType(e.value)
                        }
                    /> */}
                    <ButtonBrand
                        className='w-30'
                        click={() => {
                            handleFilter()
                        }}
                    >
                        <b> {t('Txt.filter')} </b>
                    </ButtonBrand>
                </div>
            </div>
        </Drawer>
    )
}
export default FilterTransactions
