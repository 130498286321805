import React, { useState } from 'react'

import AddProduct from "./actions/AddEdit/AddProduct";
import ButtonOrange from '../shared/button/ButtonBrand'
import Plus from '../shared/Icon/PlusCircle'
import { useTranslation } from 'next-i18next';

function Header() {
    const [OpenAdd, setOpenAdd] = useState(false);
    const {t}=useTranslation()
     
  
    return (
        <>
            <h1 className="text-2xl">{t("Menu.products")}</h1>
            <div className="w-60">
                <ButtonOrange click={() => { setOpenAdd(true) }} >
                    <div className="flexRow items-center gap-2">
                        <span><Plus fill="var(--light)" /></span>
                        <b> {t("Txt.addProduct")} </b>
                    </div>
                </ButtonOrange>
            </div>

            <AddProduct openModal={OpenAdd} ChangeOpen={setOpenAdd}/>

    
        </>
    )
}

export default Header