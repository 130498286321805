import { createSlice } from "@reduxjs/toolkit"

const initialState:any={Auth:{mobile:""},AuthResult:{exist:false}}


export const AuthData = createSlice({
    name: 'AuthData',
    initialState,
    reducers: {
        setAuthDataMobile: (state, action: { payload:any }) => {
            let newStateAuth=state;
            newStateAuth.Auth=action.payload;
            return newStateAuth
        },
        setAuthDataExist: (state, action: { payload: any }) => {
            let newStateAuthResult=state;
            newStateAuthResult.AuthResult=action.payload;
            return newStateAuthResult
        },
       
        emptyAuthData: (state) => {
            state=initialState
            return state
        },
       
    }
})

export const { setAuthDataMobile,setAuthDataExist, emptyAuthData } = AuthData.actions

export default AuthData.reducer