
import { setBannerFormMode } from '../../../data/slice/banner/BannerForm';
import ButtonBrand from '../../shared/button/ButtonBrand';
import PlusIcon from '../../shared/Icon/Plus';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';

function Header() {
    const dispatch=useDispatch();
    const { t } = useTranslation()


    return (
        <>
            <h1 className="text-2xl">{t("Menu.banner")}</h1>
            <div className="w-60">
                <ButtonBrand click={() => { dispatch(setBannerFormMode("add")) }} >
                    <div className="flexRow items-center gap-2">
                        <span><PlusIcon fill="var(--light)" /></span>
                        <b> {t("Txt.addBanner")} </b>
                    </div>
                </ButtonBrand>
            </div>

 

        </>
    )
}

export default Header