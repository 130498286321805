import FilterProduct from "../../components/product/FilterProduct";
import Header from "../../components/product/Header";
import ListData from "../../components/product/Index";

export default function ProductsPage() {
  return (
    <>
      <div className="flexCol gap-4 pageLayout">
   
        <Header />
        <FilterProduct/>
        <ListData/>
      

    

      </div>
    </>
  );
}
