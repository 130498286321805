

export default function DownloadLink({ url, fileName, children }: { url: string, fileName: string, children: React.ReactNode }) {
    const handleDownload = () => {
         
    
        const link = document.createElement('a');
        link.href = url;
        link.download =fileName;
        link.click();
    };
    return (
        <div onClick={handleDownload}>
            {children}
        </div>
    );
}