import { SliderList,SliderAddPropsApi,SliderRemovePropsApi, SliderUpdatePropsApi } from "../../interface/EntitySlider";

import { ChangeToFormData } from "../../utility/Function";
import {  SLIDER_GET_PATH, SLIDER_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const SliderApi = createApi({
    reducerPath: 'SliderApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Slider"],

    endpoints: (builder) => ({
        Slider: builder.query<SliderList, void>({
            query: () => ({
                url: SLIDER_GET_PATH,
                method: 'GET',
            }),
            providesTags: ['Slider'],
        }),

        SliderAdd: builder.mutation<any, SliderAddPropsApi>({
            query: (body) => ({
                url: SLIDER_PATH,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Slider'],
        }),

        SliderEdit: builder.mutation<void, SliderUpdatePropsApi>({
            query: ({ id, ...res }) => ({
                url: SLIDER_PATH + "/" + id,
                method: 'POST',
                body: ChangeToFormData({...res,...{"_method":"PUT"}})
           
            }),
            invalidatesTags: ['Slider'],
        }),

        SliderRemove: builder.mutation<void, SliderRemovePropsApi>({
            query: (body) => ({
                url: SLIDER_PATH + "/" + body.id,
                method: 'DELETE',

            }),
            invalidatesTags: ['Slider'],
        }),



    }),



})

export const {
    useSliderQuery,
    useSliderAddMutation,
    useSliderEditMutation,
    useSliderRemoveMutation
} = SliderApi


