
export const ApiRevers='https://api.neshan.org/v5/reverse'//نشان
//export const ApiRevers='https://api.geocode.earth/v1/reverse'//geocode.earth
//export const ApiRevers='https://nominatim.openstreetmap.org/reverse'///nominatim
export const ApiSearch='https://api.neshan.org/v1/search'//نشان
//export const ApiSearch='https://api.geocode.earth/v1/search'//geocode.earth
//export const ApiSearch='https://nominatim.openstreetmap.org/search'//nominatim
//export const ApiKey="ge-2320452420af7210";//geocode.earth

export const ApiDistance='https://api.neshan.org/v1/distance-matrix'//نشان


export const ApiKey="service.3c5cabc7df4f4f369149ae3b0e6f4464";

export const LatIran="35.67774127312945";
export const LngIran="51.3896004";