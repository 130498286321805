import { SupplierAddPropsApi, SupplierListApi, SupplierListFilterApi } from "../../interface/EntitySupplier";
import { SupplierRemovePropsApi, SupplierUpdatePropsApi } from './../../interface/EntitySupplier';

import { ChangeToFormData, ChangeToQueryString } from "../../utility/Function";
import { SUPPLIER_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const SupplierApi = createApi({
    reducerPath: 'SupplierApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Supplier"],

    endpoints: (builder) => ({
        Supplier: builder.query<SupplierListApi, SupplierListFilterApi>({
            query: (body) => ({
                url: SUPPLIER_PATH+ChangeToQueryString(body),
                method: 'GET',

            })
            ,
            providesTags: ['Supplier'],
        }),


        SupplierAdd: builder.mutation<void, SupplierAddPropsApi>({
            query: (body) => ({
                url: SUPPLIER_PATH,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Supplier'],
        }),

        SupplierEdit: builder.mutation<void, SupplierUpdatePropsApi>({
            query: ({ id, ...res }) => ({
                url: SUPPLIER_PATH + "/" + id,
                method: 'PUT',

                body: (res)
            }),
            invalidatesTags: ['Supplier'],
        }),

        SupplierRemove: builder.mutation<void, SupplierRemovePropsApi>({
            query: (id) => ({
                url: SUPPLIER_PATH + "/" + id,
                method: 'DELETE',

            }),
            invalidatesTags: ['Supplier'],
        }),



    }),



})

export const {
    useSupplierQuery,
    useSupplierAddMutation,
    useSupplierEditMutation,
    useSupplierRemoveMutation
} = SupplierApi


