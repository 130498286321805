import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, BannerDataType } from "../../../interface/EntityBanner";
import { PermissionData } from "../../../interface/EntityPermission";

const initialState: {
    mode: ActionMode,
    data: BannerDataType | null,
    deleteItem:BannerDataType|null,
    permissions:PermissionData[]
    
} = {
    mode: "none",
    data: null,
    deleteItem:null,
    permissions:[]

}


export const BannerForm = createSlice({
    name: 'BannerForm',
    initialState,
    reducers: {
        setBannerFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },

        emptyBannerFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'none'
            return newState;
        },

        setBannerFormData: (state, action: { payload: BannerDataType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },

        setBannerPermissionsFormData: (state, action: { payload: PermissionData[] }) => {
            let newState = state;
            newState['permissions'] = action.payload
            return newState;
        },

        emptyBannerFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },
        emptyBannerPermissionsFormData:(state)=>{
            let newState=state
            newState['permissions']=[];
            return newState;
        },


        setBannerDeleteItem: (state, action: { payload: BannerDataType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptyBannerDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setBannerFormMode,
    emptyBannerFormMode,
    setBannerFormData,
    emptyBannerFormData,
    setBannerDeleteItem,
    emptyBannerDeleteItem
    
} = BannerForm.actions

export default BannerForm.reducer