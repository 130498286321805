import Header from '../../components/pages/header'
import ListData from '../../components/pages/list/Index'
import React from 'react'

export default function Pages() {
  return (
    <>
      <div className="flexCol gap-4 pageLayout">

        <Header />
        <ListData />

      </div>
    </>
  )
}
