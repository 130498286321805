import { SubmitHandler, useForm } from 'react-hook-form'
import InputTxt from '../../components/shared/form/input/InputTxt'
import NumberCodeInput from '../../components/shared/form/input/NumberCodeInput copy'
import { useTranslation } from 'next-i18next'
import InputTextarea from '../../components/shared/form/input/InputTextarea'
import ButtonBrand from '../../components/shared/button/ButtonBrand'
import {
  useSiteInfoDataQuery,
  useSiteInfoUpdateMutation
} from '../../data/services/SiteInfo'
import { useEffect, useState } from 'react'
import FileInput from '../../components/shared/form/upload/FileInput'
import ImageComponent from '../../components/shared/Html/Image'
import VideoComponent from '../../components/shared/Html/Video'
import Maps from '../../components/shared/map/Map'
import { ToastSuccess } from '../../utility/ToastFunction'
import NumberInput from '../../components/shared/form/input/NumberInput'
import CustomSelect from '../../components/shared/form/input/CustomSelect'

function SiteInfoPage() {
  const { t } = useTranslation()
  const [updateInfo, results] = useSiteInfoUpdateMutation()
  const { data } = useSiteInfoDataQuery()
  const [deleteImage, setDeleteImage] = useState(false)
  const [deleteVideo, setDeleteVidelo] = useState(false)
  const [position, setPosition] = useState<any>([
    data?.data.site_info?.lat,
    data?.data?.site_info.long
  ])
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch
  } = useForm<any>({})

  const OnFinish: SubmitHandler<any> = data => {
    if (data !== undefined) {
      let params = data

      if (deleteVideo || typeof params.instagram_post_video === 'string') {
        delete params['instagram_post_video']
      }

      if (typeof params.instagram_post_image === 'string') {
        delete params['instagram_post_image']
      }

      if (deleteImage) {
        params['instagram_post_image'] = null
      }
      if (deleteVideo) {
        params['instagram_post_video'] = null
      }

      delete params['instagram_post_image_path']
      delete params['instagram_post_video_path']

      updateInfo({
        ...params,
        lat: position[0].toString(),
        long: position[1].toString()
      })
    }
  }

  useEffect(() => {
    if (results.isSuccess) {
      setDeleteImage(false)
      setDeleteVidelo(false)
      ToastSuccess('Common.successTxt')
    }
  }, [results])

  useEffect(() => {
    if (data?.data.site_info) {
      let params: any = data.data.site_info
      setPosition([params.lat, params.long])
      reset(params)
    }
  }, [data])

  return (
    <>
      <form onSubmit={handleSubmit((values: any) => OnFinish(values))}>
        <div className='bg-[--light] FormModal !h-auto flexCol gap-4 p-4'>
          <div className='flexRowItemForm'>
            <div className='w-full'>
              <InputTxt
                errors={errors}
                label={t('Common.instagram')}
                name='instagram'
                control={control}
                keyValue='instagram'
              />
            </div>
            <div className='w-full'>
              <InputTxt
                errors={errors}
                label={t('Common.twitter')}
                name='twitter'
                control={control}
                keyValue='twitter'
              />
            </div>
          </div>
          <div className='flexRowItemForm'>
            <div className='w-full'>
              <InputTxt
                errors={errors}
                label={t('Common.facebook')}
                name='facebook'
                control={control}
                keyValue='facebook'
              />
            </div>
            <div className='w-full'>
              <InputTxt
                errors={errors}
                label={t('Common.telegram')}
                name='telegram'
                control={control}
                keyValue='telegram'
              />
            </div>
          </div>
          <div className='flexRowItemForm'>
            <div className='w-full'>
              <InputTxt
                errors={errors}
                label={t('Common.whatsapp')}
                name='whatsapp'
                control={control}
                keyValue='whatsapp'
              />
            </div>
          </div>
          <div className='flexRowItemForm'>
            <div className='w-full'>
              <InputTextarea
                errors={errors}
                label={t('Common.address')}
                name='address'
                control={control}
                keyValue='address'
              />
            </div>
            <div className='w-full'>
              <InputTextarea
                errors={errors}
                label={t('Menu.about')}
                name='about'
                control={control}
                keyValue='about'
              />
            </div>
          </div>

          <div className='flexRowItemForm'>
            <div className='w-full'>
              <NumberCodeInput
                length={11}
                errors={errors}
                label={t('Common.phone')}
                name='phone'
                control={control}
                keyValue='phone'
              />
            </div>
            <div className='w-full'>
              <InputTxt
                errors={errors}
                label={t('Common.email')}
                name='email'
                control={control}
                keyValue='email'
              />
            </div>
          </div>

          {/* Transactions */}

          <div className='w-full'>
            <NumberInput
              errors={errors}
              label={t('Common.limit_purchase_wholesale')}
              name='limit_purchase_wholesale'
              control={control}
              keyValue='limit_purchase_wholesale'
            />
            <div className="flexRowItemForm">
              <div className="w-full">
                <NumberInput
                  errors={errors}
                  label={t('Common.post_price')}
                  name='post_price'
                  control={control}
                  keyValue='post_price'
                />
              </div>
              <div className="w-full">
                <NumberInput classContainer=''
                  errors={errors}
                  label={t('Common.tax_percent')}
                  name='tax_percent'
                  control={control}
                  keyValue='tax_percent'
                />
              </div>
            </div>
          </div>

          {/* SEO */}
          <div className='flexRowItemForm'>
            <div className='w-full'>
              <InputTxt
                errors={errors}
                label={t('Common.titleSeo')}
                name='title_seo'
                control={control}
                keyValue='title_seo'
              />
            </div>
            <div className='w-full'>
              <InputTextarea
                errors={errors}
                label={t('Common.descriptionSeo')}
                name='description_seo'
                control={control}
                keyValue='description_seo'
              />
            </div>
          </div>
          <div className='flexRowItemForm'>
            <div className='w-full'>
              <FileInput
                accept='image/*'
                getValues={getValues}
                name='instagram_post_image'
                title={t('Txt.instagram_image')}
                control={control}
                errors={errors}
              />
            </div>
            <div className='w-full'>
              <FileInput
                accept='.mp4'
                getValues={getValues}
                name='instagram_post_video'
                title={t('Txt.instagram_post_video')}
                control={control}
                errors={errors}
              />
            </div>
          </div>
          <div className=''>
            <p className='LabelClass'>انتخاب موقعیت مکانی</p>
            <div className='w-full flex justify-center'>
              <div className='relative w-[70%] h-[300px]'>
                <Maps
                  ChangeData={(e: any) => {
                    setPosition([e?.lat, e?.lng])
                  }}
                  canChangeLocation={true}
                  findCurrentLocation={false}
                  editMode={true}
                />
              </div>
            </div>
          </div>
          <div className='flexRow gap-4'>
            <div className='w-[45%]'>
              <ImageComponent
                className='w-full h-full object-contain rounded-[--radius]'
                src={data?.data.site_info.instagram_post_image_path ?? ''}
              />
            </div>
            <div className='w-[45%] rounded-[10px]  '>
              <VideoComponent
                className='w-full h-full bg-[--murky] rounded-[--radius]'
                src={data?.data.site_info.instagram_post_video_path ?? ''}
              />
            </div>
          </div>
          <div className='w-full flex justify-evenly'>
            <ButtonBrand
              className='mb-4 !w-[100px]'
              loading={results.isLoading}
              click={() => {
                // setValue('instagram_post_image', null)
                setDeleteImage(true)
                OnFinish({ ...getValues() })
              }}
              type='submit'
            >
              {t('Common.deleteImage')}
            </ButtonBrand>
            <ButtonBrand
              className='mb-4 !w-[100px]'
              loading={results.isLoading}
              click={() => {
                setDeleteVidelo(true)
                // setValue('instagram_post_video', '')
                OnFinish({ ...getValues() })
              }}
              type='submit'
            >
              {t('Common.deleteVideo')}
            </ButtonBrand>
          </div>
          <div className='flexRow justify-center w-full mt-20'>
            <div className='w-[50%]'>
              <ButtonBrand
                loading={results.isLoading}
                click={() => { }}
                type='submit'
              >
                {t('Common.saveButton')}
              </ButtonBrand>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default SiteInfoPage
