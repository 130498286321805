import { Controller } from 'react-hook-form'

import React from 'react'
import { InputType } from './interface'

const InputTextarea = ({
    label,
    placeholder,
    control,
    rules,
    name,
    defaultValue,
    errors = {},
    changeF,
    classContainer
}: InputType) => {



    return (
        <>
            {control && <Controller
                control={control}
                rules={rules}
                render={({ field: { onChange, onBlur, value } }) => (
                    <div className={`InputLabelClass ${classContainer}`}>
                        <label htmlFor="" className="LabelClass">{label}</label>
                        <textarea name={name} className="InputClass" onChange={onChange} rows={10}  value={value}></textarea>

                    </div>

                )}

                name={name} />}
            {!control && <div>
                <label htmlFor="" className="LabelClass">{label}</label>
                <textarea name={name} className="InputClass" onChange={(value)=>console.log(value)}  >{defaultValue} </textarea>

            </div>}
            <div>
                <p className='text-red-400 font-bold text-sm m-2'>{errors[name]?.message}</p>

            </div>


        </>
    )
}

export default InputTextarea