import { useEffect, useState } from 'react'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import FormItem from '../FormItem';
import {  useCategoryEditMutation } from '../../../../../data/services/Category';
import { ToastError, ToastSuccess } from '../../../../../utility/ToastFunction';
import ButtonBrand from '../../../../shared/button/ButtonBrand';
import ButtonBrandOuter from '../../../../shared/button/ButtonBrandOuter';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store';
import { emptyCategoryFormData, emptyCategoryFormMode } from '../../../../../data/slice/category/CategoryForm';
import AvatarImage from '../../../../shared/Html/avatar/AvatarImage';
import { baseUrl } from '../../../../../config/ApiConf';

export default function EditCategory() {
    const { t } = useTranslation();
    const[id, setId]=useState(0);
    const [CategoryEdit, results] = useCategoryEditMutation()
    const dispatch=useDispatch();
    const Data=useSelector((state:RootState)=>state.CategoryForm)

    const Schema = Yup.object().shape({
        name: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<any> = (data) => {
        if (data?.name !== undefined) {
            let params = data
            if(params.img===undefined)
                delete(params['img'])
            CategoryEdit({...params,...{type:Data.type} });
        }
    }

    useEffect(()=>{
      if(Data.data){
        reset({id:Data.data.id,name:Data.data.name,title_seo:Data.data.title_seo,description_seo:Data.data.description_seo,alt:Data.data.attach.alt})
        setId(Data.data.id)
      }
    },[Data])


    useEffect(() => {
        if (results.isSuccess) {
            ToastSuccess(t("Common.successResponse"))
            Cancel()

        }
        if (results.isError) {
            ToastError(t("Common.errorResponse"))
        }
    }, [results])

    const Cancel=()=>{
      reset();
      dispatch(emptyCategoryFormMode());
      dispatch(emptyCategoryFormData());
    }

    return (
        <>

            <div className="p-4">
              {Data.data?.attach?.pathFile&&<div className='w-full flex justify-center'><AvatarImage img={baseUrl+Data.data.attach?.pathFile} /></div>}
                <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                    <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />
                    <div className='w-full flexRow gap-4 justify-center mt-10'>
                        <div className="w-1/3">
                            <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                <b>{t("Common.edit")}</b>
                            </ButtonBrand>

                          
                        </div>
                        <div className="w-1/3">
                           

                            <ButtonBrandOuter type='submit' loading={results.isLoading} key={"buttonForm"} click={()=>Cancel()} >
                                <b>{t("Common.cancel")}</b>
                            </ButtonBrandOuter>
                        </div>
                    </div>
                </form>


            </div>

        </>
    )
}
