import * as Yup from 'yup'

import { ProductInventory, ProductUpdateInventoryPropsApi } from '../../../../../../interface/EntityProduct';
import { SubmitHandler, useForm } from 'react-hook-form';

import ButtonBrand from '../../../../../shared/button/ButtonBrand';
import ButtonBrandOuter from '../../../../../shared/button/ButtonBrandOuter';
import FormItem from '../FormItem'
import   { useEffect } from 'react'
import { useProductUpdateInventoryMutation } from '../../../../../../data/services/Product';
import { useTranslation } from 'next-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

function EditInventory({ data, cancel }: { data: ProductInventory, cancel: Function }) {
    const { t } = useTranslation()
    const [updateColorSize, results] = useProductUpdateInventoryMutation()

    const Schema = Yup.object().shape({
        amount: Yup.string().required(t("Validation.require")),
        size: Yup.string().required(t("Validation.require")),
        color: Yup.string().required(t("Validation.require")),
    });
    const { handleSubmit, control, formState: { errors },   reset } = useForm<any>({
        resolver: yupResolver(Schema),
    
    });

    const OnFinish: SubmitHandler<ProductUpdateInventoryPropsApi> = (param) => {
        if (param.amount !== undefined) {
            updateColorSize({ ...param, ...{ product_id: data.product_id } })
        }
    }


    useEffect(() => {
        if(results.isSuccess)
        reset();
    }, [results, reset])

    useEffect(()=>{
        reset(data);
 
    },[data, reset])

    return (
        <>
            <form onSubmit={handleSubmit((values: any) => OnFinish(values))} className='flexCol gap-10'   >

                <div className='flexCol gap-4 p-4'>
                    <FormItem control={control} errors={errors} />
                </div>
              

                <div className='flex justify-center gap-2'>
                    <div className='w-1/2'>
                        <ButtonBrand type='submit' loading={results.isLoading} click={() => { }}>
                            {t("Common.edit")}
                        </ButtonBrand>
                    </div>
                    <div className='w-1/2'>
                        <ButtonBrandOuter type='button' click={() => { cancel("add") }}>
                            {t("Common.cancel")}
                        </ButtonBrandOuter>
                    </div>
                </div>

            </form>
        </>
    )
}

export default EditInventory