import FilterProduct from "../../components/product/FilterProduct";
import Header from "../../components/user/header";
import ListData from "../../components/user/list";

export default function UsersPage() {
  return (
    <>
      <div className="flexCol gap-4 pageLayout">
   
        <Header />
        <ListData/>
      </div>
    </>
  );
}
