import React from 'react'
import { SvgProps } from '../../../interface/Element'

function ArrowUp({ fill = "#A1A0A3", size = "20px", stroke = "#000000" }: SvgProps) {
    return (
        <>
            <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.2753 13.3586C16.0534 13.5805 15.7062 13.6007 15.4615 13.4191L15.3914 13.3586L10 7.96751L4.60862 13.3586C4.38673 13.5805 4.03951 13.6007 3.79483 13.4191L3.72474 13.3586C3.50285 13.1367 3.48267 12.7895 3.66422 12.5448L3.72474 12.4747L9.55807 6.6414C9.77996 6.41951 10.1272 6.39934 10.3719 6.58088L10.442 6.6414L16.2753 12.4747C16.5194 12.7188 16.5194 13.1145 16.2753 13.3586Z" fill={fill} />
            </svg>
        </>
    )
}

export default ArrowUp