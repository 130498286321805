import { FC, useEffect } from "react";
import { ID } from '../../../../interface/EntityCommon';
import { useTranslation } from "next-i18next";
import { useUserDetailQuery } from "../../../../data/services/User";

const AddressesList=({id}:ID)=>{

    const { t } = useTranslation();
    const { data, isLoading } = useUserDetailQuery({ id: id })

    useEffect(()=>{
        if(data?.success == true){
            console.log(data.data)
        }
    },[data])

    return(
        <div>list addresses</div>
    )
}
export default AddressesList