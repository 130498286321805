import { SiteInfo_ADMIN_PATH, SiteInfo_PATH } from "../../config/ApiConf";
import { SiteInfoDataProps, TextUpdateApi } from "../../interface/EntitySetting";
import { ChangeToFormData, ChangeToFormDataSiteInfo } from "../../utility/Function";

import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const SiteInfoApi = createApi({
    reducerPath: 'SiteInfoApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["SiteInfo"],

    endpoints: (builder) => ({
        SiteInfoData: builder.query<SiteInfoDataProps, void>({
            query: () => ({
                url: SiteInfo_PATH,
                method: 'GET',

            })
            ,
            providesTags: ['SiteInfo'],
        }),


        SiteInfoUpdate: builder.mutation<void, TextUpdateApi>({
            query: (body) => ({
                url: SiteInfo_ADMIN_PATH,
                method: 'POST',

                body: ChangeToFormDataSiteInfo(body),
            }),
                invalidatesTags: ['SiteInfo'],
            }),



        }),



    })

export const { useSiteInfoDataQuery, useSiteInfoUpdateMutation } = SiteInfoApi


