
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../data/store'

import EditSlider from './actions/edit'
import DeleteBannerComponent from './actions/delete'
import AddBanner from './actions/add'

export default function FormBanner() {

  const mode = useSelector((state: RootState) => state.BannerForm.mode)

  return (
    <>
      <div className='w-full h-full bg-[--light] p-2 rounded-[--radius]'>
 

        {mode === "delete" && <DeleteBannerComponent/>}
        {mode === "add" && <AddBanner/>}
        {mode==="edit"&&<EditSlider/>}

      </div>
    </>
  )
}
