import { PermissionAddPropsApi, PermissionListApi } from "../../interface/EntityPermission";

import { ChangeToFormData } from "../../utility/Function";
import { Permission_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const PermissionApi = createApi({
    reducerPath: 'permissionApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Permission"],

    endpoints: (builder) => ({
        Permission: builder.query<PermissionListApi, void>({
            query: () => ({
                url: Permission_PATH,
                method: 'GET',
                
            })
            ,
            providesTags: ['Permission'],
        }),


        PermissionAdd: builder.mutation<void, PermissionAddPropsApi>({
            query: (body) => ({
                url: Permission_PATH,
                method: 'POST',
                
                body:ChangeToFormData(body)
            }),
            invalidatesTags: ['Permission'],
        }),

       

    }),



})

export const { usePermissionQuery, usePermissionAddMutation } = PermissionApi


