import { useTranslation } from 'next-i18next';
import { FormProps } from '../../../../../interface/Element';
import SelectInput from '../../../../shared/form/select/SelectInput';

function SizeSelect({ control, errors,name="" }: FormProps) {
    const { t } = useTranslation()

  

    return (
        <>
            <SelectInput
                options={[
                    {label:t("Common.chooseOption"),value:""},
                    {label:t("Common.freeSize"),value:t("Common.freeSize")},
                    {label:"6",value:"6"},
                    {label:"7",value:"7"},
                    {label:"8",value:"8"},
                    {label:"9",value:"9"},
                    {label:"10",value:"10"},


                ]}
                control={control}
                errors={errors}
                keyValue={name}
                label={t("Txt.size")}
                name={name} 
                changeF={()=>{}}
            
                />

        </>
    )
}

export default SizeSelect