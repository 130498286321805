import React from 'react'
import { FormComponentProps } from '../../../../interface/Element'
import FileInput from '../../../shared/form/upload/FileInput'
import InputTxt from '../../../shared/form/input/InputTxt'
import { useTranslation } from 'next-i18next'
import { ImgUploadType } from '../../../../config/App'
import InputTextarea from '../../../shared/form/input/InputTextarea'

export default function FormItem({ control, getValues, setValue, errors }: FormComponentProps) {
    const { t } = useTranslation()
    return (
        <>
            <div className='flexRow flex-wrap w-full'>
                <div className='w-full'>
                    
                    <InputTxt control={control} errors={errors} keyValue='name' label={t("Common.title")} name='name' placeholder={t("Common.title")} />
                    <InputTxt control={control} errors={errors} keyValue='alt' label={t("Common.alt")} name='alt' placeholder={t("Common.alt")} />
                    <InputTxt control={control} errors={errors} keyValue='title_seo' label={t("Common.titleSeo")} name='title_seo' placeholder={t("Common.titleSeo")} />
                    <InputTextarea errors={errors} label={t("Common.descriptionSeo")} name="description_seo" control={control}
                                keyValue="description_seo" />
                </div>
                <br/>
                <div className='w-[45%]'>
                </div>
                <div className='w-[45%]'>
                    <FileInput
                        changeValue={(file: File) => console.log("thumb", file)}
                        getValues={getValues}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        name="img"
                        accept={ImgUploadType}
                        title={t("Txt.chooseImg")} />
                </div>
            </div>

        </ >


    )
}
