import * as Yup from "yup"

import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'



import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from "next-i18next"
import ModalFull from "../../../../shared/modal/ModalFull"
import FormItem from "../FormItem"
import ButtonBrand from "../../../../shared/button/ButtonBrand"
import { ToastError, ToastSuccess } from "../../../../../utility/ToastFunction"
import PageMarginBottom from "../../../../shared/Html/PageMarginBottom"
import { usePageAddMutation } from "../../../../../data/services/Page"

export default function AddPage({ openModal, ChangeOpen }: { openModal: boolean, ChangeOpen: Function }) {

    const { t } = useTranslation();
    const [OpenAdd, setOpenAdd] = useState(false);
    const [pageAdd, results] = usePageAddMutation()
    const [loading, setLoading] = useState(results.isLoading)
    const Schema = Yup.object().shape({
        name: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<any> = (data) => {

        if (data?.name !== undefined) {
            setLoading(true)
            pageAdd(data);
        }
    }

    useEffect(() => {
        setOpenAdd(openModal);
        reset();
    }, [openModal, reset])

 

    useEffect(() => {
        if (results.isSuccess) {
            setLoading(false);
            ToastSuccess(t("Common.successAlarm"))
            setOpenAdd(false);
        }
        if (results.isError) {
            setLoading(false);
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => { setOpenAdd(open); ChangeOpen(open); }} Open={OpenAdd} title={t("Txt.addPage")}>
                <div className="FormModal !h-auto">
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />

                        <div className='w-full flex justify-center mt-10'>
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={loading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                              
                            </div>
                        </div>
                    </form>

                    <PageMarginBottom />


                </div>

            </ModalFull>
        </>
    )
}

