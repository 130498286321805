import { ButtonProps } from '../../../interface/Element'
import { Loader } from '../Loader'

const ButtonDanger = ({ click, children, type = "button",loading=false }: ButtonProps) => {
  return (
    <button
      type={type}
      onClick={() => click()}
      className={`w-full p-3 rounded-[--radius] ${loading ?"bg-[--error]":"bg-[--errorDark]"} text-white`}>
        {loading && <Loader />}
      {!loading&&children}
    </button>
  )
}

export default ButtonDanger