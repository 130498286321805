import { ABOUT_ADMIN_PATH, ABOUT_PATH } from "../../config/ApiConf";
import { AboutDataProps, TextUpdateApi } from "../../interface/EntitySetting";
import { ChangeToFormData } from "../../utility/Function";

import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const AboutApi = createApi({
    reducerPath: 'aboutApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Admin"],

    endpoints: (builder) => ({
        AboutData: builder.query<AboutDataProps, void>({
            query: () => ({
                url: ABOUT_PATH,
                method: 'GET',
                
            })
            ,
            providesTags: ['Admin'],
        }),


        AboutUpdate: builder.mutation<void, TextUpdateApi>({
            query: (body) => ({
                url: ABOUT_ADMIN_PATH,
                method: 'POST',
                
                body:ChangeToFormData(body)
            }),
            invalidatesTags: ['Admin'],
        }),

       

    }),



})

export const { useAboutDataQuery, useAboutUpdateMutation } = AboutApi


