import AddCategory from './actions/add'
import EditCategory from './actions/edit'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../data/store'
import AddChildCategory from './actions/addChild'
import DeleteCategoryComponent from './actions/delete'
import SelectInput from '../../shared/form/select/SelectInput'
import { setCategoryTypeFormData } from '../../../data/slice/category/CategoryForm'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

export default function FormAddEdit() {

  let mode = useSelector((state: RootState) => state.CategoryForm.mode)
  const dispatch = useDispatch();
  const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({

  });

  return (
    <>
      <div className='w-full h-full bg-[--light] p-2 rounded-[--radius] mb-6'>
        <div>
          {/* <form > */}
              
            {/* <SelectInput
              changeF={(value: any) => { dispatch(setCategoryTypeFormData(value)); console.log(value) }}
              keyValue='category_type'
              label=''
              control={control}
              name='category_type'
              options={[
                { label: "product", value: "product" },
                { label: "blog", value: "blog" },
                { label: "faq", value: "faq" },
              ]}
            /> */}
          {/* </form> */}
        </div>
        {mode === "addChild" && <AddChildCategory />}
        {mode === "add" && <AddCategory />}
        {mode === "edit" && <EditCategory />}
        {mode === "delete" && <DeleteCategoryComponent />}
      </div>
    </>
  )
}
