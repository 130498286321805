import {ActionMode, SupportDataType} from '../../../interface/EntitySupport'

import { createSlice } from "@reduxjs/toolkit"

const initialState: {
    mode: ActionMode,
    data: SupportDataType | null,
    deleteItem:SupportDataType|null,
    
} = {
    mode: "none",
    data: null,
    deleteItem:null,

}


export const SupportForm = createSlice({
    name: 'SupportForm',
    initialState,
    reducers: {
        setSupportFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },

        emptySupportFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'none'
            return newState;
        },

        setSupportFormData: (state, action: { payload: SupportDataType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },

        
        emptySupportFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },
        


        setSupportDeleteItem: (state, action: { payload: SupportDataType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptySupportDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setSupportFormMode,
    emptySupportFormMode,
    setSupportFormData,
    emptySupportFormData,
    setSupportDeleteItem,
    emptySupportDeleteItem
    
} = SupportForm.actions

export default SupportForm.reducer