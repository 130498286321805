import { LabelTxtProps } from '../../../interface/Element'
import React from 'react'

const LabelTxt = ({txt="",bg="bg-[--brandLight]",color="white" , className}:LabelTxtProps) => {
  return (
    <div className={` ${bg}  text-${color} py-1 px-2 rounded-[--radius] ${className}`}>
        <span>{txt}</span>
    </div>
  )
}

export default LabelTxt