import { useEffect, useState } from 'react'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import FormItem, { IUser } from "../FormItemDiscount";
import { ToastError, ToastSuccess } from '../../../../../utility/ToastFunction';
import ButtonBrand from '../../../../shared/button/ButtonBrand';
import ModalFull from '../../../../shared/modal/ModalFull';
import PageMarginBottom from '../../../../shared/Html/PageMarginBottom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store';
import { IDiscountData } from '../../../../../interface/EntityDiscount';
import { setDiscountFormMode } from '../../../../../data/slice/discount/DiscountForm';
import { useEditDiscountMutation, useGetDiscountQuery } from '../../../../../data/services/Discount';

export default function EditDiscount() {

    const { t } = useTranslation();
    const Data = useSelector((state: RootState) => state.DiscountForm);
    const { data } = useGetDiscountQuery({ id: Data.data?.id ?? 0 }, { skip: Data.data === null })
    const dispatch = useDispatch()
    const [Edit, results] = useEditDiscountMutation();
    const [OpenAdd, setOpenAdd] = useState(false);

    const Schema = Yup.object().shape({
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset, watch } = useForm<any>({
        resolver: yupResolver(Schema),

    });

    const OnFinish: SubmitHandler<IDiscountData> = (data) => {

        let newUsers: number[] = []
        if (data?.type !== undefined && data?.type === "users") {
            data.users.map((user: IUser) => newUsers.push( user?.value ))
            setValue("users", newUsers)
        }
        // console.log(data)

        if (data?.code !== undefined) {
            let params: any = data

            Edit(params)
        }
    }

    useEffect(() => {
        if (Data.mode === 'edit' && data?.data !== undefined) {
            setOpenAdd(true);
            let listNew = data.data?.users.map((item: any) => ({ ...item, value: item.id, label: item.first_name }))
            reset({
                id: data.data?.id,
                code: data.data?.code,
                name: data.data?.name,
                reductionType: data.data?.reductionType,
                type: data.data?.type,
                amount: data.data?.amount,
                allowed_number: data.data?.allowed_number,
                active: data.data?.active,
                start_date: data.data?.start_date,
                expire_date: data.data?.expire_date,
                // users:Data.data?.users.map
                users: listNew

            });
        } else {
            setOpenAdd(false);
        }
    }, [Data.mode, data, reset])



    useEffect(() => {
        if (results.isSuccess) {

            dispatch(setDiscountFormMode("none"))
            ToastSuccess(t("Common.successAlarm"))
            // window.location.reload();
        }

        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => {
                setOpenAdd(open);
                dispatch(setDiscountFormMode(open ? "edit" : "none"));


            }} Open={OpenAdd} title={t("Common.editDiscount")}>
                <div className="FormModal !h-auto">

                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} watch={watch} />

                        <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]' >
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>

                    <PageMarginBottom />


                </div>

            </ModalFull>

        </>
    )
}
