import { useTranslation } from 'next-i18next'
import ButtonOrangeOuter from '../shared/button/ButtonBrandOuter'
import InputTextWithoutForm from '../shared/form/input/InputTextWithoutForm'
import InputTxt from '../shared/form/input/InputTxt'
import SearchIcon from '../shared/Icon/Search'
import { useDispatch } from 'react-redux'
import { setFilterProduct } from '../../data/slice/product/ProductForm'
import { useEffect } from 'react'

function FilterProduct() {

    const {t} = useTranslation()
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(setFilterProduct({searchName:""}))
    },[])

    return (
        <>
            <div className="flexCol flex-wrap gap-4  bg-[--light] w-full rounded-[--radius] p-4">
                <b>فیلتر</b>

                <div className="flexRow place-items-center gap-2 flex-flex-grow-1">
                    {/* <div><InputTxt keyValue="name" label="   " name="name" placeholder="نام محصول" /></div> */}
                    <InputTextWithoutForm getValue={(e:any)=>dispatch(setFilterProduct({searchName:e}))} placeholder={t("Common.productName")} label={t("Common.productName")} />
                    {/* <div className=" ">
                        <ButtonOrangeOuter click={() => { }} >
                            <SearchIcon />
                        </ButtonOrangeOuter>
                    </div> */}

                </div>
            </div>
        </>
    )
}

export default FilterProduct