import React from 'react'
import { AvatarProps } from '../../../../interface/Element'
import AvatarImg from './default.png'

const AvatarImage = ({img,width='w-[80px]',height='h-[80px]'}:AvatarProps) => {
  return (
    <div className={`${width} ${height}`}>
        <img className='w-full h-full rounded-full object-cover' src={img??AvatarImg} alt="" />
    </div>
  )
}

export default AvatarImage