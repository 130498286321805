import { createSlice } from "@reduxjs/toolkit"
import { ActionMode, PageDataType } from "../../../interface/EntityPage";

const initialState: {
    mode: ActionMode,
    data: PageDataType | null,
    deleteItem:PageDataType|null,
    
} = {
    mode: "none",
    data: null,
    deleteItem:null,

}


export const PageForm = createSlice({
    name: 'PageForm',
    initialState,
    reducers: {
        setPageFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },

        emptyPageFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'add'
            return newState;
        },

        setPageFormData: (state, action: { payload: PageDataType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },


        emptyPageFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },
       


        setPageDeleteItem: (state, action: { payload: PageDataType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptyPageDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setPageFormMode,
    emptyPageFormMode,
    setPageFormData,
    emptyPageFormData,
    setPageDeleteItem,
    emptyPageDeleteItem
    
} = PageForm.actions

export default PageForm.reducer