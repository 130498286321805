import Cookies from 'js-cookie'

export function SetToken(token: string, name?: "userA" | "userAR") {
    Cookies.set(name??"userA", token,{ secure: true,expires: 730 })
    return
}


export function GetToken(name?: "userA" | "userAR") {
    return Cookies.get(name ?? "userA") 
}

export function EmptyToken(name?: "userA" | "userAR") {
    return Cookies.remove(name ?? "userA")
}


