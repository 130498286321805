import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../data/store'
import ViewSupport from './actions/view'
import { setAdminFormMode } from '../../../data/slice/admin/AdminForm'
import { setSupportFormMode } from '../../../data/slice/support/SupportForm'

export default function FormSupport() {

  const dispatch = useDispatch()
  const mode = useSelector((state: RootState) => state.SupportForm.mode)

  return (
    <>
      <div className='w-full h-full bg-[--light] p-2 rounded-[--radius]'>

        {mode === "view" && <ViewSupport
          ChangeOpen={() => dispatch(setSupportFormMode("none"))}
          openModal={mode === "view"}
        />}


      </div>
    </>
  )
}
