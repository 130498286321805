import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../data/store'
import ViewOrder from './actions/view'
import { setOrderFormMode } from '../../../data/slice/order/OrderForm'

export default function FormOrder() {

  const dispatch = useDispatch()
  const mode = useSelector((state: RootState) => state.OrderForm.mode)

  return (
    <>
      <div className='w-full h-full bg-[--light] p-2 rounded-[--radius]'>

        {mode === "view" && <ViewOrder
          ChangeOpen={() => dispatch(setOrderFormMode("none"))}
          openModal={mode === "view"}
        />}


      </div>
    </>
  )
}
