import React from 'react'
import SwitchComponent from '../../../shared/form/switch'
import { useUserEditMutation } from '../../../../data/services/User'
import { Loader } from '../../../shared/Loader'

export default function ({ confirm,id }: { confirm: 1 | 0,id:number }) {

    const [edit,results]=useUserEditMutation()


    return (
        <>
            {results.isLoading?<Loader/>:<SwitchComponent checked={confirm === 1} handleChange={(data: any) => { edit({confirm:data?1:0,id}) }} />}
        </>
    )
}
