import React from 'react'

const TitleValue = ({ title = '', value = '', className = '' }) => {
  return (
    <div className={`flex flex-row w-full ${className}`}>
      <div className='basis-[35%] '><b >{title} : </b></div>
      <div className='flex-grow truncate'>
        {value}
      </div>
    </div>
  )
}

export default TitleValue