import Header from "../../components/supplier/header";
import ListData from "../../components/supplier/list";

export default function SupplierPage() {
  return (
    <>
      <div className="flexCol gap-4 pageLayout">
   
        <Header />
        <ListData/>
      

    

      </div>
    </>
  );
}
