import {ActionMode, OrderDataType, OrderFilterProps} from '../../../interface/EntityOrder'

import { createSlice } from "@reduxjs/toolkit"

const initialState: {
    mode: ActionMode,
    data: OrderDataType | null,
    deleteItem:OrderDataType|null,
    filters:OrderFilterProps
    
} = {
    mode: "none",
    data: null,
    deleteItem:null,
    filters:{order_status_id:'0'}

}


export const OrderForm = createSlice({
    name: 'OrderForm',
    initialState,
    reducers: {
        setOrderFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },

        emptyOrderFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'none'
            return newState;
        },

        setOrderFormData: (state, action: { payload: OrderDataType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },

        setOrderFormFilter: (state, action: { payload: OrderFilterProps }) => {
            let newState = state;
            newState['filters'] = action.payload
            return newState;
        },

        emptyOrderFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },
        
        emptyOrderFormFilter: (state) => {
            let newState = state;
            newState['filters'] = initialState.filters
            return newState;
        },
        

        setOrderDeleteItem: (state, action: { payload: OrderDataType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptyOrderDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setOrderFormMode,
    emptyOrderFormMode,
    setOrderFormData,
    emptyOrderFormData,
    setOrderDeleteItem,
    emptyOrderDeleteItem,
    setOrderFormFilter,
    emptyOrderFormFilter
    
} = OrderForm.actions

export default OrderForm.reducer