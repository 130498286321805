import { useState, useEffect, FC } from 'react'
import {  Marker, Popup, useMapEvents } from "react-leaflet";

// import markerIconPng from "./mark.png"
import markerIconPng from "./Vector.svg"
import { Icon } from 'leaflet'
import { MapMarket } from './interface';

interface IProps{
    position:any
}


const EmptyMarker:FC<IProps>=({position})=> {
 
    return  (
        <Marker position={position}
            icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
        >
            <Popup>شما اینجا هستی</Popup>
        </Marker>
    )
}

export default EmptyMarker