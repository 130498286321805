import React, { useEffect, useState } from 'react'

import EditProduct from './AddEdit/EditProduct';
import GalleryData from './gallery/Index';
import Inventory from './inventory';
import Keys from './keys';
import { Loader } from '../../shared/Loader';
import ModalFull from '../../shared/modal/ModalFull';
import TabBar from '../../../layout/tabBar';
import TabBtn from '../../shared/Html/TabBtn';
import ThumbImageData from './thumb/Index';
import { useProductDetailQuery } from '../../../data/services/Product';
import { useTranslation } from 'next-i18next';
import ShowIn from './showIn';

function ProductActions({ openModal, ChangeOpen, id }: { openModal: boolean, ChangeOpen: Function, id: number }) {
    const { t } = useTranslation()
    const [OpenAdd, setOpenAdd] = useState(false);
    const { data, isLoading } = useProductDetailQuery({ id }, { skip: id == 0 || id === null });
    const [tab, setTab] = useState("edit");

    useEffect(() => {
        setOpenAdd(openModal);
    }, [openModal])


    return (
        <>
            <ModalFull ChangeOpen={(open: boolean) => { setOpenAdd(open); ChangeOpen(open); }} Open={OpenAdd} title={isLoading ? "" : data?.data.name ?? ""}>

                <TabBar>
                    <div className='w-full' onClick={() => setTab("edit")}><TabBtn active={tab === "edit"} txt={t("Txt.edit")} /></div>
                    <div className='w-full' onClick={() => setTab("thumb")}><TabBtn active={tab === "thumb"} txt={t("Txt.thumbImg")} /></div>
                    <div className='w-full' onClick={() => setTab("gallery")}><TabBtn active={tab === "gallery"} txt={t("Txt.gallery")} /></div>
                    <div className='w-full' onClick={() => setTab("inventory")}><TabBtn active={tab === "inventory"} txt={t("Txt.inventory")} /></div>
                    <div className='w-full' onClick={() => setTab("keys")}><TabBtn active={tab === "keys"} txt={t("Txt.keys")} /></div>
                    <div className='w-full' onClick={() => setTab("showIn")}><TabBtn active={tab === "showIn"} txt={t("Txt.showIn")} /></div>
                </TabBar>



                {isLoading && <div className="flex justify-center w-full"><Loader /></div>}
                {!isLoading && data?.data && <>
                    {tab === "edit" && <EditProduct {...data?.data} />}
                    {tab === "thumb" && <ThumbImageData {...data?.data} />}
                    {tab === "gallery" && <GalleryData {...data?.data} />}
                    {tab === "inventory" && <Inventory {...data?.data} />}
                    {tab === "keys" && <Keys {...data?.data} />}
                    {tab === "showIn" && <ShowIn {...data?.data} />}
                </>}


            </ModalFull>
        </>
    )
}

export default ProductActions