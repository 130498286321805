
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../data/store'

import AddSlider from './actions/add'
import EditSlider from './actions/edit'
import DeleteSliderComponent from './actions/delete'

export default function FormRoles() {

  const dispatch = useDispatch()
  const mode = useSelector((state: RootState) => state.SliderForm.mode)

  return (
    <>
      <div className='w-full h-full bg-[--light] p-2 rounded-[--radius]'>
 

        {mode === "delete" && <DeleteSliderComponent/>}
        {mode === "add" && <AddSlider/>}
        {mode==="edit"&&<EditSlider/>}

      </div>
    </>
  )
}
