import { ProductData, ProductDetailData } from '../../../../interface/EntityProduct'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import ButtonBrand from '../../../shared/button/ButtonBrand'
import FileInput from '../../../shared/form/upload/FileInput'
import FileInputMulti from '../../../shared/form/upload/FileInputMulti'
import Gallery from '../../detail/info/Gallery'
import GrayLine from '../../../shared/Html/GrayLine'
import { useProductEditPostMutation } from '../../../../data/services/Product'
import { useTranslation } from 'next-i18next'
import InputTxt from '../../../shared/form/input/InputTxt'
import InputTextWithoutForm from '../../../shared/form/input/InputTextWithoutForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { ToastError } from '../../../../utility/ToastFunction'

function ThumbImageData({ ...data }: ProductDetailData) {
    const { t } = useTranslation();
    const Schema = Yup.object().shape({
        alt: Yup.string().required(t("Validation.require")),
    });
    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        // resolver: yupResolver(Schema)
    });
    const [editThumb, results] = useProductEditPostMutation()
    const [emptyFile, setEmptyFile] = useState(false);
    const [altImage, setAltImage] = useState<string>("")

    const OnFinishEditImg: SubmitHandler<{ thumb: File, alt: string }> = (param) => {
        if (param.alt == "") {
            ToastError("مقدار Alt الزامی است.")
        } else {
            if (param?.thumb !== undefined) {
                editThumb({ id: data.id, thumb: param.thumb, alt: altImage })
                setEmptyFile(true)
            } else {
                // api without thumb
            }
        }

    }

    // تصویر اصلی

    const handleAlt = async () => {
        const thumb = await data.gallery.find(item => item.show === "thumb")
        await setAltImage(thumb ? thumb?.alt : "")
        setValue('alt', thumb ? thumb?.alt : "")
    }

    useEffect(() => {
        handleAlt()
    }, [])
    return (
        <>
            <div className='FormModal'>
                {data !== undefined && <div className='flex justify-center '>
                    <Gallery data={data.gallery} typeShow={["thumb"]} />
                </div>}
                <form onSubmit={handleSubmit((values: any) => OnFinishEditImg(values))}    >

                    <div className='w-full h-52'>
                        <FileInput
                            emptyFile={emptyFile}
                            control={control}
                            errors={errors}
                            getValues={() => { }}
                            changeValue={(file: File) => console.log("thumb", file)}
                            name="thumb"
                            accept='image/png,image/jpeg,image/jpg'
                            title={t("Txt.chooseImg")} />

                    </div>
                    <div className="w-full">
                        <InputTextWithoutForm classContainer='mb-6' value={altImage} getValue={(e: any) => {
                            setValue("alt", e)
                            setAltImage(e)
                        }} label={t("Common.alt")} placeholder={t("Common.alt")} />
                    </div>

                    <div className='mb-4 w-full flex justify-center'>
                        <div className='w-[50%]'>
                            <ButtonBrand type='submit' click={() => { }} loading={results.isLoading}>
                                <b>{t("Common.saveButton")}</b>
                            </ButtonBrand>
                        </div>
                    </div>
                </form>
            </div>


        </>
    )
}

export default ThumbImageData